import { Box, Stack, Modal, Typography, IconButton } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import BrandLogo from "../../assets/Icons/BrandLogo";
import {
  ContinueButton,
  ModalContainer,
  ModalHeading,
  ModalText,
  StyledDivider,
  StyledInput,
} from "./index.styled";
import VerifyEmailModal, { VerifyEmailRef } from "./VerifyEmailModal";
import { signInWithGoogleAuth } from "../../service/firebase/firebaseAuth";
import CloseButton from "./components/CloseButton";
import useDebounce from "../../Hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalState } from "../../store/features/general/loginModalSlice";
import { RootState } from "../../store/store";
import useSendEmailLink from "./hooks/useSendEmailLink";
import { EMAIL_PATERN } from "../../utils/Constants";
import GoogleIcon from "../../assets/Icons/GoogleIcon";

interface Props {}

const LoginModal: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const verifyModalRef = useRef<VerifyEmailRef>(null);
  const { sendEmailLinkTo } = useSendEmailLink();

  const loginModalState = useSelector(
    (state: RootState) => state.loginModalState
  );

  const [email, setEmail] = useState<string>("");

  const [emailControl, setEmailControl] = useState("");
  const debounceSetEmail = useDebounce(setEmail, 100, EMAIL_PATERN);

  function handleEmailSent() {
    handleClose();
    verifyModalRef.current &&
      verifyModalRef.current?.toggleVerifyEmailModal(true);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    sendEmailLinkTo(email, handleEmailSent);
  }

  async function handleSignInWithRedirect() {
    const res = await signInWithGoogleAuth();
    if (res.status === 0) {
    }
  }

  function handleClose() {
    setTimeout(() => {
      dispatch(setLoginModalState(false));
    }, 50);
  }

  useEffect(() => {
    if (email !== "") {
      setEmailControl(email);
    }
  }, []);

  return (
    <>
      <Modal
        open={loginModalState}
        onClose={handleClose}
        aria-labelledby="Login-modal"
        aria-describedby="Login-to-continue"
      >
        <ModalContainer>
          <Box textAlign={"right"} marginBottom={"5px"}>
            <CloseButton onClick={handleClose} />
          </Box>

          <Box paddingLeft={1.25}>
            <BrandLogo width={"44"} height={"44"} />
          </Box>

          {/* Login Container */}
          <Box marginTop={3}>
            <Stack className="dialog-heading" direction="column" gap={1.25}>
              <ModalHeading variant={"h1"}>
                Log in or <br />
                create an account
              </ModalHeading>

              <ModalText>
                Enter your mail id or use google to continue
              </ModalText>
            </Stack>

            <IconButton
              tabIndex={-1}
              disableFocusRipple
              onClick={handleSignInWithRedirect}
              size="small"
              sx={{
                mt: 3.5,
                gap: 1,
                padding: 1.25,
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.08)",
                width: "100%",
                borderRadius: 2,
                border: "1px solid #D7D7D7 ",
              }}
            >
              <GoogleIcon />
              <Typography
                sx={{
                  color: "#2D3748",
                  fontWeight: 600,
                  fontSize: "17px",
                  lineHeight: "24px",
                }}
              >
                Continue with Google
              </Typography>
            </IconButton>

            <StyledDivider>
              <Typography className="px-1 text-[14px] font-medium text[#636262]">
                Or use your email
              </Typography>
            </StyledDivider>

            <Stack
              component={"form"}
              onSubmit={handleSubmit}
              direction={"column"}
              gap={1}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Email address
              </Typography>
              <StyledInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debounceSetEmail(e.target.value);
                  setEmailControl(e.target.value);
                }}
                value={emailControl}
                size="small"
                id="styled-input"
                autoComplete="off"
                placeholder="name@company.com"
              />

              <ContinueButton
                type="submit"
                disableElevation
                disableFocusRipple={true}
                disabled={email ? false : true}
                variant="contained"
              >
                Continue
              </ContinueButton>
            </Stack>
          </Box>
        </ModalContainer>
      </Modal>
      <VerifyEmailModal email={email} ref={verifyModalRef} />
    </>
  );
};

export default LoginModal;
