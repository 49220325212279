import { SvgIconProps } from "@mui/material";
import React from "react";

const WhyWeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00002 13.3341C7.63202 13.3341 7.33335 13.0361 7.33335 12.6674C7.33335 11.1221 8.25468 9.76274 9.92735 8.84008C11.452 8.00008 12.24 6.33674 11.9353 4.60341C11.6533 2.99808 10.336 1.68008 8.73002 1.39808C7.52202 1.18608 6.35002 1.49608 5.42868 2.26941C4.52002 3.03208 3.99935 4.14941 3.99935 5.33408C3.99935 5.70208 3.70068 6.00074 3.33268 6.00074C2.96468 6.00074 2.66602 5.70208 2.66602 5.33408C2.66602 3.75408 3.36068 2.26474 4.57135 1.24808C5.78202 0.232076 7.38268 -0.191924 8.96068 0.0847431C11.1087 0.46141 12.8713 2.22474 13.2487 4.37274C13.6473 6.64074 12.5707 8.90474 10.5713 10.0081C9.70268 10.4867 8.66668 11.3367 8.66668 12.6674C8.66668 13.0361 8.36802 13.3341 8.00002 13.3341ZM7.00002 15.0007C7.00002 15.5527 7.44802 16.0007 8.00002 16.0007C8.55202 16.0007 9.00002 15.5527 9.00002 15.0007C9.00002 14.4487 8.55202 14.0007 8.00002 14.0007C7.44802 14.0007 7.00002 14.4487 7.00002 15.0007Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WhyWeIcon;
