import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { SwipeableDrawer, Box, styled, Stack } from "@mui/material";
import FilterControls from "../../pages/Home/components/common/FilterControls";
import { Global } from "@emotion/react";
import { DrawerForwardRef } from "../../pages/Home/index.types";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import { useAuthContext } from "../../context/AuthContext";

const Puller = styled(Box)({
  position: "absolute",
  top: "10px",
  width: 80,
  height: 4,
  borderRadius: 8,
  backgroundColor: "#949698",
});

const DrawerSM = forwardRef<DrawerForwardRef>((props, ref) => {
  const { filterDrawerState, setFilterDrawerState } = useAuthContext();

  const { smallScreen } = useGeneralContext();

  useImperativeHandle(ref, () => ({
    toggleDrawer: toggleDrawer,
    state: filterDrawerState,
  }));

  function toggleDrawer(state?: boolean) {
    if (state) {
      setFilterDrawerState(state);
    } else {
      setFilterDrawerState((prev) => !prev);
    }
  }

  // close drawer on large screen or horizontal orientation
  useEffect(() => {
    !smallScreen && setFilterDrawerState(false);
  }, [smallScreen]);

  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: "auto",
            overflow: "auto",
          },
        }}
      />

      <SwipeableDrawer
        anchor="bottom"
        open={filterDrawerState}
        hysteresis={0.52}
        minFlingVelocity={1300}
        // disableBackdropTransition={true}
        transitionDuration={{ enter: 300, exit: 300 }}
        onClose={() => setFilterDrawerState(false)}
        onOpen={() => setFilterDrawerState(true)}
        disableSwipeToOpen={true}
        keepMounted={false}
        sx={{
          overflowY: "auto",
          "& .MuiDrawer-paper": {
            borderRadius: "16px 16px 0 0",
          },
        }}
      >
        <Stack
          alignItems={"center"}
          sx={{
            padding: {
              md: "44px 36px",
              xs: "32px 16px 16px",
            },
          }}
        >
          <Puller />
          <Box width={"100%"}>
            <FilterControls
              drawerState={filterDrawerState}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        </Stack>
      </SwipeableDrawer>
    </>
  );
});

export default DrawerSM;
