import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import Card from "./components/Card";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import AddReviewIcon from "../../assets/Icons/AddReviewIcon";
import SearchBar from "../../components/common/SearchBar";
import FilterSection from "./components/FilterSection";
import { useAuthContext } from "../../context/AuthContext";
import { useGeneralContext } from "../../context/GeneralContextProvider";
import CustomBackdrop from "../../components/common/CustomBackdrop";
import { cardList } from "../../constants/featuresCardist";

type State = {
  run: boolean;
  stepIndex?: number;
  steps: Step[];
};
let delay = 0;

type TitleProp = {
  index: string;
  title: string;
};

const TitleStyle = ({ index, title }: TitleProp) => {
  return (
    <div className=" flex gap-3 items-center justify-center py-2">
      {/* <div className=" bg-[#138FD7] leading-0 text-white rounded-full p-2 w-5 h-5 flex justify-center items-center text-xs">
        {index}
      </div> */}
      <div className=" text-base ">{title}</div>
    </div>
  );
};
const Features = () => {
  const {
    isLoginVisible,
    setIsLoginVisible,
    filterDrawerState,
    setFilterDrawerState,
  } = useAuthContext();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const { smallScreen } = useGeneralContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirestTime, setIsFirestTime] = useState(true);

  useEffect(() => {
    if (delay !== 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [delay]);

  const [{ run, steps, stepIndex }, setState] = useState<State>({
    stepIndex: -1,
    run: false,
    steps: [
      {
        content: <h2>temp!</h2>,
        placement: "center",
        target: "body",
      },
      {
        target: smallScreen ? ".small-phrase-terms" : ".phrase-terms",
        title: <TitleStyle index="1" title="Choose between Phrases & Terms" />,
        placement: "right",
        content:
          "You have the option to select either phrases or terms for your convenience.",
      },
      {
        target: smallScreen ? ".small-language-box" : ".language-box",
        title: <TitleStyle index="2" title="Choose your language" />,
        placement: "right",
        content:
          "You have the flexibility to select your preferred languages for your convenience.",
      },
      {
        target: smallScreen ? ".small-category-topic" : ".category-topic",
        title: (
          <TitleStyle index="3" title="Choose your Category & It’s Topics" />
        ),
        placement: "right",
        content:
          "You have the flexibility to select both your category & its corresponding topics for your convenience.",
      },
      {
        target: smallScreen ? ".small-view-favourites" : ".view-favourites",
        title: <TitleStyle index="6" title="View Favourites" />,
        content:
          "You can use this toggle to create a shortlist of your favourite cards.",
        placement: "right",
      },
      {
        target: ".text-to-speech",
        title: <TitleStyle index="4" title="Text to speech" />,
        content:
          "Listen to the pronunciation of the phrase in various languages.",
      },
      {
        target: ".add-favourites",
        title: <TitleStyle index="5" title="Add To Favourites" />,
        content: "Simply click the star to add the card to your Favourites.",
        placement: "right",
      },

      {
        target: ".setting",
        title: <TitleStyle index="7" title="Customize your experience" />,
        content:
          "Personalize your card experience by choosing the language proficiency & voice gender preferences.",
        placement: "left",
      },
      {
        target: ".feedback",
        title: <TitleStyle index="8" title="Give feedback" />,
        content:
          "Kindly provide your valuable feedback by tapping on this button.",
      },
    ],
  });

  useEffect(() => {
    setIsLoginVisible(false);
  }, []);

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (smallScreen) {
      setIsLoading(true);

      setFilterDrawerState(true);
      delay = 2000;
    } else {
      delay = 0;
    }
    setTimeout(() => {
      setState(() => ({
        steps: steps,
        run: true,
        stepIndex: 1,
      }));
      setIsDialogOpen(false);
      delay = 0;
    }, delay);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      if (index === 1 && action === "next") {
        delay = 0;
      }
      if (index === 4 && action === "next") {
        delay = 0;
        if (smallScreen) setFilterDrawerState(false);
      }
      if (index === 6 && action === "next") {
        delay = 1000;
        setIsLoginVisible(true);
      }
      if (index === 5 && action === "prev") {
        delay = 2000;
        if (smallScreen) setFilterDrawerState(true);
      }
      if (index === 7 && action === "prev") {
        delay = 0;
        setIsLoginVisible(false);
      }
      if (index === 1 && action === "prev") {
        if (smallScreen) setFilterDrawerState(false);
        setIsLoginVisible(false);
      }
      if (!smallScreen) delay = 0;
      setTimeout(() => {
        if (action === ACTIONS.PREV && index === 1) {
          setIsDialogOpen(true);
          setIsLoginVisible(false);
          setState((prev) => ({
            ...prev,
            run: false,
            stepIndex: -1,
          }));
        } else {
          if (!type.toString().includes("error")) {
            setState((prev) => ({
              ...prev,
              stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
            }));
          }
        }
        delay = 0;
      }, delay);
    } else if (status === STATUS.FINISHED) {
      delay = 0;
      setState((prev) => ({
        ...prev,
        run: false,
      }));
      setIsFirestTime(false);
      setIsDialogOpen(true);
      setIsLoginVisible(false);
    }

    if (action === "close" && stepIndex && stepIndex > 0) {
      setIsDialogOpen(true);
      setFilterDrawerState(false);

      setIsLoginVisible(false);
      setState((prev) => ({
        ...prev,
        run: false,
        stepIndex: 1,
      }));
    }
  };

  // close drawer on large screen or horizontal orientation
  useEffect(() => {
    !smallScreen && setFilterDrawerState(false);
  }, [smallScreen]);

  return (
    <div className=" w-screen flex-grow overflow-hidden  ">
      {isDialogOpen && !smallScreen && (
        <Divider
          sx={{
            marginTop: "-2px",
            display: { xs: "none", sm: "block" },
            borderColor: "#EAEEF4",
            //   location.pathname === "/features" && !isLoginVisible
            //     ? "#000000 !important"
            //     : "#EAEEF4 !important",
            // opacity:
            //   location.pathname === "/features" && !isLoginVisible ? "0.5" : "1",
          }}
        />
      )}
      <div className={` w-full h-full flex  overflow-auto`}>
        {/* relative */}
        {run ? (
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep={false}
            disableScrolling
            showProgress
            beaconComponent={() => null}
            steps={steps}
            stepIndex={stepIndex}
            // showSkipButton=true
            styles={{
              options: {
                zIndex: 10000,
                // "& __floater__arrow span svg polygon": {
                //   backgroundColor: "#E7F4FB",
                // },
              },
              tooltipFooter: {
                padding: "0px 18px 18px 18px",
              },
              buttonBack: {
                float: "left",
                position: "absolute",
                left: 7,
                color: "#138FD7",
              },
              buttonSkip: {
                color: "#000",
              },
              buttonNext: {
                order: 2,
                backgroundColor: "#138FD7",
              },
              tooltipTitle: {
                overflow: "hidden",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                padding: "10px 0px 0px 0px",
                borderBottom: "3px solid #E7F4FB",
                color: "#2A292E",
              },
              tooltip: {
                padding: 0,
              },
            }}
            floaterProps={{
              // hideArrow: true,
              style: {
                background: "#000",
                zIndex: 100,
                transform: "translateY(-50%)",
              },
            }}
            locale={{
              back: "Previous",
              skip: "Skip",
            }}
          />
        ) : null}
        <div className="w-screen flex-grow overflow-auto">
          <Stack direction={"row"} height={"100%"}>
            <FilterSection />
            <Divider
              sx={{ display: { md: "flex", xs: "none" } }}
              orientation="vertical"
              flexItem
            />

            <Box
              className={`flex-grow  overflow-y-auto px-3 pt-4 pb-2 sm:px-5 sm:pt-6 md:px-6 md:pt-7 flex flex-col gap-5`}
            >
              <Stack direction="row" gap={1.5} alignItems={"center"}>
                {" "}
                <SearchBar />
              </Stack>

              <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-5">
                <Card
                  data={{
                    primary: "I'm a music lover.",
                    secondary: "Soy un apasionado de la música",
                  }}
                />
                {cardList.map((card, index) => {
                  return <Card key={index} data={card} />;
                })}
              </Box>
            </Box>
          </Stack>
          {/* <DrawerSM ref={DrawerRef} /> */}

          <IconButton
            disableRipple
            sx={{
              position: "absolute",
              bottom: { md: "48px", xs: "24px" },
              right: { md: "48px", xs: "24px" },
              padding: 0,
            }}
            aria-label="fingerprint"
            color="secondary"
            className="feedback"
          >
            <AddReviewIcon />
          </IconButton>
        </div>
        {smallScreen &&
          isLoading &&
          !isDialogOpen &&
          stepIndex !== -1 &&
          stepIndex !== 9 && (
            <CustomBackdrop>
              <CircularProgress
                size="20px"
                sx={{ margin: "auto" }}
                className=" text-white"
              />
            </CustomBackdrop>
          )}

        {isDialogOpen && (
          <CustomBackdrop isAbsolute={smallScreen && isLoading ? false : true}>
            <div className=" w-full h-full flex justify-center items-center px-[15px]">
              <div className=" bg-white  h-fit rounded-md w-[380px] min-h-[150px] flex flex-col p-5 justify-between">
                <div className="   font-bold text-2xl text-[#333333] text-center">
                  {isFirestTime
                    ? "Let's begin our journey!"
                    : "Let's Restart our journey!"}
                </div>

                <Button
                  className=" bg-text-secondary w-fit self-end"
                  onClick={(e) => {
                    handleClickStart(e);
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size="20px"
                      sx={{ margin: "auto" }}
                      className=" text-white"
                    />
                  ) : (
                    "Start"
                  )}
                </Button>
              </div>
            </div>
          </CustomBackdrop>
        )}
      </div>
    </div>
  );
};

export default Features;
