import {
  Avatar,
  Link,
  Box,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState, useRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAvatarInitials } from "../../utils/GeneralUtils";
import useHandleSignOut from "../../Hooks/useHandleSignOut";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router";

type Props = {
  showDetails?: boolean;
};
const UserProfile = ({ showDetails }: Props) => {
  const [showProfileDetails, setShowProfileDetails] = useState<boolean>(false);
  const location = useLocation();
  const userData = useSelector((state: RootState) => state.userData);
  const anchorElement = useRef<any>();
  const { handleSignOut } = useHandleSignOut();
  const { setIsConfigVoiceDialog } = useAuthContext();

  function handleClose() {
    setShowProfileDetails(false);
  }

  const AvatarInitials = useMemo(() => {
    return getAvatarInitials(userData);
  }, [userData]);

  useEffect(() => {
    if (showDetails !== undefined) setShowProfileDetails(showDetails);
  }, [showDetails]);

  const getDisplayName = useMemo(() => {
    const userName = userData?.displayName ?? "";

    if (userName !== "") {
      return userName;
    }
    return userData?.email.split("@").at(0) ?? "New User";
  }, [userData]);

  return (
    <>
      <Avatar
        onClick={() => {
          if (location.pathname !== "/features") {
            setShowProfileDetails(true);
          }
        }}
        ref={anchorElement}
        sx={{ cursor: "pointer", bgcolor: "secondary.main" }}
      >
        {AvatarInitials !== "?" ? AvatarInitials : "U"}
      </Avatar>
      <Menu
        anchorEl={anchorElement?.current}
        keepMounted={false}
        tabIndex={-1}
        id="account-menu"
        open={showProfileDetails}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              width: "250px",
              mt: 1.5,
              top: "75px !important",
              borderRadius: "12px",
              "& .MuiAvatar-root": { width: 40, height: 40, ml: -0.5, mr: 1 },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          disableRipple={true}
          onClick={() => {
            setIsConfigVoiceDialog(true);
          }}
          className={`overflow-ellipsis flex gap-5 cursor-pointer hover:bg-transparent ${
            showDetails ? "setting" : null
          }`}
        >
          {" "}
          <SettingsIcon sx={{ color: "#138FD7" }} />
          <Box>
            <Typography className="w-full line-clamp-1 font-medium leading-5 ">
              User Setting
            </Typography>
          </Box>
        </MenuItem>{" "}
        <Divider sx={{ width: "100%" }} />
        <MenuItem
          disableRipple={true}
          onClick={handleClose}
          className="overflow-ellipsis flex gap-0.5 cursor-pointer hover:bg-transparent"
        >
          <Avatar sx={{ bgcolor: "secondary.main" }}>
            {" "}
            {AvatarInitials !== "?" ? AvatarInitials : "U"}
          </Avatar>
          <Box>
            <Typography className="w-full line-clamp-1 font-medium leading-5 ">
              {getDisplayName}
            </Typography>
            <Link
              onClick={handleSignOut}
              color="secondary"
              sx={{ fontSize: "14px", cursor: "pointer" }}
            >
              Logout
            </Link>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserProfile;
