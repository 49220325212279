import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import LanguageBox from "./components/LanguageBox";
import CardInfo from "./components/CardInfo";
import FavouriteButton from "./components/FavouriteButton";
import { useSelector } from "react-redux";
import { StyledCard } from "./index.styled";
import { RootState } from "../../../../../../../store/store";
import { PhraseAndTermsData } from "../../../../../../../service/firebase/firebase.types";

interface Props {
  isReversedData: boolean;
  cardData: PhraseAndTermsData;
  speechCallbackRef: React.MutableRefObject<(() => void) | undefined>;
}

enum TYPE {
  "PRIMARY",
  "SECONDARY",
}

const MemoCard: React.FC<Props> = ({
  cardData,
  isReversedData,
  speechCallbackRef,
}) => {
  const categoryList = useSelector((state: RootState) => state.categoryList);
  const topicList = useSelector((state: RootState) => state.topicList);

  const { languagePrimary, languageSecondary } = useSelector(
    (state: RootState) => state.appliedFilters
  );

  const category = useMemo(() => {
    return categoryList.find(
      (category) => category.category_id === cardData.category_id
    )?.value;
  }, [cardData, categoryList]);

  const topic = useMemo(() => {
    return topicList.find((topic) => topic.topic_id === cardData.topic_id)
      ?.value;
  }, [cardData, topicList]);

  const getLanguage = (type: TYPE) => {
    if (type === TYPE.PRIMARY) {
      return languagePrimary?.value ?? "language";
    } else {
      return languageSecondary?.value ?? "language";
    }
  };

  const getGeneralLang = (type: TYPE) => {
    if (type === TYPE.PRIMARY) {
      if (
        cardData.primary_data.primary_language_id !==
        languagePrimary?.language_id
      ) {
        return "General";
      }

      return "";
    } else {
      if (
        cardData.secondary_data.secondary_language_id !==
        languageSecondary?.language_id
      ) {
        return "General";
      }

      return "";
    }
  };

  function getData(type: TYPE) {
    const secondary_value2: string = cardData.secondary_data.secondary_value2
      ? cardData?.secondary_data?.secondary_value2
      : "";

    if (type === TYPE.PRIMARY) {
      return {
        generalLang: isReversedData
          ? getGeneralLang(TYPE.SECONDARY)
          : getGeneralLang(TYPE.PRIMARY),
        language: isReversedData
          ? getLanguage(TYPE.SECONDARY)
          : getLanguage(TYPE.PRIMARY),
        content: cardData?.primary_data?.primary_value,
        languageId: isReversedData
          ? languageSecondary?.language_id
          : languagePrimary?.language_id,
      };
    } else {
      return {
        generalLang: isReversedData
          ? getGeneralLang(TYPE.PRIMARY)
          : getGeneralLang(TYPE.SECONDARY),
        language: isReversedData
          ? getLanguage(TYPE.PRIMARY)
          : getLanguage(TYPE.SECONDARY),
        content:
          cardData?.secondary_data?.secondary_value + "/v2/" + secondary_value2,
        languageId: isReversedData
          ? languagePrimary?.language_id
          : languageSecondary?.language_id,
      };
    }
  }

  return (
    <StyledCard elevation={0}>
      <FavouriteButton cardData={cardData} />
      <Stack gap={1.5}>
        <LanguageBox
          speechCallbackRef={speechCallbackRef}
          data={
            isReversedData ? getData(TYPE.SECONDARY) : getData(TYPE.PRIMARY)
          }
        />
        <LanguageBox
          speechCallbackRef={speechCallbackRef}
          data={
            isReversedData ? getData(TYPE.PRIMARY) : getData(TYPE.SECONDARY)
          }
        />
      </Stack>
      <Box>
        <Typography
          sx={{ fontSize: "12px", textAlign: "end", fontWeight: 500 }}
        >
          {cardData.fake_index}
        </Typography>
        <Divider />
      </Box>
      <CardInfo
        category={category ?? "Unknown Category"}
        topic={topic ?? "Unknown Topic"}
      />
    </StyledCard>
  );
};

const PhraseAndTermCard = React.memo(MemoCard);
export default PhraseAndTermCard;
