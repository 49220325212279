import { useDispatch } from "react-redux";
import { signOutUser } from "../service/firebase/firebaseAuth";
import { setAlert } from "../store/features/general/alertSlice";

const useHandleSignOut = () => {
  const dispatch = useDispatch();

  async function handleSignOut() {
    const res = await signOutUser();

    if (res) {
      dispatch(setAlert({ type: "info", message: "Signed out successfully", duration: 800 }));
    } else {
      dispatch(setAlert({ type: "info", message: "Can not sign out!", duration: 800 }));
    }
  }

  return { handleSignOut };
};

export default useHandleSignOut;
