import { Stack, Modal, Divider, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import BrandLogo from "../../assets/Icons/BrandLogo";
import { ReviewModalContainer } from "./index.styled";
import CloseButton from "./components/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setReviewModalState } from "../../store/features/general/reviewModalSlice";
import ReviewInput from "./components/ReviewInput";
import ReviewList from "./components/ReviewList";
import VerbValorText from "../../assets/Icons/VerbValorText";

interface Props {}

const ReviewModal: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const reviewModalState = useSelector(
    (state: RootState) => state.reviewModalState
  );

  const [reviewCount, setReviewCount] = useState<number | null>(null);

  function handleClose() {
    dispatch(setReviewModalState(false));
  }

  return (
    <>
      <Modal
        tabIndex={-1}
        open={reviewModalState}
        onClose={handleClose}
        aria-labelledby="review-modal"
        aria-describedby="add-review"
      >
        <ReviewModalContainer
          tabIndex={-1}
          sx={{ display: "flex", flexDirection: "column", border: "none" }}
        >
          <Stack
            direction={"row"}
            sx={{
              height: "72px",
              padding: "18px 18px 10px 16px",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} sx={{ flexGrow: 1, gap: "19px" }}>
              <BrandLogo width={"42"} height={"42"} />
              <Stack className=" flex flex-col justify-between">
                <VerbValorText />
                {reviewCount !== null ? (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: 700,
                      color: "rgba(28, 28, 28, 0.4)",
                    }}
                  >
                    {reviewCount > 0
                      ? `Reviews (${reviewCount})`
                      : "No Reviews"}
                  </Typography>
                ) : (
                  <Stack marginTop={"5px"}>
                    <Skeleton animation="wave" width={"80px"} height={15} />
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack sx={{ justifyContent: "end" }}>
              <CloseButton
                className=" relative -top-[8px]"
                onClick={handleClose}
              />
            </Stack>
          </Stack>

          <Divider />

          <ReviewList
            reviewCount={reviewCount}
            setReviewCount={setReviewCount}
          />

          <Divider />
          <ReviewInput />
        </ReviewModalContainer>
      </Modal>
    </>
  );
};

export default ReviewModal;
