import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearAll } from "../../store";

const reviewModalSlice = createSlice({
  name: "review modal",
  initialState: false,
  reducers: {
    setReviewModalState(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
    toggleReviewModal(state) {
      return !state;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => false);
  },
});

export const { setReviewModalState, toggleReviewModal } = reviewModalSlice.actions;
export default reviewModalSlice.reducer;
