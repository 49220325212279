import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import InfiniteScroll from "../common/InfiniteScroll.tsx";
// import { fetchData } from "../../../../service/firebase/firebaseQueries";
import {
  setPhraseAndTerms,
  updatePhraseAndTerms,
} from "../../../../store/features/data/phraseAndTermSlice";
import { FilterTypes } from "../../../../types/global.types";
import PhraseAndTermList from "./components/PhraseAndTermList";
import { PhraseAndTermsData } from "../../../../service/firebase/firebase.types";
import { fetchData } from "../../../../service/algolia/algoliaQueries";
import { StyledSwitch } from "../common/FilterControls/components/VIewOnlyFavourites/index.styled";
import { WrapperStack } from "../common/FilterControls/index.styled";
import DotIcon from "../../../../assets/Icons/DotIcon";
import FilterIcon from "../../../../assets/Icons/FilterIcon";
import SearchBar from "../../../../components/common/SearchBar";
import { DrawerForwardRef } from "../../index.types";
import { useAuthContext } from "../../../../context/AuthContext";

interface Props {
  className?: string;
  // DrawerRef: React.RefObject<DrawerForwardRef>;
}

const MainSection: React.FC<Props> = ({ className }) => {
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  const fetchQueue = useRef<FilterTypes | null>(null);
  const fetchQueueTimeout = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const languageLevel = useSelector(
    (state: RootState) => state.userData?.user_settings?.language_level
  );

  const [page, setPage] = useState(0);

  const appliedFilters = useSelector(
    (state: RootState) => state.appliedFilters
  );

  function updateQueue() {
    if (fetchQueueTimeout.current) {
      clearTimeout(fetchQueueTimeout.current);
    }
    fetchQueue.current = appliedFilters;

    fetchQueueTimeout.current = setTimeout(() => {
      const currentFilter = JSON.stringify(fetchQueue.current);
      getData(true, currentFilter);
    }, 600);
  }

  async function getData(isRefresh?: boolean, currentFilter?: string) {
    let fetchedData: PhraseAndTermsData[] = [];

    if (appliedFilters.showFavourites) {
      fetchedData = await fetchData({
        appliedFilters,
        page: isRefresh ? 0 : page,
        currentUserId,
        languageLevel,
      });
    } else {
      fetchedData = await fetchData({
        appliedFilters,
        page: isRefresh ? 0 : page,
        currentUserId,
        languageLevel,
      });
    }

    setIsLoading(false);

    if (isRefresh) {
      if (currentFilter === JSON.stringify(fetchQueue.current)) {
        fetchQueue.current = null;
        dispatch(setPhraseAndTerms(fetchedData));
        if (fetchedData.length === 0) {
          setPage(-1);
        } else {
          setPage(1);
        }
        setIsLoading(false);
      }
    } else {
      if (fetchQueue.current === null) {
        dispatch(updatePhraseAndTerms(fetchedData));
        setPage((prev) => prev + 1);
        setIsLoading(false);
      }
    }
    if (fetchedData.length < 24 && fetchQueue.current === null) {
      setHasMoreData(false);
    }
  }

  function handleRemoveFavourite() {
    setPage(-1);
  }

  useLayoutEffect(() => {
    setPage(0);
    setHasMoreData(true);
    setIsLoading(true);
    appliedFilters.languagePrimary && updateQueue();
  }, [appliedFilters, languageLevel]);

  const dispatch = useDispatch();

  const filters = useSelector((state: RootState) => state.appliedFilters);
  const [filterSelected, setFilterSelected] = useState<boolean>(false);

  const userData = useSelector((state: RootState) => state.userData);
  const { filterDrawerState, setFilterDrawerState } = useAuthContext();

  function toggleDrawer() {
    setFilterDrawerState(!filterDrawerState);
    // if (DrawerRef?.current) {
    //   DrawerRef.current?.toggleDrawer();
    // }
  }

  useEffect(() => {
    if (
      filters?.category?.value ||
      filters?.topic?.value ||
      filters?.showFavourites
    ) {
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }
  }, [filters]);

  return (
    <Box className={`flex-grow  overflow-y-auto flex flex-col gap-5`}>
      <Stack
        direction="row"
        gap={1.5}
        alignItems={"center"}
        className=" px-3 pt-4 pb-2 sm:px-5 sm:pt-6 md:px-6 md:pt-7 "
      >
        <SearchBar />

        <IconButton
          onClick={() => {
            toggleDrawer();
          }}
          sx={{ padding: 0, display: { md: "none" } }}
        >
          <FilterIcon />

          {filterSelected && (
            <DotIcon className="absolute top-0 right-0 translate-x-[25%] -translate-y-[25%]" />
          )}
        </IconButton>
      </Stack>
      <Box className=" overflow-auto  px-3  pb-2 sm:px-5 md:px-6 ">
        <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-5 ">
          <InfiniteScroll
            page={page}
            hasMore={hasMoreData}
            isLoading={isLoading}
            loadMore={getData}
            loadingComponent={
              <CircularProgress
                color="secondary"
                size="30px"
                sx={{ margin: "auto" }}
              />
            }
            noDataComponent={
              <div className="text-center col-span-full font-medium ">
                No Data Found
              </div>
            }
          >
            <PhraseAndTermList handleRemoveFavourite={handleRemoveFavourite} />
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default MainSection;
