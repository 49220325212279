/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  cardLanguageOptions,
  defaultUserSettings,
  genderOptions,
  languageLevelOptions,
} from "../../constants/constants";
import { Button, Dialog, Divider, IconButton } from "@mui/material";
import Select, { SingleValue, components } from "react-select";
import { useAuthContext } from "../../context/AuthContext";
import CloseButton from "./components/CloseButton";
import { theme as muiTheme } from "../../theme/index";
import { voiceSettingStyles } from "../../pages/Home/components/common/FilterControls/components/common/react_select.styleConfig";
import DropDownIcon from "../../assets/Icons/DropDownIcon";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "../../pages/Home/components/common/FilterControls/index.types";
import { updateUserVoiceConfigs } from "../../service/firebase/firebaseQueries";
import { updateUserData } from "../../store/features/user/userSlice";
import { setAlert } from "../../store/features/general/alertSlice";
import { RootState } from "../../store/store";
import { userSettingsType } from "../../types/global.types";

const CustomDropdownIndicator: React.FC<any> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon width={16} height={16} className=" cursor-pointer" />
    </components.DropdownIndicator>
  );
};

const VoiceConfigModel = () => {
  const dispatch = useDispatch();
  const { isConfigVoiceDialog, setIsConfigVoiceDialog } = useAuthContext();

  const userSettings = useSelector(
    (state: RootState) => state?.userData?.user_settings
  );

  const [selectedOptions, setSelectedOptions] = useState<userSettingsType>({
    gender: null,
    languageLevel: null,
    defaultCardLanguage: null,
  });

  // save user selections to firebase...
  const updateUserSetting = () => {
    const userConfigs = {
      language_level:
        selectedOptions.languageLevel?.value ||
        defaultUserSettings.language_level,
      gender: selectedOptions?.gender?.value || defaultUserSettings.gender,
      default_card_language:
        selectedOptions.defaultCardLanguage?.value ||
        defaultUserSettings.default_card_language,
    };

    dispatch(updateUserData({ user_settings: userConfigs }));

    dispatch(
      setAlert({ type: "info", message: "Settings saved !", duration: 800 })
    );

    updateUserVoiceConfigs(userConfigs);
    setIsConfigVoiceDialog(false);
  };

  // pre-fills the default selected from user-settings
  useEffect(() => {
    if (userSettings?.language_level && userSettings?.gender) {
      const languageLevel = languageLevelOptions.find(
        (option) => option.value === userSettings?.language_level
      );
      const gender = genderOptions.find(
        (option) => option.value === userSettings?.gender
      );
      const defaultCardLanguage = cardLanguageOptions.find(
        (option) => option.value === userSettings?.default_card_language
      );

      setSelectedOptions((prev) => ({
        ...prev,
        ...(gender ? { gender } : {}),
        ...(languageLevel ? { languageLevel } : {}),
        ...(defaultCardLanguage ? { defaultCardLanguage } : {}),
      }));
    }
  }, [userSettings]);

  return (
    <Dialog
      open={isConfigVoiceDialog}
      PaperProps={{
        sx: {
          maxWidth: "500px",
          width: "100%",
          maxHeight: 600,
          // scrollbarGutter: "stable",
        },
      }}
    >
      <div className={`flex flex-col items-center justify-center gap-3  `}>
        <div className="w-full flex justify-between pt-3 px-5">
          <p className=" text-lg text-black font-semibold tracking-wide">
            User Setting
          </p>
          <IconButton className="p-0 m-0">
            <CloseButton
              className=" relative scale-75 "
              onClick={() => {
                setIsConfigVoiceDialog(false);
              }}
            />
          </IconButton>
        </div>
        <Divider sx={{ width: "100%", padding: 0, margin: 0 }} />

        <div className="flex flex-col w-full gap-3 px-5 pb-5">
          <div className=" ">
            <h3 className=" font-semibold text-sm py-1 tracking-wider">
              Language Level
            </h3>
            <Select
              isSearchable={false}
              isLoading={!userSettings}
              value={selectedOptions?.languageLevel || null}
              blurInputOnSelect={true}
              minMenuHeight={300}
              maxMenuHeight={250}
              // menuPlacement={smallScreen ? "top" : "auto"}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,

                colors: {
                  ...theme.colors,
                  primary25: muiTheme.palette.secondary.light,
                  primary: muiTheme.palette.secondary.main,
                },
              })}
              // isLoading={languageOptions?.length === 0}
              styles={voiceSettingStyles}
              hideSelectedOptions={true}
              isClearable={false}
              name="Language Level"
              options={userSettings ? languageLevelOptions : []}
              onChange={(value) => {
                setSelectedOptions((prev) => ({
                  ...prev,
                  languageLevel: value as SingleValue<Option>,
                }));
              }}
              placeholder="Language Select"
              components={{
                DropdownIndicator: selectedOptions?.languageLevel
                  ? CustomDropdownIndicator
                  : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="">
            <h3 className=" font-semibold text-sm py-1 tracking-wider">
              Voice Gender
            </h3>
            <Select
              isSearchable={false}
              isLoading={!userSettings}
              value={selectedOptions?.gender || null}
              blurInputOnSelect={true}
              minMenuHeight={300}
              maxMenuHeight={250}
              // menuPlacement={smallScreen ? "top" : "auto"}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,

                colors: {
                  ...theme.colors,
                  primary25: muiTheme.palette.secondary.light,
                  primary: muiTheme.palette.secondary.main,
                },
              })}
              styles={voiceSettingStyles}
              hideSelectedOptions={true}
              isClearable={false}
              name="Gender Select"
              options={userSettings ? genderOptions : []}
              onChange={(value) => {
                setSelectedOptions((prev) => ({
                  ...prev,
                  gender: value as SingleValue<Option>,
                }));
              }}
              placeholder="Gender"
              components={{
                DropdownIndicator: selectedOptions?.gender
                  ? CustomDropdownIndicator
                  : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className=" ">
            <h3 className=" font-semibold text-sm py-1 tracking-wider">
              Default Card Language
            </h3>
            <Select
              isSearchable={false}
              isLoading={!userSettings}
              value={selectedOptions?.defaultCardLanguage || null}
              blurInputOnSelect={true}
              minMenuHeight={300}
              maxMenuHeight={250}
              theme={(theme) => ({
                ...theme,
                borderRadius: 8,

                colors: {
                  ...theme.colors,
                  primary25: muiTheme.palette.secondary.light,
                  primary: muiTheme.palette.secondary.main,
                },
              })}
              // isLoading={languageOptions?.length === 0}
              styles={voiceSettingStyles}
              hideSelectedOptions={true}
              isClearable={false}
              name="Default Card Language"
              options={userSettings ? cardLanguageOptions : []}
              onChange={(value) => {
                setSelectedOptions((prev) => ({
                  ...prev,
                  defaultCardLanguage: value as SingleValue<Option>,
                }));
              }}
              placeholder="Default Card Language"
              components={{
                DropdownIndicator: selectedOptions?.defaultCardLanguage
                  ? CustomDropdownIndicator
                  : null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="flex md:flex-col justify-around gap-2 pt-5">
            <Button
              className="text-white rounded-md px-10"
              variant="contained"
              color="secondary"
              onClick={updateUserSetting}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                fontWeight: 500,
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VoiceConfigModel;
