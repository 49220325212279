const useDebounce = (callBack: (value?: any) => void, timeout: number, pattern?: RegExp) => {
  let timeOutId: NodeJS.Timeout;

  return (value?: any) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }

    timeOutId = setTimeout(() => {
      if (pattern) {
        if (value && pattern.test(value)) {
          callBack(value);
        } else {
          callBack("");
        }
      } else if (value) {
        callBack(value);
      } else {
        callBack();
      }
    }, timeout);
  };
};

export default useDebounce;
