import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: " 16px 20px ",
  backgroundColor: theme.palette.primary.main,
  border: " 1px solid",
  borderRadius: "12px",
  borderColor: theme.palette.primary.light,
}));
