import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearAll } from "../../store";

const loginModalSlice = createSlice({
  name: "login modal",
  initialState: false,
  reducers: {
    setLoginModalState(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
    toggleLoginModal(state) {
      return !state;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => false);
  },
});

export const { setLoginModalState, toggleLoginModal } = loginModalSlice.actions;
export default loginModalSlice.reducer;
