import React, { useRef } from "react";
import LoginModal from "../Modals/LoginModal";
import ReviewModal from "../Modals/ReviewModal";
import AlertBar from "./Alert";
import VoiceConfigModel from "../Modals/VoiceConfigModel";
import DrawerSM from "../Home/Drawer_sm";
import { DrawerForwardRef } from "../../pages/Home/index.types";

const GlobalDialogs = () => {
  const DrawerRef = useRef<DrawerForwardRef>(null);

  return (
    <>
      {/* Absolute compopnents ie. modals, dialog, alert */}
      <DrawerSM ref={DrawerRef} />
      <VoiceConfigModel />
      <LoginModal />
      <ReviewModal />
      <AlertBar />
    </>
  );
};

export default GlobalDialogs;
