import { useRef } from "react";
import { addToFavourites, removeFromFavourites } from "../../../../../../../../../../service/firebase/firebaseQueries";
import { PhraseAndTermsData, Res } from "../../../../../../../../../../service/firebase/firebase.types";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFavouriteId,
  updateFavourites,
} from "../../../../../../../../../../store/features/data/phraseAndTermSlice";
import { RootState } from "../../../../../../../../../../store/store";
import { setAlert } from "../../../../../../../../../../store/features/general/alertSlice";

type HandleConfirm = {
  cardData: PhraseAndTermsData;
  stopProcessing?: () => void;
};

const useUpdateFavourites = () => {
  const dispatch = useDispatch();
  const ref = useRef("");
  const showOnlyFavourites = useSelector((state: RootState) => state.appliedFilters.showFavourites);

  async function updateDB({ cardData }: HandleConfirm) {
    let response: Res;

    if (cardData.isFavourite) {
      response = await removeFromFavourites(cardData);
    } else {
      response = await addToFavourites(cardData);
    }

    if (response.status !== 200) {
      dispatch(
        updateFavourites({ type: cardData.isFavourite ? "ADD" : "REMOVE", cardId: cardData.phrase_and_term_id })
      );
      dispatch(setAlert({ type: "error", message: "An error occured while adding to favourite!", duration: 800 }));
    } else {
      dispatch(
        updateFavouriteId({
          favouriteId: response.data.favouriteId as string,
          cardId: cardData.phrase_and_term_id,
          showOnlyFavourites: showOnlyFavourites,
        })
      );
    }
  }

  async function handleConfirm({ cardData }: HandleConfirm) {
    if (ref.current !== cardData.phrase_and_term_id) {
      ref.current = cardData.phrase_and_term_id;
      if (cardData.isFavourite) {
        dispatch(updateFavourites({ type: "REMOVE", cardId: cardData.phrase_and_term_id }));
      } else {
        dispatch(updateFavourites({ type: "ADD", cardId: cardData.phrase_and_term_id }));
      }
      await updateDB({ cardData });

      if (ref.current === cardData.phrase_and_term_id) {
        ref.current = "";
      }
    }
  }

  return { handleConfirm };
};

export default useUpdateFavourites;
