import { StylesConfig } from "react-select";
import { theme as muiTheme } from "../../../../../../../theme";
import { Option } from "../../index.types";

// style config for react-select
export const colourStyles: StylesConfig<Option> = {
  control: (styles) => ({
    ...styles,
    fontSize: "14px", //14px
    fontWeight: 500,
    cursor: "pointer",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      margin: 0,
      fontSize: "16px",
      fontWeight: 500,

      color: isSelected
        ? muiTheme.palette.secondary.light
        : isFocused
        ? muiTheme.palette.secondary.main
        : "#999999",

      ":active": {
        color: muiTheme.palette.secondary.main,
        backgroundColor: !isDisabled
          ? isSelected
            ? muiTheme.palette.secondary.light
            : muiTheme.palette.secondary.light
          : undefined,
      },
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "start",
    height: "25px",
  }),
};

export const voiceSettingStyles: StylesConfig<Option> = {
  control: (styles) => ({
    ...styles,
    fontSize: "14px", //14px
    fontWeight: 500,
    cursor: "pointer",
    backgroundColor: "#F6FAFD",
    border: "1px solid #EAEEF4",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      margin: 0,
      fontSize: "16px",
      fontWeight: 500,

      color: isSelected
        ? muiTheme.palette.secondary.light
        : isFocused
        ? muiTheme.palette.secondary.main
        : "#999999",

      ":active": {
        color: muiTheme.palette.secondary.main,
        backgroundColor: !isDisabled
          ? isSelected
            ? muiTheme.palette.secondary.light
            : muiTheme.palette.secondary.light
          : undefined,
      },
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "start",
    height: "25px",
  }),
};

export const colourStylesSearch: StylesConfig<Option> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: "14px", //14px
    fontWeight: 500,
    cursor: "pointer",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      margin: 0,
      fontSize: "16px",
      fontWeight: 500,

      color: isSelected
        ? muiTheme.palette.secondary.light
        : isFocused
        ? muiTheme.palette.secondary.main
        : "#999999",

      ":active": {
        color: muiTheme.palette.secondary.main,
        backgroundColor: !isDisabled
          ? isSelected
            ? muiTheme.palette.secondary.light
            : muiTheme.palette.secondary.light
          : undefined,
      },
    };
  },
};
