import React from "react";
import { theme as muiTheme } from "../../../../../../../../../theme";
import Select, { ControlProps, components } from "react-select";
import SearchIcon from "../../../../../../../../../assets/Icons/SearchIcon";
import { Stack } from "@mui/material";
import { colourStylesSearch } from "../../../common/react_select.styleConfig";
import {
  CategoryOptions,
  TopicOptions,
} from "../../../../../../../../../types/global.types";
import "./index.css";
import { useGeneralContext } from "../../../../../../../../../context/GeneralContextProvider";

type Option = {
  value: string;
  label: string;
};

const Control = ({ children, ...props }: ControlProps<Option, false>) => {
  return (
    <components.Control {...props}>
      <Stack className=" cursor-pointer ml-2" alignItems={"center"}>
        <SearchIcon
          width={16}
          height={16}
          className="overflow-hidden rounded-full"
        />
      </Stack>
      {children}
    </components.Control>
  );
};

interface Props {
  isLoading: boolean;
  value: TopicOptions | CategoryOptions | null | undefined;
  options: TopicOptions[] | CategoryOptions[];
  placeHolder: string;
  filterTopics?: (filter_id: string) => void;
  setSelected: (typedItem: any) => void;
}

const CategoryTopicSelect: React.FC<Props> = ({
  isLoading,
  value,
  options,
  placeHolder,
  filterTopics,
  setSelected,
}) => {
  const { smallScreen } = useGeneralContext();
  return (
    <Select
      isLoading={isLoading}
      value={value}
      isSearchable={false}
      minMenuHeight={300}
      maxMenuHeight={180}
      menuPlacement="auto"
      classNamePrefix="react-select"
      blurInputOnSelect={smallScreen ? false : true}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary25: muiTheme.palette.secondary.light,
          primary: muiTheme.palette.secondary.main,
        },
      })}
      styles={colourStylesSearch}
      hideSelectedOptions={true}
      isClearable={true}
      name="Filter Options"
      options={options}
      placeholder={placeHolder}
      onChange={(item: any) => {
        setSelected(item);
        filterTopics && filterTopics(item?.category_id ?? "");
      }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        // ClearIndicator: () => null,
        Control,
      }}
    />
  );
};

// const CategoryTopicSelect = React.memo(MemoCategoryTopicSelect);
export default CategoryTopicSelect;
