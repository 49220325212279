import { SvgIconProps } from "@mui/material";
import React from "react";

const SwapIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "16"}
      height={height ?? "13"}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.71632 0.400264L0.720068 3.56693L0.657069 3.64214C0.545226 3.79455 0.489916 3.98481 0.501515 4.1772C0.513114 4.3696 0.590825 4.55092 0.720068 4.68714L0.791318 4.75285C0.93571 4.8709 1.11595 4.92929 1.29822 4.91704C1.48049 4.9048 1.65227 4.82277 1.78132 4.68635L3.50032 2.86789V12.0433L3.50557 12.1359C3.52689 12.3286 3.61452 12.5064 3.75181 12.6354C3.88911 12.7644 4.0665 12.8357 4.25032 12.8358L4.33807 12.8302C4.52064 12.8075 4.68894 12.7148 4.81103 12.5698C4.93312 12.4247 5.00048 12.2373 5.00032 12.0433V2.87343L6.72007 4.68635L6.79132 4.75285C6.94208 4.87739 7.13226 4.93636 7.32288 4.91768C7.5135 4.899 7.69014 4.80409 7.81661 4.65238C7.94308 4.50067 8.00979 4.30365 8.00309 4.1017C7.99639 3.89974 7.91678 3.70814 7.78057 3.56614L4.77607 0.399472L4.70557 0.333763C4.56118 0.215708 4.38094 0.157324 4.19867 0.169568C4.01639 0.181811 3.84537 0.26384 3.71632 0.400264ZM11.7503 0.17068L11.6626 0.176222C11.4801 0.198909 11.3119 0.291483 11.1899 0.436385C11.0678 0.581288 11.0003 0.768441 11.0003 0.962347V10.1298L9.28057 8.31693L9.20932 8.25043C9.0585 8.12734 8.86894 8.06949 8.67916 8.08865C8.48939 8.1078 8.31364 8.20252 8.18766 8.35355C8.06168 8.50458 7.99491 8.70058 8.00093 8.90172C8.00695 9.10285 8.0853 9.29401 8.22007 9.43635L11.2231 12.603L11.2936 12.6695C11.438 12.7876 11.6182 12.846 11.8005 12.8337C11.9827 12.8215 12.1545 12.7394 12.2836 12.603L15.2806 9.43635L15.3436 9.36114C15.4554 9.20872 15.5107 9.01847 15.4991 8.82607C15.4875 8.63367 15.4098 8.45236 15.2806 8.31614L15.2093 8.25043C15.0649 8.13237 14.8847 8.07399 14.7024 8.08623C14.5201 8.09848 14.3484 8.18051 14.2193 8.31693L12.5003 10.133V0.962347L12.4951 0.870513C12.4737 0.677796 12.3861 0.500055 12.2488 0.371042C12.1115 0.242029 11.9341 0.170728 11.7503 0.17068Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SwapIcon;
