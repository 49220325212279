import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React, { useEffect, useRef } from "react";
import DeskTopNav from "../../components/Home/DesktopNav";
import DrawerSM from "../../components/Home/Drawer_sm";
import MainSection from "./components/MainSection";
import TopNav from "../../components/Home/TopNav";
import { DrawerForwardRef } from "./index.types";
import useLoadInitialData from "../../Hooks/useLoadInitialData";
import AlertBar from "../../components/common/Alert";
import AddReviewButton from "../../components/common/AddReviewButton";
import ReviewModal from "../../components/Modals/ReviewModal";
import LoginModal from "../../components/Modals/LoginModal";
import VoiceConfigModel from "../../components/Modals/VoiceConfigModel";

const Home = () => {
  // Load initial Data -i.e.- Topics, Categories, Languages
  useLoadInitialData();

  return (
    <div className="h-full overflow-auto">
      <Stack direction={"row"} height={"100%"} className=" ">
        <DeskTopNav />

        <Divider
          sx={{ display: { md: "flex", xs: "none" } }}
          orientation="vertical"
          flexItem
        />

        <Box className="flex-grow flex flex-col overflow-y-hidden">
          <MainSection className="shrink-0" />
        </Box>
      </Stack>

      <AddReviewButton />

      {/* Absolute compopnents ie. modals, dialog, alert */}
      {/* <LoginModal />
      <ReviewModal />
      <AlertBar /> */}
    </div>
  );
};

export default Home;
