import {
  Dialog,
  IconButton,
  Divider,
  Button,
  Stack,
  Avatar,
  Box,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import Select, {
  OptionProps,
  SingleValue,
  SingleValueProps,
  components,
} from "react-select";
import React from "react";
import SwapIcon from "../../../assets/Icons/SwapIcon";
import { StyledSwitch } from "../../Home/components/common/FilterControls/components/VIewOnlyFavourites/index.styled";
import {
  colourStyles,
  colourStylesSearch,
} from "../../Home/components/common/FilterControls/components/common/react_select.styleConfig";
import {
  StyledTab,
  WrapperStack,
  StyledButton,
} from "../../Home/components/common/FilterControls/index.styled";
import { useSelector } from "react-redux";
import DropDownIcon from "../../../assets/Icons/DropDownIcon";
import { useGeneralContext } from "../../../context/GeneralContextProvider";
import { RootState } from "../../../store/store";
import { getFlagByName } from "../../../utils/GeneralUtils";
import { theme as muiTheme } from "../../../theme/index";
import { Option } from "../../Home/components/common/FilterControls/index.types";

const CustomOption: React.FC<OptionProps<Option>> = ({ innerProps, label }) => {
  const Flag = getFlagByName(label);
  return (
    <div
      {...innerProps}
      className="flex flex-row gap-[8px] items-center overflow-hidden px-2.5 py-1 cursor-pointer"
    >
      {Flag ? (
        <Flag
          width={15}
          height={15}
          className=" overflow-hidden rounded-full"
        />
      ) : null}
      <span className=" line-clamp-1">{label}</span>
    </div>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<Option>> = ({
  children,
  data,
}) => {
  const Flag = getFlagByName(data?.label);
  return (
    <span className="flex flex-row gap-[8px] items-center cursor-pointer">
      {Flag ? (
        <Flag width={15} height={15} className="overflow-hidden rounded-full" />
      ) : null}
      <span className=" line-clamp-1">{data?.label}</span>
    </span>
  );
};
const CustomDropdownIndicator: React.FC<any> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon width={16} height={16} className=" cursor-pointer" />
    </components.DropdownIndicator>
  );
};

const FilterSection = () => {
  const primaryLanguage = useSelector(
    (state: RootState) => state.selectedFilters.languagePrimary
  );
  const secondaryLanguage = useSelector(
    (state: RootState) => state.selectedFilters.languageSecondary
  );
  const { smallScreen } = useGeneralContext();

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: 335,
          flexShrink: 0,
          height: "100%",
          display: { md: "flex", xs: "none" },
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Stack
          direction="column"
          className="flex-grow flex flex-col  overflow-y-auto"
        >
          <Stack direction="column" sx={{ flexGrow: 1, pt: 3, pb: 2, px: 2.5 }}>
            <>
              <Typography component={"h3"} variant="h3">
                Filter By
              </Typography>

              {/* filter options */}
              <Stack sx={{ mt: 3.5, gap: 1.5 }}>
                <Stack
                  sx={{
                    width: "100%",
                    padding: "0px",
                    userSelect: "none",
                    fontWeight: 500,
                    textAlign: "center",
                    alignItems: { sm: "start", md: "stretch" },
                  }}
                >
                  <Stack
                    zIndex={1}
                    direction="row"
                    gap={0.5}
                    className="phrase-terms"
                  >
                    <StyledTab disableRipple selected={true}>
                      Phrases
                    </StyledTab>
                    <StyledTab disableRipple selected={false}>
                      Terms
                    </StyledTab>
                  </Stack>
                </Stack>

                <WrapperStack className="language-box">
                  <Typography variant="h4">Language</Typography>

                  <Stack direction={"column"} sx={{ gap: 1.25 }}>
                    <Select
                      isSearchable={false}
                      value={primaryLanguage}
                      blurInputOnSelect={true}
                      minMenuHeight={300}
                      maxMenuHeight={250}
                      menuPlacement={smallScreen ? "top" : "auto"}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: muiTheme.palette.secondary.light,
                          primary: muiTheme.palette.secondary.main,
                        },
                      })}
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                      styles={colourStyles}
                      hideSelectedOptions={true}
                      isClearable={false}
                      name="Language Select"
                    />

                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <IconButton
                        disableRipple
                        className={`${"rotate-0"} transition-all duration-500`}
                        sx={{
                          padding: "0",
                          color: "#999999",
                          "&:hover": { color: "secondary.main" },
                        }}
                      >
                        <SwapIcon width={18} height={18} />
                      </IconButton>
                    </Stack>

                    <Select
                      isSearchable={false}
                      value={secondaryLanguage}
                      blurInputOnSelect={true}
                      minMenuHeight={300}
                      maxMenuHeight={250}
                      menuPlacement={smallScreen ? "top" : "auto"}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: muiTheme.palette.secondary.light,
                          primary: muiTheme.palette.secondary.main,
                        },
                      })}
                      styles={colourStyles}
                      hideSelectedOptions={true}
                      isClearable={false}
                      name="Language Select"
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                    />
                  </Stack>
                </WrapperStack>

                <WrapperStack className="category-topic">
                  {" "}
                  <Stack
                    justifyContent={"start"}
                    sx={{ gap: 1.25, width: "100%", cursor: "auto" }}
                  >
                    <Typography variant="h4" sx={{ userSelect: "text" }}>
                      Category
                    </Typography>
                    <Select
                      minMenuHeight={300}
                      maxMenuHeight={180}
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      blurInputOnSelect={smallScreen ? false : true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: muiTheme.palette.secondary.light,
                          primary: muiTheme.palette.secondary.main,
                        },
                      })}
                      styles={colourStylesSearch}
                      hideSelectedOptions={true}
                      isClearable={true}
                      name="Filter Options"
                      placeholder="Catogory"
                    />
                  </Stack>{" "}
                  <Stack
                    justifyContent={"start"}
                    sx={{ gap: 1.25, width: "100%", cursor: "auto" }}
                  >
                    <Typography variant="h4" sx={{ userSelect: "text" }}>
                      Topic
                    </Typography>
                    <Select
                      minMenuHeight={300}
                      maxMenuHeight={180}
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      blurInputOnSelect={smallScreen ? false : true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                          ...theme.colors,
                          primary25: muiTheme.palette.secondary.light,
                          primary: muiTheme.palette.secondary.main,
                        },
                      })}
                      styles={colourStylesSearch}
                      hideSelectedOptions={true}
                      isClearable={true}
                      name="Filter Options"
                      placeholder="Topic"
                    />
                  </Stack>
                </WrapperStack>

                <WrapperStack
                  className="view-favourites"
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    View Only Favourites
                  </Typography>
                  <StyledSwitch name="toggle-favourites" />
                </WrapperStack>

                <StyledButton
                  disableElevation
                  disableFocusRipple={true}
                  variant="contained"
                  color="secondary"
                >
                  Apply Filter
                </StyledButton>
              </Stack>
            </>
            <Box className=" flex-1" />
          </Stack>
          {/* divider if user signed in */}
          {/* {(userData === null || userData?.userId) && } */}
          <Divider />
          {/* <Divider sx={{}} />{" "} */}
          <Stack
            direction="row"
            sx={{
              px: 2.5,
              alignItems: "center",
            }}
          ></Stack>
          {/* <Stack
            direction="row"
            sx={{
              height: "71px",
              py: 2,
              px: 2.5,
              gap: 2,
              alignItems: "center",
            }}
          > */}
          {/* <>
              <Avatar sx={{ bgcolor: "secondary.main", fontSize: "16px" }}>
                {AvatarInitials}
              </Avatar>
              <Stack className="flex-grow">
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: 1.2,
                      fontWeight: 600,
                    }}
                  >
                    userName
                  </Typography>
                  <Box>
                    <Link
                      color="secondary"
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                      }}
                    >
                      Logout
                    </Link>
                  </Box>
                </>
              </Stack>
              <IconButton className=" border border-[#EDEDED] border-solid rounded-lg ">
                <SettingsIcon className=" text-[#138FD7]" />
              </IconButton>
            </> */}

          {/* <Avatar sx={{ bgcolor: "secondary.main", fontSize: "16px" }}>
    {AvatarInitials}s
  </Avatar>
  <Stack className="flex-grow">
    <>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: 1.2,
          fontWeight: 600,
        }}
      >
        {getDisplayName}dcd
      </Typography>
      <Box>
        <Link
          onClick={handleSignOut}
          color="secondary"
          sx={{
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: 500,
            textTransform: "uppercase",
          }}
        >
          Logout
        </Link>
      </Box>
    </>
  </Stack>
  <IconButton className=" border border-[#EDEDED] border-solid rounded-lg ">
    <SettingsIcon className=" text-[#138FD7]" />
  </IconButton> */}
          {/* </Stack> */}
        </Stack>
      </Box>
    </>
  );
};

export default FilterSection;
