import { SvgIconProps } from "@mui/material";
import React from "react";

const AddReviewIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 29C0 45.0163 12.9837 58 29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0H10.973C7.2666 0 5.4135 0 3.982 0.684C2.538 1.374 1.374 2.538 0.684 3.982C0 5.4135 0 7.2666 0 10.973V29Z"
        fill="#138FD7"
      />
      <path
        d="M22.5 25C24.433 25 26 23.433 26 21.5C26 19.567 24.433 18 22.5 18C20.567 18 19 19.567 19 21.5C19 23.433 20.567 25 22.5 25Z"
        fill="white"
      />
      <path
        d="M40 21.5C40 22.4283 39.6313 23.3185 38.9749 23.9749C38.3185 24.6313 37.4283 25 36.5 25C35.5717 25 34.6815 24.6313 34.0251 23.9749C33.3687 23.3185 33 22.4283 33 21.5H34.2394C34.2394 22.0996 34.4775 22.6746 34.9015 23.0985C35.3254 23.5225 35.9004 23.7606 36.5 23.7606C37.0996 23.7606 37.6746 23.5225 38.0985 23.0985C38.5225 22.6746 38.7606 22.0996 38.7606 21.5H40Z"
        fill="white"
      />
      <path
        d="M41 32C41 34.9174 39.8411 37.7153 37.7782 39.7782C35.7153 41.8411 32.9174 43 30 43C27.0826 43 24.2847 41.8411 22.2218 39.7782C20.1589 37.7153 19 34.9174 19 32H30H41Z"
        fill="white"
      />
    </svg>
  );
};

export default AddReviewIcon;
