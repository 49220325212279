import React, { createContext, useContext } from "react";
import { Children } from "../types/global.types";
import { useMediaQuery } from "@mui/material";
import { theme } from "../theme";

interface GeneralContextType {
  smallScreen: boolean;
}

const defaultGeneralContext = {
  smallScreen: true,
};

const GeneralContext = createContext<GeneralContextType>(defaultGeneralContext);

const GeneralContextProvider: React.FC<Children> = ({ children }) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <GeneralContext.Provider value={{ smallScreen }}>
      <>{children}</>
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => useContext(GeneralContext);
export default GeneralContextProvider;
