import { Skeleton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import ReviewCard from "./ReviewCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviews } from "../../../service/firebase/firebaseQueries";
import { setReviews } from "../../../store/features/data/reviewSlice";
import { RootState } from "../../../store/store";

interface Props {
  reviewCount: number | null;
  setReviewCount: React.Dispatch<React.SetStateAction<number | null>>;
}

const ReviewList = ({ setReviewCount, reviewCount }: Props) => {
  const dispatch = useDispatch();

  const reviewList = useSelector((state: RootState) => state.reviewList);
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  const getReviews = async () => {
    const reviews = await fetchReviews();
    dispatch(setReviews(reviews));
  };

  useEffect(() => {
    if (reviewList.length > 0) {
      setReviewCount(reviewList?.length);
    }
  }, [reviewList, reviewCount]);

  useEffect(() => {
    if (currentUserId) {
      getReviews();
    } else {
      dispatch(setReviews([]));
      setReviewCount(null);
    }
  }, [currentUserId]);

  useEffect(() => {
    if (reviewList.length > 0 || reviewCount === null) {
      setReviewCount(reviewList?.length);
    }
  }, []);

  return (
    <Stack
      sx={{
        flexDirection: "column",
        flexGrow: 1,
        padding: "20px 11px 14px",
        overflow: "hidden",
        overflowY: !!reviewCount ? "auto" : "hidden",
        gap: "12px",
        position: "relative",
      }}
    >
      {reviewCount ? (
        reviewList?.map((review) => {
          return <ReviewCard key={review?.created_at} review={review} />;
        })
      ) : reviewCount !== null && currentUserId ? (
        <div className="text-center col-span-full font-medium">
          {" "}
          Add Your First Review{" "}
        </div>
      ) : (
        Array(5)
          .fill(0)
          .map((_, index) => (
            <Stack
              key={index}
              direction={"column"}
              sx={{ padding: "5px", gap: "3px" }}
            >
              <Stack sx={{ flexDirection: "row", gap: "12px" }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  sx={{ width: "39px", height: "39px", borderRadius: "8px" }}
                />
                <Stack>
                  <Skeleton animation="wave" width={"150px"} height={18} />
                  <Skeleton animation="wave" width={"80px"} height={16} />
                </Stack>
              </Stack>
              <Stack sx={{ paddingLeft: "51px" }}>
                <Skeleton animation="wave" width={"230px"} height={16} />
              </Stack>
            </Stack>
          ))
      )}
    </Stack>
  );
};

export default ReviewList;
