import dayjs from "dayjs";
import { UserData } from "../service/firebase/firebase.types";
import { LanguageOptions } from "../types/global.types";
import { FlagNames, GetGeneralLanguage } from "./index.types";
import { SvgIconProps } from "@mui/material";
import React from "react";
import EsFlag from "../assets/Icons/EsFlag";
import UkFlag from "../assets/Icons/UkFlag";
import MxFlag from "../assets/Icons/MxFlag";
import UsFlag from "../assets/Icons/UsFlag";

export const getAvatarInitialsFromName = (name: string) => {
  let userNameArr: string[] = ["?"];

  if (name !== "") {
    userNameArr = name?.split(" ");
  } else {
    userNameArr = name?.split(" ");
  }

  return userNameArr.reduce((acc, data, index) => {
    if (index < 2) {
      return acc + data.slice(0, 1).toUpperCase();
    }
    return acc;
  }, "");
};

// Return First Two Letters of userNameArr
export function getAvatarInitials(userData: UserData | null | undefined) {
  if (userData) {
    return getAvatarInitialsFromName(userData?.displayName);
  }
  return "?";
}

const isGeneralLanguage = (language: LanguageOptions) => {
  return language?.label?.toLocaleLowerCase().includes("general");
};

//
const getGeneralLanguageId = ({
  languageList,
  groupId,
}: GetGeneralLanguage) => {
  return (
    languageList.find(
      (currentLang) =>
        isGeneralLanguage(currentLang) && currentLang.group_id === groupId
    )?.language_id ?? ""
  );
};

export const getFIlteredLanguages = (languageList: LanguageOptions[]) => {
  return languageList
    .map((lang) => {
      return {
        ...lang,
        general_language_id: getGeneralLanguageId({
          languageList,
          groupId: lang.group_id,
        }),
      };
    })
    .filter((lang) => !isGeneralLanguage(lang));
};

export const getDefaultLanguages = (languageList: LanguageOptions[]) => {
  const defaultPrimaryLanguage = languageList?.find(
    (lang) => lang?.group_id === 1
  );
  const defaultSecondaryLanguage = languageList?.find(
    (lang) => lang?.group_id === 2
  );

  return { defaultPrimaryLanguage, defaultSecondaryLanguage };
};

export function getFormatDateFromMilliseconds(milliseconds: number): string {
  const formattedDate = dayjs(milliseconds).format("D MMMM, YYYY");
  return formattedDate;
}

export const getFlagByName = (name: string): React.FC<SvgIconProps> => {
  switch (name) {
    case FlagNames.ES:
      return EsFlag;
    case FlagNames.UK:
      return UkFlag;
    case FlagNames.US:
      return UsFlag;
    case FlagNames.MX:
      return MxFlag;
    default:
      return UsFlag;
  }
};
