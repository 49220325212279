import { SvgIconProps } from "@mui/material";
import React from "react";

const StarFilledIcon: React.FC<SvgIconProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <svg
      width={width ?? "15"}
      height={height ?? "14"}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1377 6.36394L11.2203 8.91009L12.0942 12.7008C12.1405 12.899 12.1273 13.1064 12.0563 13.2971C11.9853 13.4878 11.8597 13.6534 11.6951 13.7731C11.5305 13.8928 11.3343 13.9613 11.131 13.9701C10.9277 13.9789 10.7263 13.9276 10.552 13.8225L7.2458 11.8172L3.94667 13.8225C3.77238 13.9276 3.57099 13.9789 3.36769 13.9701C3.16439 13.9613 2.96819 13.8928 2.80362 13.7731C2.63905 13.6534 2.51342 13.4878 2.44243 13.2971C2.37144 13.1064 2.35825 12.899 2.40449 12.7008L3.27714 8.91397L0.359037 6.36394C0.204695 6.23083 0.0930899 6.05511 0.0382169 5.85882C-0.0166561 5.66253 -0.0123543 5.45441 0.0505828 5.26055C0.11352 5.0667 0.232291 4.89574 0.392001 4.76912C0.551711 4.6425 0.745251 4.56585 0.948351 4.54878L4.79474 4.21563L6.29617 0.634467C6.37457 0.446557 6.50682 0.286046 6.67627 0.173144C6.84571 0.0602433 7.04477 0 7.24838 0C7.45199 0 7.65106 0.0602433 7.8205 0.173144C7.98994 0.286046 8.12219 0.446557 8.2006 0.634467L9.70655 4.21563L13.5516 4.54878C13.7547 4.56585 13.9483 4.6425 14.108 4.76912C14.2677 4.89574 14.3865 5.0667 14.4494 5.26055C14.5124 5.45441 14.5167 5.66253 14.4618 5.85882C14.4069 6.05511 14.2953 6.23083 14.141 6.36394H14.1377Z"
        fill="#F7E228"
      />
    </svg>
  );
};

export default StarFilledIcon;
