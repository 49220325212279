import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import store from "./store/store";
import App from "./App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import "./index.css";
import { theme } from "./theme";
import {
  BrowserRouter,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import GeneralContextProvider from "./context/GeneralContextProvider";
import AuthContext from "./context/AuthContext";
import Whywe from "./pages/Whywe";
import TopNav from "./components/Home/TopNav";
import Welcome from "./pages/Welcome";
import Features from "./pages/Features";
import Flashcards from "./pages/Flashcards";
import Home from "./pages/Home";
import GlobalDialogs from "./components/common/GlobalDialogs";
// import { PersistGate } from "redux-persist/integration/react";

const RouteManager = () => {
  return null; // Placeholder, you can implement this component as needed
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContext>
        <GeneralContextProvider>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <ThemeProvider theme={theme}>
            <BrowserRouter basename="/">
              <CssBaseline /> <TopNav /> <GlobalDialogs />
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="/about" element={<Whywe />} />
                <Route path="/features" element={<Features />} />
                {/* <Route path="/reference" element={<App />} /> */}
                <Route path="/flashcards" element={<Flashcards />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
          {/* </PersistGate> */}
        </GeneralContextProvider>
      </AuthContext>
    </Provider>{" "}
    <RouteManager /> {/* Include RouteManager component */}
  </React.StrictMode>
);
