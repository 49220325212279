import { Stack, FormControlLabel, Checkbox, Divider } from "@mui/material";
import React from "react";
import { StyledTab, WrapperStack } from "../../index.styled";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { setSelectedFilters } from "../../../../../../../store/features/filters/selectedFilterSlice";

const PhraseTermFilter = () => {
  const dispatch = useDispatch();

  const selectedType = useSelector(
    (state: RootState) => state.selectedFilters.type
  );

  function toggleType(type: "PHRASE" | "TERM") {
    if (type !== selectedType) {
      dispatch(setSelectedFilters({ type }));
    }
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          padding: "0px",
          userSelect: "none",
          fontWeight: 500,
          textAlign: "center",
          alignItems: { sm: "start", md: "stretch" },
        }}
        className="small-phrase-terms"
      >
        <Stack zIndex={1} direction="row" gap={0.5}>
          <StyledTab
            disableRipple
            selected={selectedType === "PHRASE"}
            onClick={() => toggleType("PHRASE")}
          >
            Phrases
          </StyledTab>
          <StyledTab
            disableRipple
            selected={selectedType === "TERM"}
            onClick={() => toggleType("TERM")}
          >
            Terms
          </StyledTab>
        </Stack>
      </Stack>
    </>
  );
};

export default PhraseTermFilter;
