import { Stack, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import { WrapperStack } from "../../index.styled";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import {
  CategoryOptions,
  TopicOptions,
} from "../../../../../../../types/global.types";
import { AccordionData, AccordionTitle, StyledAccordion } from "./index.styled";
import CategoryTopicSelect from "./components/CategoryTopicSelect";
import { setSelectedFilters } from "../../../../../../../store/features/filters/selectedFilterSlice";

const CategoryTopicFilter = () => {
  const dispatch = useDispatch();

  const categoryList = useSelector((state: RootState) => state.categoryList);
  const topicList = useSelector((state: RootState) => state.topicList);

  const selectedCategory = useSelector(
    (state: RootState) => state.selectedFilters.category
  );
  const selectedTopic = useSelector(
    (state: RootState) => state.selectedFilters.topic
  );

  const [topicOptions, setTopicOptions] = useState<TopicOptions[]>([]);

  function filterTopicOPtions(filterId: string) {
    setTopicOptions(
      topicList.filter((topic) => topic.category_id === filterId)
    );
  }

  function setSelectedCategory(category: CategoryOptions) {
    dispatch(
      setSelectedFilters({
        topic: null,
        category,
      })
    );
  }

  function setSelectedTopic(topic: TopicOptions) {
    dispatch(
      setSelectedFilters({
        topic,
      })
    );
  }

  return (
    <WrapperStack className="small-category-topic">
      <StyledAccordion
        elevation={0}
        disableGutters
        expanded={!!topicOptions?.length}
      >
        <AccordionTitle
          expandIcon={null}
          aria-controls="Select Category"
          id="Select-Category"
        >
          <Stack
            justifyContent={"start"}
            sx={{ gap: 1.25, width: "100%", cursor: "auto" }}
          >
            <Typography variant="h4" sx={{ userSelect: "text" }}>
              Category
            </Typography>
            <CategoryTopicSelect
              isLoading={!categoryList.length}
              options={categoryList}
              value={selectedCategory}
              filterTopics={filterTopicOPtions}
              setSelected={setSelectedCategory}
              placeHolder={"Search category"}
            />
          </Stack>
        </AccordionTitle>

        <AccordionData>
          <Divider
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />

          <Stack sx={{ gap: 1.25 }}>
            <Typography variant="h4">Topics</Typography>
            <CategoryTopicSelect
              isLoading={!topicOptions.length}
              value={selectedTopic}
              options={topicOptions}
              setSelected={setSelectedTopic}
              placeHolder={"Search Topic"}
            />
          </Stack>
        </AccordionData>
      </StyledAccordion>
    </WrapperStack>
  );
};

export default CategoryTopicFilter;
