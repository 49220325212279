import { SvgIconProps } from "@mui/material";
import React from "react";

const DotIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "16"}
      height={height ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FDA599" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
    </svg>
  );
};

export default DotIcon;
