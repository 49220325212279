import { Flag } from "@mui/icons-material";
import { Stack, Box, Typography, IconButton, Divider } from "@mui/material";
import React from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CategoryIcon from "../../../assets/Icons/CategoryIcon";
import StarIcon from "../../../assets/Icons/StarIcon";
import { StyledCard } from "../../Home/components/MainSection/components/PhraseAndTermList/PhraseAndTermCard/index.styled";
import { getFlagByName } from "../../../utils/GeneralUtils";
import { FlagNames } from "../../../utils/index.types";

type Props = {
  data: { primary: string; secondary: string };
};
const Card = ({ data }: Props) => {
  const PrimaryFlag = getFlagByName(FlagNames.US);
  const SecoundryFlag = getFlagByName(FlagNames.ES);

  return (
    <StyledCard elevation={0}>
      <IconButton
        className="add-favourites"
        aria-label="delete"
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
        }}
      >
        <StarIcon />
      </IconButton>
      <Stack gap={1.5}>
        <Box sx={{ maxWidth: "85%" }}>
          <Typography
            className="line-clamp-2 whitespace-break-spaces break-all flex items-center gap-1.5 mb-0.5 "
            sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.5)" }}
          >
            {PrimaryFlag && (
              <PrimaryFlag
                width={20}
                height={20}
                className="overflow-hidden rounded-full"
              />
            )}
            US English
            <IconButton className="text-to-speech">
              <VolumeUpIcon className=" text-lg text-text-secondary" />
            </IconButton>
          </Typography>
          <Typography
            className="line-clamp-2 whitespace-break-spaces break-words h-[48px]"
            sx={{
              fontWeight: "550",
              fontSize: "16px",
              height: "42px",
            }}
          >
            {data.primary}
            {/* <div dangerouslySetInnerHTML={{ __html: secNewValue }}></div> */}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "85%" }}>
          <Typography
            className="line-clamp-2 whitespace-break-spaces break-all flex items-center gap-1.5 mb-0.5 "
            sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.5)" }}
          >
            {SecoundryFlag && (
              <SecoundryFlag
                width={20}
                height={20}
                className="overflow-hidden rounded-full"
              />
            )}
            EU Spanish
            <IconButton>
              <VolumeUpIcon className=" text-lg text-text-secondary" />
            </IconButton>
          </Typography>
          <Typography
            className="line-clamp-2 whitespace-break-spaces break-words h-[48px]"
            sx={{
              fontWeight: "550",
              fontSize: "16px",
              height: "42px",
            }}
          >
            {data.secondary}
            {/* <div dangerouslySetInnerHTML={{ __html: secNewValue }}></div> */}
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Typography
          sx={{ fontSize: "12px", textAlign: "end", fontWeight: 500 }}
        >
          sss
        </Typography>
        <Divider />
      </Box>
      <Stack
        direction={"row"}
        sx={{
          color: "secondary.main",
        }}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <CategoryIcon />
          <Typography
            sx={{ textTransform: "capitalize" }}
            fontSize={14}
            fontWeight={600}
          >
            category
          </Typography>
        </Stack>

        <Typography
          sx={{ textTransform: "capitalize" }}
          fontSize={14}
          fontWeight={600}
        >
          topic
        </Typography>
      </Stack>
    </StyledCard>
  );
};

export default Card;
