import React from "react";
import { CircularProgress, IconButton } from "@mui/material";
import StarFilledIcon from "../../../../../../../../../assets/Icons/StarFilledIcon";
import StarIcon from "../../../../../../../../../assets/Icons/StarIcon";
import useUpdateFavourites from "./hooks/useUpdateFavourites";
import { PhraseAndTermsData } from "../../../../../../../../../service/firebase/firebase.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../store/store";
import { setLoginModalState } from "../../../../../../../../../store/features/general/loginModalSlice";

interface Props {
  cardData: PhraseAndTermsData;
  className?: string;
}

const FavouriteButton: React.FC<Props> = ({ cardData, className }) => {
  const dispatch = useDispatch();

  const toggleLoginModal = () => dispatch(setLoginModalState(true));
  const { handleConfirm } = useUpdateFavourites();
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  function handleClick() {
    if (currentUserId) {
      handleConfirm({ cardData });
    } else {
      toggleLoginModal();
    }
  }

  return (
    <IconButton
      onClick={handleClick}
      aria-label="delete"
      sx={{
        position: "absolute",
        top: "8px",
        right: "8px",
        color: "#1C1C1C",
      }}
      className={className}
    >
      {cardData.favouriteId === "PROCESSING" ? (
        <CircularProgress color="secondary" size={17} />
      ) : cardData.isFavourite ? (
        <StarFilledIcon />
      ) : (
        <StarIcon />
      )}
    </IconButton>
  );
};

export default FavouriteButton;

// export const addToFavouriteData: any = {
//   dialogLabel: "",
//   dialogDescription: "",
//   title: "Add To Favourites",
//   content: "This will save the item for quick access later.",
//   confirm: "Add",
// };

// export const removeFromFavouriteData: DialogDataType = {
//   dialogLabel: "",
//   dialogDescription: "",
//   title: "Remove From Favourites ?",
//   content: "This will remove item from favourites permenently.",
//   confirm: "Remove",
// };

// const handleClick = () => {
//   if (!isProcessing && currentUserId) {
//     setIsProcessing(true);

//     const stopProcessing = () => setIsProcessing(false);

//     setDialogControls({
//       dialogData: isFavourite ? removeFromFavouriteData : addToFavouriteData,
//       handleConfirm: () => handleConfirm({ isFavourite, cardData, stopProcessing }),
//       handleReject: () => setIsProcessing(false),
//     });
//   }
// };
