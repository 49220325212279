import { RedirectRes } from "./../service/firebase/firebase.types";
import Cookies from "universal-cookie";
import {
  getRedirectSignInResult,
  handleSignInWithEmailLink,
  hasSignInWithEmailLink,
} from "../service/firebase/firebaseAuth";
import { createNewUserDoc } from "../service/firebase/firebaseQueries";

export function setHasRedirectResponse(state: boolean) {
  const cookies = new Cookies();
  const current = new Date();
  let expirationDate;

  if (state) {
    expirationDate = new Date(current.getTime() + 15 * 60 * 1000);
  } else {
    expirationDate = new Date(0);
  }
  cookies.set("hasRedirectResponse", true, {
    expires: expirationDate,
  });
}

export async function handleSignInRedirect(
  setAuthenticating: (state: boolean) => void
) {
  let res: RedirectRes = { status: 404, data: null, msg: "", error: "" };
  const isRedirected = await hasRedirectResponse();
  if (isRedirected) {
    setAuthenticating(true);
    res = await getRedirectSignInResult();
    setAuthenticating(false);
  } else {
    const email = await hasSignInWithEmailLink();
    if (email) {
      setAuthenticating(true);
      res = await handleSignInWithEmailLink(email);
      setAuthenticating(false);
    }
  }

  // handle create New user
  if (res.status === 200) {
    res.msg = "Signed in successfully!";
    if (res.data?.isNewUser) {
      createNewUserDoc(res.data);
    }
  }
  return res;
}

async function hasRedirectResponse() {
  const cookies = new Cookies();
  const response = !!(await cookies.get("hasRedirectResponse"));
  setHasRedirectResponse(false);
  return response;
}
