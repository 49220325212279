import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { VoiceConfigType } from "../../../types/global.types";

const initialState: VoiceConfigType = {
  male: null,
  female: null,
};

const voiceConfigSlice = createSlice({
  name: "voiceConfigs",
  initialState: initialState as VoiceConfigType,
  reducers: {
    setVoiceConfigs(_, action: PayloadAction<VoiceConfigType>) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export default voiceConfigSlice.reducer;
export const { setVoiceConfigs } = voiceConfigSlice.actions;
