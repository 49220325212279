import algoliasearch from "algoliasearch";
import { FavouriteList, FetchProps } from "./algolia.types";
import { PhraseAndTermsData } from "../firebase/firebase.types";
import { LanguageLevel } from "../../types/global.types";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_PROJECT_ID || "",
  process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY || ""
);
const fetchIndex = client.initIndex("PhrasesAndTerms");
const FavouritesIndex = client.initIndex("Favourites");
const hitsPerPage: number = 24;
fetchIndex.setSettings({ customRanking: ["asc(fake_index)"] });
FavouritesIndex.setSettings({ customRanking: ["asc(fake_index)"] });

const attributesToRetrieve = [
  "access",
  "category_id",
  "created_at",
  "fake_index",
  "phrase_and_term_id",
  "primary_data",
  "secondary_data",
  "topic_id",
  "type",
];

const accessOptions = {
  [LanguageLevel.Beginner]: "access:0",
  [LanguageLevel.Intermediate]: "access:1",
  [LanguageLevel.All]: "access:0 OR access:1",
};

const sanatizeResponse = {
  attributesToRetrieve,
  attributesToHighlight: [],
};

export async function fetchData({
  appliedFilters,
  page,
  currentUserId,
  languageLevel,
}: FetchProps) {
  const showOnlyFavourites = appliedFilters?.showFavourites;
  try {
    const searchQery = appliedFilters?.searchTerm;

    const access =
      accessOptions[(languageLevel as LanguageLevel) || LanguageLevel.All];

    const optionalFilters: (string | string[])[] = [];

    if (appliedFilters?.category?.category_id) {
      optionalFilters.push(
        `category_id:${appliedFilters.category?.category_id}`
      );

      if (appliedFilters.topic?.topic_id) {
        optionalFilters.push(`topic_id:${appliedFilters.topic?.topic_id}`);
      }
    }
    const searchlanguageList = (() => {
      const languagePrimary = appliedFilters.languagePrimary;
      const languageSecondary = appliedFilters.languageSecondary;
      return [
        languagePrimary?.language_id,
        languagePrimary?.general_language_id,
        languageSecondary?.language_id,
        languageSecondary?.general_language_id,
      ];
    })();

    const primarySearchLanguages = searchlanguageList
      .map((languageId) => `primary_data.primary_language_id:${languageId}`)
      .join(" OR ");

    const secondarySearchLanguages = searchlanguageList
      .map((languageId) => `secondary_data.secondary_language_id:${languageId}`)
      .join(" OR ");

    if (appliedFilters?.showFavourites) {
      optionalFilters.push(`user_id:${currentUserId}`);
    }

    // -----------ALGOLIA SEARCH QUERY -----------
    const response = await (appliedFilters?.showFavourites
      ? FavouritesIndex
      : fetchIndex
    ).search<any>(searchQery, {
      page,
      hitsPerPage,
      cacheable: appliedFilters?.showFavourites ? false : true,
      filters: `(${primarySearchLanguages}) AND (${secondarySearchLanguages}) AND (type:${
        appliedFilters?.type
      }) ${
        optionalFilters.length > 0
          ? `AND (${optionalFilters.join(") AND (")} )`
          : ""
      } AND (${access})`,
      ...sanatizeResponse,
    });

    const data = response?.hits?.map((hit) => {
      const created_at = hit?.created_at ?? null;
      return {
        ...hit,
        created_at,
        phrase_and_term_id: hit?.objectID,
      };
    });

    const matchedFavourites: FavouriteList[] = await getMatchedFavouriteData(
      data,
      currentUserId,
      showOnlyFavourites
    );

    const phraseAndTermData = getDataWithFavourite(
      data,
      matchedFavourites,
      showOnlyFavourites
    );

    return phraseAndTermData;
  } catch (error: any) {
    console.error(error, "error");
    console.log("An error occured at algolia search");
    return [];
  }
}
function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
// --------- helpers -----------
async function getMatchedFavouriteData(
  data: PhraseAndTermsData[],
  userId: string | undefined,
  showOnlyFavourites: boolean
): Promise<FavouriteList[]> {
  if (userId && !!data.length && !showOnlyFavourites) {
    const phraseIds = data.map(
      (phrase) => `phrase_and_term_id:${phrase.phrase_and_term_id}`
    );

    const response = await FavouritesIndex.search<any>("", {
      cacheable: false,
      hitsPerPage,
      filters: `(user_id:${userId}) AND (${phraseIds.join(" OR ")})`,
      ...sanatizeResponse,
    });

    return response.hits?.map((hit) => ({
      phraseId: hit?.phrase_and_term_id as string,
      favouriteId: hit?.objectID as string,
    }));
  } else {
    return [];
  }
}
// ------ utils ----------
function getDataWithFavourite(
  data: PhraseAndTermsData[],
  favourites: FavouriteList[],
  showOnlyFavourites: boolean
) {
  return data.map((phrase) => {
    const matchedData = favourites.find((favourite) => {
      return favourite.phraseId === phrase.phrase_and_term_id;
    });

    const isFavourite = showOnlyFavourites ? true : !!matchedData;
    const favouriteId = showOnlyFavourites
      ? phrase.phrase_and_term_id
      : matchedData?.favouriteId ?? null;
    return { ...phrase, isFavourite, favouriteId };
  });
}
