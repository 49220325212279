import { PaletteTextColors, Theme, createTheme } from "@mui/material";

// --------------------------------------------------------------------------
// CUSTOM THEME OBJECTS
// --------------------------------------------------------------------------

const paletteTextColors: PaletteTextColors = {
  primary: "#1C1C1C", // Replace with your desired color codes
  secondary: "#138FD7", // Replace with your desired color codes
  primaryLight: "#999999", // Replace with your desired color codes
  secondaryLight: "#F39E92", // Replace with your desired color codes
};

export const theme: Theme = createTheme({
  palette: {
    common: {
      white: "#fff",
      black: "#000",
    },
    text: paletteTextColors,

    grey: {
      50: "#EAEAEA",
      100: "#F7F9FB",
      200: "#00000015",
      300: "#D5DAE1",
      400: "#00000066",
      500: "#00000060",
      600: "#949597",
      700: "#656575",
    },

    primary: { main: "#F6FAFD", light: "#EAEEF4" },
    secondary: { main: "#138FD7", light: "#F6FAFD", contrastText: "#753027" },
    success: { main: "#039855", light: "#A5E9D9", dark: "#4F9E91" },
    error: { main: "#D32F2F", light: "#FF4747" },
    info: { main: "#138FD7", light: "#F6FAFD" },
  },
  typography: {
    fontFamily: "Urbanist, sans-serif",
    h1: {
      fontSize: "36px",
      lineHeight: 1,
      fontWeight: 700,
    },
    h2: {
      fontSize: "28px",
      lineHeight: 1,
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      lineHeight: 1,
      fontWeight: 600,
    },
    h4: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "none",
    },
  },

  breakpoints: {
    values: {
      xs: 315,
      sm: 535,
      md: 960,
      lg: 1280,
      xl: 1920,

      // xs: 300,
      // sm: 484,
      // md: 834,
      // lg: 1200,
      // xl: 1536,
    },
  },
});

// --------------------------------------------------------------------------
// TYPESCRIPT MODULES
// --------------------------------------------------------------------------
declare module "@mui/material/styles" {
  interface PaletteTextColors {
    primary: string;
    secondary: string;
    primaryLight: string;
    secondaryLight: string;
    // Add more custom text colors if needed
  }
}
