import { SvgIconProps } from "@mui/material";
import React from "react";

const SearchIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "14"}
      height={height ?? "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="6.86393"
        cy="6.86442"
        r="5.24333"
        stroke="#999999"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5107 10.7852L12.5664 12.8355"
        stroke="#999999"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
