import { createContext, useContext, useState } from "react";

type MyContextType = {
  isConfigVoiceDialog: boolean;
  setIsConfigVoiceDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isLoginVisible: boolean;
  setIsLoginVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filterDrawerState: boolean;
  setFilterDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  currentCardInfo: { index: number; isFliped: boolean } | null;
  setCurrentCardInfo: React.Dispatch<
    React.SetStateAction<{ index: number; isFliped: boolean } | null>
  >;
};

const defaultContext: MyContextType = {
  isConfigVoiceDialog: false,
  setIsConfigVoiceDialog: () => {},
  isLoginVisible: true,
  setIsLoginVisible: () => {},
  filterDrawerState: true,
  setFilterDrawerState: () => {},
  currentCardInfo: null,
  setCurrentCardInfo: () => {},
};

const Context = createContext<MyContextType>(defaultContext);

function AuthContext({ children }: { children: React.ReactNode }) {
  const [isConfigVoiceDialog, setIsConfigVoiceDialog] =
    useState<boolean>(false);

  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState<boolean>(false);
  const [currentCardInfo, setCurrentCardInfo] = useState<{
    index: number;
    isFliped: boolean;
  } | null>(null);

  return (
    <Context.Provider
      value={{
        isConfigVoiceDialog,
        setIsConfigVoiceDialog,
        isLoginVisible,
        setIsLoginVisible,
        filterDrawerState,
        setFilterDrawerState,
        currentCardInfo,
        setCurrentCardInfo,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default AuthContext;

export const useAuthContext = () => useContext(Context);
