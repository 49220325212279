export const cardList = [
  { primary: "May I come in?  ", secondary: "¿Puedo parar?" },
  { primary: "Where is my seat? ", secondary: "¿Dónde está mi asiento?" },
  {
    primary: "I'll never forget this night!",
    secondary: "¡Nunca olvidaré esta noche!",
  },
  {
    primary: "build a sandcastle.  ",
    secondary: "hacer un castillo de arena.",
  },
  {
    primary: "How long will it take to get there? ",
    secondary: "¿Cuánto tiempo se tarda en llegar?",
  },
  {
    primary: "I'm looking for a product for foot care.",
    secondary: "Busco un producto para el cuidado de los pies.",
  },
  {
    primary: "Can I send multiple packages together?",
    secondary: "¿Puedo enviar varios paquetes juntos?",
  },
  { primary: "Where is the bathroom?", secondary: "¿Dónde está el baño? " },
  { primary: "No, thank you. ", secondary: "No, gracias." },
  {
    primary: "What are your opening hours?",
    secondary: "¿Cuál es su horario de atención?",
  },
  {
    primary: "I need a dining table.",
    secondary: "Necesito una mesa de comedor.",
  },
  {
    primary: "Can I pay the bill separately?",
    secondary: "¿Puedo pagar la cuenta por separado?",
  },
  {
    primary: "Do you have any lactose-free dishes? ",
    secondary: "¿Tenéis algún plato sin lactosa?",
  },
];
