import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchBar from "../common/SearchBar";
import FilterIcon from "../../assets/Icons/FilterIcon";
import DotIcon from "../../assets/Icons/DotIcon";
import BrandTitle from "../../assets/Icons/BrandTitle";
import UserProfile from "../common/UserProfile";
import { DrawerForwardRef } from "../../pages/Home/index.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setLoginModalState } from "../../store/features/general/loginModalSlice";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useHandleSignIn from "../../Hooks/useHandleSignIn";
import MenuIcon from "../../assets/Icons/MenuIcon";
import MobileMenus from "../common/MobileMenus";

const TopNav = () => {
  const dispatch = useDispatch();
  const { isLoginVisible, setIsLoginVisible, setIsConfigVoiceDialog } =
    useAuthContext();
  const filters = useSelector((state: RootState) => state.appliedFilters);
  const [filterSelected, setFilterSelected] = useState<boolean>(false);

  const userData = useSelector((state: RootState) => state.userData);
  const { handleAuth } = useHandleSignIn();
  const location = useLocation();

  useEffect(() => {
    handleAuth();
  }, []);

  useEffect(() => {
    if (
      filters?.category?.value ||
      filters?.topic?.value ||
      filters?.showFavourites
    ) {
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }
  }, [filters]);

  return (
    <div className=" flex flex-col">
      <Stack
        className="w-full h-20 flex items-center justify-between "
        direction="column"
        sx={{
          gap: { xs: 1.5, sm: 0 },

          justifyContent: "space-between",
        }}
      >
        {/* Header */}
        {/* <Typography
          variant="h3"
          component={"h1"}
          sx={{
            fontWeight: 700,
            display: { md: "block", xs: "none" },
          }}
        >
          Explore Phrases and Terms
        </Typography> */}

        {/* User Login and Profile */}
        <Stack
          className="w-full h-full flex items-center"
          direction={"row"}
          justifyContent={"space-between"}
          sx={
            {
              // display: { md: "none" },
            }
          }
        >
          <div className="flex justify-between sm:pl-5 pl-4">
            <BrandTitle width={171} />
            <Divider
              className="my-2 px-5"
              sx={{ display: { md: "flex", xs: "none" } }}
              orientation="vertical"
              flexItem
            />
          </div>
          <div className="h-full flex-grow md:flex md:gap-10 sm:gap-5 gap-2 px-10 items-center font-semibold tracking-wide text-lg hidden">
            <NavLink
              to="/"
              className={`navItem ${location.pathname === "/" && "active"}`}
            >
              Home
            </NavLink>
            <Link
              to="/about"
              className={`navItem ${
                location.pathname === "/about" && "active"
              }`}
            >
              About
            </Link>
            <Link
              to="/features"
              className={`navItem ${
                location.pathname === "/features" && "active"
              }`}
            >
              Features
            </Link>
            {/* <Link
              to="/reference"
              className={`navItem ${
                location.pathname === "/reference" && "active"
              }`}
            >
              Reference
            </Link> */}
            <Link
              to="/flashcards"
              className={`navItem ${
                location.pathname === "/flashcards" && "active"
              }`}
            >
              Flashcards
            </Link>
          </div>
          <Stack className="flex items-center" direction="row">
            {location.pathname === "/features" && !isLoginVisible ? null : (
              <Stack
                direction="row"
                gap={2}
                className="items-center sm:pr-5 pr-4"
              >
                {location.pathname === "/features" && isLoginVisible ? (
                  <UserProfile showDetails={isLoginVisible} />
                ) : userData?.userId ? (
                  <UserProfile />
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(setLoginModalState(true));
                    }}
                    color="secondary"
                    variant="outlined"
                    disableFocusRipple
                    sx={{
                      borderRadius: "12px",
                      padding: "12px 24px",
                      lineHeight: "1",
                      boxShadow: "0 2px 6px #1010100A",
                      borderColor: "#EDEDED",
                    }}
                  >
                    Login
                  </Button>
                )}
              </Stack>
            )}
            <Stack className="sm:pr-5 pr-4 md:hidden block">
              <MobileMenus />
            </Stack>
          </Stack>
        </Stack>

        {/* search ++ filter Toggle Icon :: Sm */}
        {/* <Stack direction="row" gap={1.5} alignItems={"center"}>
          <SearchBar />

          <IconButton
            onClick={() => {
              toggleDrawer();
            }}
            sx={{ padding: 0, display: { md: "none" } }}
          >
            <FilterIcon />

            {filterSelected && (
              <DotIcon className="absolute top-0 right-0 translate-x-[25%] -translate-y-[25%]" />
            )}
          </IconButton>
        </Stack> */}
      </Stack>

      <Divider
        sx={{
          display: { xs: "none", sm: "block" },
          borderColor: "#EAEEF4",
          //   location.pathname === "/features" && !isLoginVisible
          //     ? "#000000 !important"
          //     : "#EAEEF4 !important",
          // opacity:
          //   location.pathname === "/features" && !isLoginVisible ? "0.5" : "1",
        }}
      />
      {/* {location.pathname === "/features" && !isLoginVisible ? : (
        <Divider
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        />
      )} */}
    </div>
  );
};

export default TopNav;
