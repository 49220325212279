import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Box, Button, Stack, Modal, Typography, Divider } from "@mui/material";
import BrandLogo from "../../assets/Icons/BrandLogo";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import { ModalContainer, ModalHeading, ModalText } from "./index.styled";
import CloseButton from "./components/CloseButton";
import useSendEmailLink from "./hooks/useSendEmailLink";
import { setLoginModalState } from "../../store/features/general/loginModalSlice";
import { useDispatch } from "react-redux";
import { useGeneralContext } from "../../context/GeneralContextProvider";

export type VerifyEmailRef = {
  toggleVerifyEmailModal: (state?: boolean) => void;
};

type Props = {
  email: string;
};

const VerifyEmailModal = forwardRef<VerifyEmailRef, Props>(({ email }, ref) => {
  const [modalState, setModalState] = useState(false);

  const dispatch = useDispatch();

  const { smallScreen } = useGeneralContext();

  const { sendEmailLinkTo } = useSendEmailLink();

  useImperativeHandle(ref, () => ({
    toggleVerifyEmailModal,
  }));

  function toggleVerifyEmailModal(state?: boolean) {
    if (state) {
      setModalState(state);
    } else {
      setModalState((prev) => !prev);
    }
  }

  function handleOpenGmail() {
    window.location.href = "https://mail.google.com/";
  }

  function handleSendEmail() {
    sendEmailLinkTo(email);
  }

  function handleChangeEmail() {
    setModalState(false);
    dispatch(setLoginModalState(true));
  }

  return (
    <Modal
      tabIndex={-1}
      open={modalState}
      onClose={() => toggleVerifyEmailModal(false)}
      aria-labelledby="Login-modal"
      aria-describedby="Login-to-continue"
    >
      <ModalContainer>
        <Box textAlign={"right"} marginBottom={"5px"}>
          <CloseButton onClick={() => toggleVerifyEmailModal(false)} />
        </Box>
        <Box paddingLeft={1.25}>
          <BrandLogo width={"44"} height={"44"} />
        </Box>

        {/* Login Container */}
        <Box marginTop={3}>
          <Stack className="dialog-heading" direction="column" gap={1.25}>
            <ModalHeading variant="h1">Verify your email</ModalHeading>

            <ModalText>Please check your inbox for a verification link</ModalText>
          </Stack>

          <Stack
            sx={{
              gap: 2,
              mt: 3,
              padding: "18px",
              borderRadius: 2,
              fontSize: "14px",
              bgcolor: "primary.main",
              border: "1px solid #D7D7D7 ",
            }}
          >
            <Box>
              <Typography fontSize={"14px"} fontWeight={400}>
                Verification mail sent to
              </Typography>
              <Typography fontSize={"14px"} fontWeight={600}>
                {email}
              </Typography>
            </Box>

            <Button
              fullWidth
              onClick={handleOpenGmail}
              disableFocusRipple
              variant="outlined"
              color="secondary"
              sx={{
                fontWeight: 700,
                padding: "12p x auto",
                borderRadius: "8px",
                bgcolor: "secondary.light",
                "&:hover": { bgcolor: "secondary.light" },
              }}
              endIcon={<OpenInNewIcon />}
            >
              Open Gmail
            </Button>
          </Stack>

          <Stack
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 400,
              gap: 1,
              mt: 3,
            }}
          >
            <Button
              disableFocusRipple
              onClick={handleSendEmail}
              sx={{
                gap: 1,
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                cursor: "pointer",
                color: "#6B6B6B",
                whiteSpace: "nowrap",
                padding: { xs: "4px", md: "6px 12px" },
              }}
            >
              <AutorenewIcon sx={{ fontSize: "22px" }} />
              Send a new link
            </Button>

            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: { xs: "none", sm: "block" },
                marginX: 3,
                marginY: "auto",
                height: "24px",
                "&.MuiDivider-root": {
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "#6b6b6b81",
                },
              }}
            />

            <Button
              disableFocusRipple
              onClick={handleChangeEmail}
              sx={{
                gap: 1,
                whiteSpace: "nowrap",
                color: "#6B6B6B",
                display: "flex",
                fontSize: "14px",
                alignItems: "center",
                cursor: "pointer",

                padding: { xs: "4px", md: "6px 12px" },
              }}
            >
              <CloseIcon sx={{ fontSize: "22px" }} />
              Use different mail
            </Button>
          </Stack>
        </Box>
      </ModalContainer>
    </Modal>
  );
});

export default VerifyEmailModal;
