import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import BrandTitle from "../../assets/Icons/BrandTitle";
import FilterControls from "../../pages/Home/components/common/FilterControls";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAvatarInitials } from "../../utils/GeneralUtils";
import useHandleSignOut from "../../Hooks/useHandleSignOut";
import { setLoginModalState } from "../../store/features/general/loginModalSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuthContext } from "../../context/AuthContext";
interface Props {}

const DeskTopNav: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const toggleLoginModal = () => dispatch(setLoginModalState(true));
  const { setIsConfigVoiceDialog } = useAuthContext();
  const { handleSignOut } = useHandleSignOut();

  const userData = useSelector((state: RootState) => state.userData);

  const AvatarInitials = useMemo(() => {
    return getAvatarInitials(userData);
  }, [userData]);

  const getDisplayName = useMemo(() => {
    const userName = userData?.displayName ?? "";

    if (userName !== "") {
      return userName;
    }
    return userData?.email.split("@").at(0) ?? "New User";
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{
        width: 335,
        flexShrink: 0,
        height: "100%",
        display: { md: "flex", xs: "none" },
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* {userData?.userId ? ( */}
      {/* <Button
      startIcon={<SettingsIcon />}
      onClick={() => {
        setIsConfigVoiceDialog(true);
      }}
      variant="outlined"
      color="secondary"
      sx={{
        textTransform: "none",
        color: "#000",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        fontWeight: 600,
        letterSpacing: "0.05em",
        borderRadius: "10px",
        fontSize: "16px",
        borderColor: "#EAEEF4",
      }}
    >
      Voice Setting
    </Button> */}
      {/* ) : null} */}
      <Stack
        direction="column"
        className="flex-grow flex flex-col  overflow-y-auto"
      >
        <Stack
          direction="column"
          sx={{ height: "100%", pt: 3, pb: 2, px: 2.5 }}
        >
          <FilterControls />
        </Stack>
        {/* divider if user signed in */}
        {/* {(userData === null || userData?.userId) && <Divider />} */}
        {/* <Divider sx={{}} />{" "} */}

        {/* <Stack
        direction="row"
        sx={{
          height: "71px",
          py: 2,
          px: 2.5,
          gap: 2,
          alignItems: "center",
        }}
      >
        {userData === null || userData?.userId ? (
          <>
            {userData?.userId ? (
              <Avatar sx={{ bgcolor: "secondary.main", fontSize: "16px" }}>
                {AvatarInitials}
              </Avatar>
            ) : (
              <Skeleton animation="wave" variant="circular">
                <Avatar />
              </Skeleton>
            )}
            <Stack className="flex-grow">
              {userData?.userId ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: 1.2,
                      fontWeight: 600,
                    }}
                  >
                    {getDisplayName}
                  </Typography>
                  <Box>
                    <Link
                      onClick={handleSignOut}
                      color="secondary"
                      sx={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                      }}
                    >
                      Logout
                    </Link>
                  </Box>
                </>
              ) : (
                <>
                  <Skeleton animation="wave" width={"90%"} height={24} />
                  <Skeleton animation="wave" width={"25%"} height={18} />
                </>
              )}
            </Stack>
            <IconButton
              className=" border border-[#EDEDED] border-solid rounded-lg "
              onClick={() => {
                setIsConfigVoiceDialog(true);
              }}
            >
              <SettingsIcon className=" text-[#138FD7]" />
            </IconButton>
          </>
        ) : (
          <Button
            onClick={toggleLoginModal}
            variant="contained"
            color="secondary"
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              width: "100%",
              fontWeight: 500,
              borderRadius: "10px",
              textTransform: "uppercase",
            }}
          >
            Login
          </Button>
        )}
      </Stack> */}
      </Stack>
    </Box>
  );
};

export default DeskTopNav;
