import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { CategoryOptions } from "../../../types/global.types";

const initialState: CategoryOptions[] = [];

const categorySlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    setCategories(state, action) {
      return action?.payload ?? [];
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export default categorySlice.reducer;
export const { setCategories } = categorySlice.actions;
