import React from "react";
import WhyWeIcon from "../../assets/Icons/WhyWe";

const Whywe = () => {
  return (
    <div className="w-screen flex-grow overflow-auto flex md:flex-row flex-col md:justify-center justify-start items-center xl:gap-20 gap-10 sm:p-10 p-5">
      {/* <img
        src="WhyWe.svg"
        alt=""
        className="w-96 lg:max-w-[40%] md:max-w-[60%] max-w-full  "
      /> */}
      <div className="w-96 lg:max-w-[40%] md:max-w-[60%]   max-w-full">
        <WhyWeIcon />
      </div>

      <div className="flex flex-col gap-3 lg:w-[40%] md:w-[60%] w-full  font-semibold text-base text-justify">
        <div className=" text-3xl font-black text-text-secondary text-start">
          About
        </div>
        <div>
          Embark on your Spanish language journey effortlessly and fearlessly
          with VerbValor! Our cutting-edge approach ensures a rapid and
          effective mastery of this beautiful language. Say goodbye to
          traditional classrooms; we've crafted the perfect solution for you.
        </div>
        <div className="text-text-secondary font-extrabold pt-5 mb-[-10px]">
          Discover the linguistic finesse of VerbValor:
        </div>
        <div>
          -Immerse yourself in an expansive open dictionary, offering a
          selection of dialect choices accompanied by seamless voice playback.
          Choose the nuances that resonate with you and elevate your language
          proficiency.
        </div>{" "}
        <div>
          -Curate a personalized list of phrases and terms with our favorites
          folder, where your preferred translations await. Tailor your learning
          experience to match your unique preferences and pace.
        </div>{" "}
        <div>
          -Put your memory to the test with our Verb Valor Flash Card feature.
          Dive into both beginner and advanced selections from our extensive
          database or fashion a bespoke learning journey from your favorites
          folder. Learning is not just efficient; it's a joyous and interactive
          endeavor.
        </div>{" "}
        <div>
          At VerbValor, we believe in transforming language learning into a
          seamless, enjoyable adventure. Break free from hesitations, speak
          confidently, and seamlessly blend in with local speakers. Unlock the
          doors to linguistic mastery with Verb Valor-where learning is as
          enjoyable as it is effective. ¡Vamos! (Let's go!)
        </div>{" "}
      </div>
    </div>
  );
};

export default Whywe;
