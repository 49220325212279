import { AlertColor } from "@mui/material";
import { SingleValue } from "react-select";

export interface Children {
  children: React.ReactNode;
}

// selected options
interface Option {
  value: string;
  label: string;
}

export interface LanguageOptions extends Option {
  group_id: number;
  language_id: string;
  general_language_id?: string;
  voice_language: string;
}

export interface CategoryOptions extends Option {
  category_id: string;
}
export interface TopicOptions extends Option {
  topic_id: string;
  category_id: string;
}

export type Favourites = {
  fav_phrase_id: string;
  created_at: number;
};

export type AlertType = {
  type: AlertColor | "loading";
  message: string | null;
  duration?: number;
} | null;

export interface FilterTypes {
  phrase: boolean;
  term: boolean;
  type: "PHRASE" | "TERM";
  languagePrimary: LanguageOptions | null;
  languageSecondary: LanguageOptions | null;
  isReverseFilter: boolean;
  category: CategoryOptions | undefined | null;
  topic: TopicOptions | undefined | null;
  showFavourites: boolean;
  searchTerm: string;
  isLoading?: boolean;
  sortById: boolean;
}

export interface ReviewType {
  review: string;
  user_id: string;
  user_name: string;
  created_at: number;
  review_id?: string;
}

type VoiceConfig = {
  languageCode: string;
  name: string;
  ssmlGender: string;
  naturalSampleRateHertz: string;
};

export type VoiceConfigType = {
  male?: Record<string, VoiceConfig> | null;
  female?: Record<string, VoiceConfig> | null;
};

export enum LanguageLevel {
  Beginner = "BEGINNER",
  Intermediate = "INTERMEDIATE",
  All = "ALL",
}

export type userSettingsType = {
  languageLevel?: SingleValue<Option>;
  gender?: SingleValue<Option>;
  defaultCardLanguage?: SingleValue<Option>;
};
