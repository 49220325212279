import { IconButton } from "@mui/material";
import React from "react";
import AddReviewIcon from "../../assets/Icons/AddReviewIcon";
import { useDispatch, useSelector } from "react-redux";
import { setReviewModalState } from "../../store/features/general/reviewModalSlice";
import { RootState } from "../../store/store";
import { setLoginModalState } from "../../store/features/general/loginModalSlice";

const AddReviewButton: React.FC = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  const handleOpenReviewModal = () => {
    if (currentUserId) {
      dispatch(setReviewModalState(true));
    } else {
      dispatch(setLoginModalState(true));
    }
  };

  return (
    <IconButton
      disableRipple
      onClick={handleOpenReviewModal}
      sx={{
        position: "absolute",
        bottom: { md: "48px", xs: "20px" },
        right: { md: "48px", xs: "20px" },
        padding: 0,
        zIndex: 100,
      }}
      aria-label="fingerprint"
      color="secondary"
    >
      <AddReviewIcon />
    </IconButton>
  );
};

export default AddReviewButton;
