import { auth, db } from "./config/firebaseConfig";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import {
  CategoryOptions,
  LanguageOptions,
  ReviewType,
  TopicOptions,
} from "../../types/global.types";
import {
  NewUser,
  PhraseAndTermsData,
  Res,
  UserSettings,
} from "./firebase.types";
import { getFIlteredLanguages } from "../../utils/GeneralUtils";
import {
  generateUserDoc,
  getDisplayName,
  getSenetizedData,
} from "./firebase.utils";

export const createNewUserDoc = async function (userData: NewUser) {
  try {
    const res = await setDoc(
      doc(db, "Users", userData.uid),
      generateUserDoc(userData)
    );
    // console.log({ res });
    return true;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchLanguages = async function () {
  const q = query(collection(db, "Languages"));
  const response = await getDocs(q);
  const languageList = response?.docs?.map((doc) => {
    return {
      value: doc?.data()?.language_name,
      label: doc?.data()?.language_name,
      group_id: doc?.data()?.group_id,
      voice_language: doc?.data()?.voice_language,
      language_id: doc?.id,
    } as LanguageOptions;
  });

  return getFIlteredLanguages(languageList);
};

export const fetchCategories = async function () {
  const q = query(
    collection(db, "Categories"),
    orderBy("category_fake_id", "asc")
  );
  const response = await getDocs(q);

  const categoryList = response.docs.map((doc) => {
    return {
      value: doc.data().category_name,
      label: doc.data().category_name,
      category_id: doc.id,
    } as CategoryOptions;
  });

  return categoryList;
};

export const fetchTopics = async function () {
  const q = query(collection(db, "Topics"), orderBy("topic_fake_id", "asc"));
  const response = await getDocs(q);

  const topicList = response.docs.map((doc) => {
    return {
      value: doc.data().topic_name,
      label: doc.data().topic_name,
      category_id: doc.data().category_id,
      topic_id: doc.id,
      fake_index: doc.data().topic_fake_id,
    } as TopicOptions;
  });

  return topicList;
};

export const fetchVoiceConfigs = async function () {
  const q = query(collection(db, "VoiceConfigs"));
  const response = await getDocs(q);

  const voiceConfigs = response.docs.reduce((acc, doc) => {
    return {
      ...acc,
      [doc.id]: doc.data(),
    };
  }, {});

  return voiceConfigs;
};

export const fetchUserVoiceConfigs = async function () {
  const currentUserId = auth.currentUser?.uid;
  if (currentUserId) {
    const docRef = doc(db, "Users", currentUserId);
    const response = await getDoc(docRef);
    const userSettings: UserSettings = response?.data()?.user_settings;
    return { userSettings };
  } else {
    return {};
  }
};

export const updateUserVoiceConfigs = async function (
  userSettings: UserSettings
) {
  const currentUserId = auth.currentUser?.uid;

  if (currentUserId) {
    const docRef = doc(db, "Users", currentUserId);
    const res = await updateDoc(docRef, {
      user_settings: userSettings,
    });

    console.log({ res });
  }
};

// export const addVoiceConfigsToFirebase = async function () {
//   const data = allVoiceConfigOptions;
//   const batch = writeBatch(db);

//   const maleDocRef = doc(db, "VoiceConfigs", "male");
//   batch.set(maleDocRef, data.male);

//   const femaleDocRef = doc(db, "VoiceConfigs", "female");
//   batch.set(femaleDocRef, data.female);

//   try {
//     // Commit the batch
//     await batch.commit();
//     console.log("Voice configs added successfully!");
//   } catch (error) {
//     console.error("Error adding voice configs to Firestore:", error);
//   }
// };

export async function addToFavourites(cardData: PhraseAndTermsData) {
  let res: Res = { status: 200, data: { favouriteId: "" }, msg: "", error: "" };

  const currentUserId = auth.currentUser?.uid;
  if (currentUserId) {
    const docRef = doc(collection(db, "Favourites"));

    const senetizedPhraseAndTerms = getSenetizedData(cardData);

    try {
      await setDoc(docRef, {
        ...senetizedPhraseAndTerms,
        user_id: currentUserId,
        created_at: serverTimestamp(),
      });

      res.data.favouriteId = docRef.id;
    } catch (e: any) {
      console.error(e);
      res.status = 0;
      res.error = e?.message as string;
    }
  } else {
    res.status = 0;
    res.error = "User Not Found";
  }

  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2500);
  });

  return res;
}

export async function removeFromFavourites(cardData: PhraseAndTermsData) {
  let res: Res = { status: 200, data: "", msg: "", error: "" };
  const currentUserId = auth.currentUser?.uid;

  if (currentUserId && cardData.favouriteId) {
    try {
      await deleteDoc(doc(db, "Favourites", cardData.favouriteId));
    } catch (e: any) {
      res.status = 0;
      res.error = e?.message as string;
    }
  } else {
    res.status = 0;
    res.error = "User Not Found";
  }

  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2500);
  });

  return res;
}

export const fetchReviews = async function () {
  const currentUserId = auth.currentUser?.uid;
  if (currentUserId) {
    const q = query(
      collection(db, "Reviews"),
      where("user_id", "==", currentUserId),
      orderBy("created_at", "desc")
    );
    const response = await getDocs(q);
    const reviewList = response?.docs?.map((doc) => {
      return {
        review: doc?.data()?.review,
        user_id: doc?.data()?.user_id,
        user_name: doc?.data()?.user_name,
        created_at: doc?.data()?.created_at?.toDate()?.getTime() ?? 0,
        review_id: doc?.id,
      } as ReviewType;
    });

    return reviewList;
  } else {
    return [];
  }
};

export const addReview = async ({ review }: { review: string }) => {
  const currentUser = auth.currentUser;
  if (currentUser?.uid) {
    const docRef = doc(collection(db, "Reviews"));

    const data = {
      review,
      created_at: serverTimestamp(),
      user_id: currentUser?.uid,
      user_name: getDisplayName(currentUser),
    };

    try {
      await setDoc(docRef, data);
    } catch (e: any) {
      console.error(e);
    }
  } else {
    console.log("user not found");
  }
  return true;
};
