import { SvgIconProps } from "@mui/material";
import React from "react";

const BrandLogo: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "36"}
      height={height ?? "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8571 25.7137C19.9579 25.7137 25.7142 19.9574 25.7142 12.8566C25.7142 12.0518 25.6403 11.2643 25.4988 10.5005C24.735 10.3591 23.9475 10.2852 23.1428 10.2852C16.042 10.2852 10.2856 16.0415 10.2856 23.1423C10.2856 23.9471 10.3596 24.7345 10.501 25.4983C11.2648 25.6398 12.0523 25.7137 12.8571 25.7137Z"
        fill="#44BAF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5011 25.4989C4.52551 24.3922 0 19.1532 0 12.8571C0 5.75634 5.75634 0 12.8571 0C19.1532 0 24.3923 4.52551 25.4989 10.5011C24.7351 10.3597 23.9476 10.2857 23.1429 10.2857C16.0421 10.2857 10.2857 16.0421 10.2857 23.1429C10.2857 23.9476 10.3597 24.7351 10.5011 25.4989Z"
        fill="#138FD7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8574 25.7151C19.9582 25.7151 25.7145 19.9588 25.7145 12.858C25.7145 12.0532 25.6406 11.2657 25.4991 10.502C31.4747 11.6086 36.0002 16.8477 36.0002 23.1437C36.0002 30.2445 30.2439 36.0009 23.1431 36.0009C16.8471 36.0009 11.608 31.4753 10.5013 25.4997C11.2651 25.6412 12.0526 25.7151 12.8574 25.7151Z"
        fill="#138FD7"
      />
    </svg>
  );
};

export default BrandLogo;
