import React, { useEffect, useState } from "react";
import { Children } from "../../types/global.types";
import { useGeneralContext } from "../../context/GeneralContextProvider";

type Props = {
  children: React.ReactNode;
  isAbsolute?: boolean;
};
const CustomBackdrop: React.FC<Props> = ({ children, isAbsolute = false }) => {
  const { smallScreen } = useGeneralContext();
  const [isAbsolutePos, setIsAbsolutePos] = useState(false);
  useEffect(() => {
    if (!smallScreen) {
      setIsAbsolutePos(true);
    } else if (isAbsolute) {
      setIsAbsolutePos(true);
    } else {
      setIsAbsolutePos(false);
    }
  }, [isAbsolute, smallScreen]);

  return (
    <div
      className={`w-full h-full bg-[#00000080] ${
        isAbsolutePos ? "absolute" : "fixed  top-0 left-0"
      } z-[1000000] flex justify-center items-center`}
    >
      {children}
    </div>
  );
};

export default CustomBackdrop;
