import { LanguageOptions } from "../types/global.types";

export interface GetGeneralLanguage {
  languageList: LanguageOptions[];
  groupId: number;
}

export enum FlagNames {
  ES = "EU Spanish",
  UK = "UK English",
  US = "US English",
  MX = "Latin American Spanish (Mex)",
}
