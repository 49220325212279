import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "../../../assets/Icons/CloseIcon";

type Props = {
  className?: string;
  onClick: () => void;
};

const CloseButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <IconButton
      className={className}
      tabIndex={-1}
      disableFocusRipple
      onClick={onClick}
      size="small"
      sx={{
        fontSize: { xs: "18px", md: "24px" },
        padding: "0",
        color: "#E5E5E5",
      }}
    >
      <CloseIcon width={30} height={30} />
    </IconButton>
  );
};

export default CloseButton;
