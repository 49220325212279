/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { handleSignInRedirect } from "../utils/AuthFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { setAlert } from "../store/features/general/alertSlice";
import { listenAuthStateChange } from "../service/firebase/firebaseAuth";
import { setUserData, updateUserData } from "../store/features/user/userSlice";
import { UserData } from "../service/firebase/firebase.types";
import { fetchUserVoiceConfigs } from "../service/firebase/firebaseQueries";
import { defaultUserSettings } from "../constants/constants";

const useHandleSignIn = () => {
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  async function handleAuth() {
    const handleAuthLoading = (state: boolean) => setAuthLoading(state);
    const res = await handleSignInRedirect(handleAuthLoading);

    if (res.status !== 404) {
      dispatch(updateUserData({ displayName: res.data?.displayName || "" }));
      dispatch(setAlert({ type: "info", message: res.msg, duration: 800 }));
    }

    setIsAuthenticating(false);
  }

  function handleAuthStateChange(userData: Exclude<UserData, null>) {
    getUserVoiceConfigs();
    dispatch(setUserData(userData));
    if (location.pathname.includes("account-setup"))
      navigate("./", { replace: true });
  }

  async function getUserVoiceConfigs() {
    const { userSettings } = await fetchUserVoiceConfigs();
    dispatch(
      updateUserData({
        user_settings: userSettings || defaultUserSettings,
      })
    );
  }

  useEffect(() => {
    if (authLoading) {
      dispatch(setAlert({ type: "info", message: "Authenticating..." }));
    } else {
      dispatch(setAlert(null));
    }
  }, [authLoading]);

  useEffect(() => {
    if (!isAuthenticating) {
      const unSub = listenAuthStateChange(handleAuthStateChange);
      return unSub;
    }
  }, [isAuthenticating]);

  return { handleAuth };
};

export default useHandleSignIn;
