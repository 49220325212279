import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { ReviewType } from "../../../types/global.types";

const initialState: ReviewType[] = [];

const reviewSlice = createSlice({
  name: "reviews",
  initialState: initialState,
  reducers: {
    setReviews(state: ReviewType[], action: PayloadAction<ReviewType[]>) {
      return action?.payload;
    },
    updateReviews(state: ReviewType[], action: PayloadAction<ReviewType[]>) {
      return action?.payload.concat(state);
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export const { setReviews, updateReviews } = reviewSlice.actions;
export default reviewSlice.reducer;
