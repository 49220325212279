import { Option } from "../pages/Home/components/common/FilterControls/index.types";
import { LanguageLevel } from "../types/global.types";

export const supportedModals = [
  {
    languageCode: "en-GB",
    name: "en-GB-News-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-H",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-I",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-J",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-K",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-L",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-News-M",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Wavenet-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Wavenet-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Wavenet-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Wavenet-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Wavenet-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-News-K",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-News-L",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-News-M",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-News-N",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-H",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-I",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-J",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-A",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-E",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Wavenet-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Wavenet-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Wavenet-D",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Wavenet-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Wavenet-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Wavenet-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Wavenet-C",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-News-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-News-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-News-E",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-News-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-C",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-A",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-E",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-H",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-I",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-J",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-D",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Studio-M",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Studio-O",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Studio-Q",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Studio-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Neural2-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Neural2-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Neural2-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Neural2-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Neural2-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-A",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-E",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-H",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-I",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Neural2-J",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Polyglot-1",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-D",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-E",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Neural2-F",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Polyglot-1",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Neural2-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Neural2-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Neural2-C",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Polyglot-1",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-GB",
    name: "en-GB-Standard-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-A",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-C",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-D",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-E",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-F",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-G",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-H",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-I",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "en-US",
    name: "en-US-Standard-J",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-ES",
    name: "es-ES-Standard-D",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-A",
    ssmlGender: "FEMALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-B",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
  {
    languageCode: "es-US",
    name: "es-US-Standard-C",
    ssmlGender: "MALE",
    naturalSampleRateHertz: 24000,
  },
];

export const languageOptions = [
  { label: "Us English", value: "en-US" },
  { label: "Uk English", value: "en-GB" },
  { label: "Eu Spanish", value: "es-ES" },
  { label: "Us Spanish", value: "es-US" },
];

export const genderOptions = [
  { label: "Masculine", value: "MALE" },
  { label: "Feminine", value: "FEMALE" },
];

export const defaultVoiceConfigs = [
  {
    voice_modal: "en-US-Wavenet-I",
    language: "en-US",
    gender: "MALE",
  },
  {
    voice_modal: "en-GB-News-L",
    language: "en-GB",
    gender: "MALE",
  },
  {
    voice_modal: "es-ES-Wavenet-B",
    language: "es-ES",
    gender: "MALE",
  },
  {
    voice_modal: "es-US-Wavenet-C",
    language: "es-US",
    gender: "MALE",
  },
];

export const baseURL = `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${process.env.REACT_APP_TTS_PUBLIC_API_KEY}`;
export const headers = { "content-type": "application/json; charset=UTF-8" };

export const languageLevelOptions = [
  {
    label: "Beginner level",
    value: LanguageLevel.Beginner,
  },
  {
    label: "Intermediate",
    value: LanguageLevel.Intermediate,
  },
  {
    label: "All",
    value: LanguageLevel.All,
  },
] as Option[];

export const cardLanguageOptions = [
  {
    label: "English",
    value: "ENGLISH",
  },
  {
    label: "Spanish",
    value: "SPANISH",
  },
] as Option[];

export const defaultUserSettings = {
  language_level: LanguageLevel.Beginner,
  gender: "MALE",
  default_card_language: "ENGLISH",
};

// Voice-config options to upload to firebase.....
//  ALERT: remove ***naturalSampleRateHertz***  field from the options as it is not allowed in the API Payload...
// ADD: add specific languageId as the key to the languages as it is accessed by the languageId when processing speech....

// export const allVoiceConfigOptions = {
//   male: {
//     eTeP7jVAZZyiJnTrasZ5: {
//       languageCode: "en-US",
//       name: "en-US-News-N",
//       ssmlGender: "MALE",
//     },
//     i98dLS0wQ8oUjRcETcMr: {
//       languageCode: "en-GB",
//       name: "en-GB-News-M",
//       ssmlGender: "MALE",
//     },
//     NbNFwFzjTriy05FAwlFM: {
//       languageCode: "es-ES",
//       name: "es-ES-Wavenet-B",
//       ssmlGender: "MALE",
//     },
//     nkZAiIdnY04nCNt1KVdI: {
//       languageCode: "es-US",
//       name: "es-US-Wavenet-B",
//       ssmlGender: "MALE",
//     },
//   },
//   female: {
//     eTeP7jVAZZyiJnTrasZ5: {
//       languageCode: "en-US",
//       name: "en-US-Wavenet-G",
//       ssmlGender: "FEMALE",
//     },
//     i98dLS0wQ8oUjRcETcMr: {
//       languageCode: "en-GB",
//       name: "en-GB-Wavenet-A",
//       ssmlGender: "FEMALE",
//     },
//     NbNFwFzjTriy05FAwlFM: {
//       languageCode: "es-ES",
//       name: "es-ES-Wavenet-D",
//       ssmlGender: "FEMALE",
//     },
//     nkZAiIdnY04nCNt1KVdI: {
//       languageCode: "es-US",
//       name: "es-US-Wavenet-A",
//       ssmlGender: "FEMALE",
//     },
//   },
// };
