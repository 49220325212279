import React from "react";
import { Stack, Typography } from "@mui/material";
import CategoryIcon from "../../../../../../../../../assets/Icons/CategoryIcon";

interface CardInfoProps {
  category: string;
  topic: string;
}

const CardInfo: React.FC<CardInfoProps> = ({ category, topic }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        color: "secondary.main",
      }}
      justifyContent={"space-between"}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <CategoryIcon />
        <Typography sx={{ textTransform: "capitalize" }} fontSize={14} fontWeight={600}>
          {category}
        </Typography>
      </Stack>

      <Typography sx={{ textTransform: "capitalize" }} fontSize={14} fontWeight={600}>
        {topic}
      </Typography>
    </Stack>
  );
};

export default CardInfo;
