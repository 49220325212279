import { Typography } from "@mui/material";
import { WrapperStack } from "../../index.styled";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { setSelectedFilters } from "../../../../../../../store/features/filters/selectedFilterSlice";
import { StyledSwitch } from "./index.styled";
import { setLoginModalState } from "../../../../../../../store/features/general/loginModalSlice";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { FilterTypes } from "../../../../../../../types/global.types";
import { setAppliedFilters } from "../../../../../../../store/features/filters/appliedFilterSlice";
import { Favorite } from "@mui/icons-material";

const ViewOnlyFavourites = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isChanged, setIsChanged] = useState(false);
  const selectedFilters: FilterTypes = useSelector(
    (state: RootState) => state.selectedFilters
  );

  const isShowFavourite = useSelector(
    (state: RootState) => state.selectedFilters.showFavourites
  );

  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  function handleToggle() {
    if (currentUserId) {
      dispatch(setSelectedFilters({ showFavourites: !isShowFavourite }));
    } else {
      dispatch(setLoginModalState(true));
    }
  }

  useEffect(() => {
    if (location.pathname === "/flashcards") {
      if (currentUserId) {
        dispatch(setSelectedFilters({ showFavourites: true }));
        setIsChanged(true);
      }
    } else {
      dispatch(setSelectedFilters({ showFavourites: false }));
      setIsChanged(true);
    }
  }, [location.pathname, currentUserId]);

  useEffect(() => {
    // update Favorite only when cards page
    if (isChanged) {
      if (location.pathname === "/flashcards") {
        if (currentUserId) {
          dispatch(setAppliedFilters(selectedFilters));
        }
      } else {
        dispatch(setAppliedFilters(selectedFilters));
      }

      setIsChanged(false);
    }
  }, [selectedFilters, location.pathname, currentUserId, isChanged]);

  return (
    <WrapperStack
      direction={"row"}
      justifyContent={"space-between"}
      className="small-view-favourites"
    >
      <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
        View Only Favourites
      </Typography>
      <StyledSwitch
        name="toggle-favourites"
        onClick={handleToggle}
        checked={isShowFavourite ?? false}
      />
    </WrapperStack>
  );
};

export default ViewOnlyFavourites;
