import { Typography, Slide, SlideProps, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setAlert } from "../../store/features/general/alertSlice";

type TransitionProps = Omit<SlideProps, "direction">;

const vertical = "top";
const horizontal = "center";

const AlertBar = () => {
  const dispatch = useDispatch();

  const alertState = useSelector((state: RootState) => state.alertState);

  // Transition Component  for AlertBar
  function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
  }

  return !!alertState ? (
    <Snackbar
      open={!!alertState}
      autoHideDuration={alertState?.duration ?? 0}
      key={vertical + horizontal}
      onClose={() => dispatch(setAlert(null))}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical, horizontal }}
      sx={{ borderRadius: "10px", overflow: "hidden" }}
    >
      <Typography
        sx={{
          color: "secondary.light",
          bgcolor: "secondary.main",
          fontSize: "18px",
          fontWeight: 600,
          width: "100%",
          padding: "8px 30px",
        }}
      >
        {alertState?.message ?? "Something went wrong!"}
      </Typography>
    </Snackbar>
  ) : null;
};

export default AlertBar;
