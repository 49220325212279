import { Typography, Stack, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import SwapIcon from "../../../../../../../assets/Icons/SwapIcon";
import { WrapperStack } from "../../index.styled";
import LanguageSelect from "./components/LanguageSelect";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { LanguageOptions } from "../../../../../../../types/global.types";
import {
  setSelectedFilters,
  swapLanguages,
} from "../../../../../../../store/features/filters/selectedFilterSlice";
import { getDefaultLanguages } from "../../../../../../../utils/GeneralUtils";

enum Language {
  "PRIMARY",
  "SECONDARY",
}

const LanguageFilters = () => {
  const dispatch = useDispatch();
  const primaryLanguage = useSelector(
    (state: RootState) => state.selectedFilters.languagePrimary
  );
  const secondaryLanguage = useSelector(
    (state: RootState) => state.selectedFilters.languageSecondary
  );
  const isReverseFilter = useSelector(
    (state: RootState) => state.selectedFilters.isReverseFilter
  );
  const favouriteToggle = useSelector(
    (state: RootState) => state.selectedFilters.showFavourites
  );

  const languageList = useSelector((state: RootState) => state.languageList);

  // state for options which controlled by other selection
  const [primaryLanguages, setPrimaryLangugages] =
    useState<LanguageOptions[]>(languageList);
  const [secondaryLanguages, setSecondaryLanguages] =
    useState<LanguageOptions[]>(languageList);

  // filter functions
  function filterPrimaryLanguageOptions(filterGroupId: number) {
    setPrimaryLangugages(
      languageList?.filter(
        (language: LanguageOptions) => language.group_id !== filterGroupId
      )
    );
  }

  function filterSecondaryLanguageOptions(filterGroupId: number) {
    setSecondaryLanguages(
      languageList?.filter(
        (language: LanguageOptions) => language.group_id !== filterGroupId
      )
    );
  }

  // switch languages :: UI refrence
  function handleSwapLanguages() {
    dispatch(swapLanguages());
  }

  // handler function on language selection
  function setSelectedLanguage(
    language: Language.PRIMARY | Language.SECONDARY,
    selected: LanguageOptions
  ) {
    if (language === Language.PRIMARY) {
      dispatch(setSelectedFilters({ languagePrimary: selected }));
    } else {
      dispatch(setSelectedFilters({ languageSecondary: selected }));
    }
  }

  useEffect(() => {
    filterPrimaryLanguageOptions(secondaryLanguage?.group_id ?? 2);
    filterSecondaryLanguageOptions(primaryLanguage?.group_id ?? 1);
  }, [primaryLanguage, secondaryLanguage, languageList]);

  // FIlter Languages on Load or Change
  useEffect(() => {
    const { defaultPrimaryLanguage, defaultSecondaryLanguage } =
      getDefaultLanguages(languageList);

    if (defaultPrimaryLanguage && defaultSecondaryLanguage) {
      dispatch(
        setSelectedFilters({
          languagePrimary: defaultPrimaryLanguage ?? null,
          languageSecondary: defaultSecondaryLanguage ?? null,
          category: null,
          topic: null,
        })
      );
    }
  }, [languageList, favouriteToggle]);

  return (
    <WrapperStack className="small-language-box">
      <Typography variant="h4">Language</Typography>

      <Stack
        direction={isReverseFilter ? "column-reverse" : "column"}
        sx={{ gap: 1.25 }}
      >
        <LanguageSelect
          isLoading={!primaryLanguages.length}
          value={primaryLanguage}
          options={primaryLanguages}
          placeHolder="Select Primary langauge"
          filterOptions={filterSecondaryLanguageOptions}
          setSelected={(selected) => {
            setSelectedLanguage(Language.PRIMARY, selected);
          }}
        />

        <Stack justifyContent={"center"} alignItems={"center"}>
          <IconButton
            onClick={handleSwapLanguages}
            disableRipple
            className={`${
              isReverseFilter ? "rotate-180" : "rotate-0"
            } transition-all duration-500`}
            sx={{
              padding: "0",
              color: "#999999",
              "&:hover": { color: "secondary.main" },
            }}
          >
            <SwapIcon width={18} height={18} />
          </IconButton>
        </Stack>

        <LanguageSelect
          isLoading={!secondaryLanguages.length}
          value={secondaryLanguage}
          options={secondaryLanguages}
          placeHolder="Select Secondary langauge"
          filterOptions={filterPrimaryLanguageOptions}
          setSelected={(selected) =>
            setSelectedLanguage(Language.SECONDARY, selected)
          }
        />
      </Stack>
    </WrapperStack>
  );
};

export default LanguageFilters;
