import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DeskTopNav from "../../components/Home/DesktopNav";
import AddReviewButton from "../../components/common/AddReviewButton";
import SwapIcon from "../../assets/Icons/SwapIcon";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FlashCardItem from "./components/FlashCardItem";
import { motion, useAnimation } from "framer-motion";
import SearchBar from "../../components/common/SearchBar";
import FilterIcon from "../../assets/Icons/FilterIcon";
import DotIcon from "../../assets/Icons/DotIcon";
import { useAuthContext } from "../../context/AuthContext";
import useLoadInitialData from "../../Hooks/useLoadInitialData";
import {
  setPhraseAndTerms,
  updatePhraseAndTerms,
} from "../../store/features/data/phraseAndTermSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FilterTypes } from "../../types/global.types";
import { PhraseAndTermsData } from "../../service/firebase/firebase.types";
import { fetchData } from "../../service/algolia/algoliaQueries";
import FavouriteButton from "../Home/components/MainSection/components/PhraseAndTermList/PhraseAndTermCard/components/FavouriteButton";

enum TYPE {
  "PRIMARY",
  "SECONDARY",
}

type BoxDataType = {
  generalLang: string;
  language: string;
  content: string;
  languageId: string;
};

const Flashcards = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isCardChanging, setIsCardChanging] = useState(false);
  const controls = useAnimation();
  const [card, setCard] = useState<PhraseAndTermsData | null>(null);
  const [boxData, setBoxData] = useState<BoxDataType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const [isDatafetched, setIsDatafetched] = useState(false);
  const fetchQueue = useRef<FilterTypes | null>(null);
  const fetchQueueTimeout = useRef<NodeJS.Timeout>();
  const [filterSelected, setFilterSelected] = useState<boolean>(false);
  const {
    filterDrawerState,
    setFilterDrawerState,
    setCurrentCardInfo,
    currentCardInfo,
  } = useAuthContext();
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );

  const languageLevel = useSelector(
    (state: RootState) => state.userData?.user_settings?.language_level
  );

  const default_card_language = useSelector(
    (state: RootState) => state.userData?.user_settings?.default_card_language
  );

  const dispatch = useDispatch();

  const user_settings = useSelector(
    (state: RootState) => state.userData?.user_settings
  );

  const [page, setPage] = useState(0);

  const appliedFilters = useSelector(
    (state: RootState) => state.appliedFilters
  );

  const phraseAndTerms = useSelector(
    (state: RootState) => state.phraseAndTerms
  );
  const { languagePrimary, languageSecondary } = useSelector(
    (state: RootState) => state.appliedFilters
  );

  useEffect(() => {
    setPage(0);
  }, []);

  useLoadInitialData();

  const nextAnimation = () => {
    setIsCardChanging(true);

    setIsAnimating(true);
    controls
      .start({
        x: "-100%",
        zIndex: -1,
        opacity: 0,
        transition: { duration: 0.6 },
      })
      .then(() => {
        controls.set({ x: "0%", zIndex: 0, opacity: 1 });
        setIsAnimating(false);
        setIsCardChanging(false);
      });
  };

  const previousAnimation = () => {
    setIsCardChanging(true);

    setIsAnimating(true);

    controls
      .start({
        x: "100%",
        zIndex: 20,
        opacity: 0,
        transition: { duration: 0.6 },
      })
      .then(() => {
        controls.set({ x: "0%", zIndex: 0, opacity: 1 });
        setIsAnimating(false);
        setIsCardChanging(false);
      });
  };

  const speak = (audioFile: string) => {
    const audio = new Audio();

    audio.addEventListener("error", (e) => {
      console.error(`Error loading audio file: ${audioFile}`);
    });

    audio.src = audioFile;
    audio.play();
  };

  function toggleDrawer() {
    setFilterDrawerState(!filterDrawerState);
    // if (DrawerRef?.current) {
    //   DrawerRef.current?.toggleDrawer();
    // }
  }

  const getLanguage = (type: TYPE) => {
    if (type === TYPE.PRIMARY) {
      return languagePrimary?.value ?? "language";
    } else {
      return languageSecondary?.value ?? "language";
    }
  };

  const getGeneralLang = (type: TYPE) => {
    if (type === TYPE.PRIMARY) {
      if (
        card?.primary_data.primary_language_id !== languagePrimary?.language_id
      ) {
        return "General";
      }

      return "";
    } else {
      if (
        card?.secondary_data.secondary_language_id !==
        languageSecondary?.language_id
      ) {
        return "General";
      }

      return "";
    }
  };

  const shuffleArray = (array: any[]) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  const updateQueue = () => {
    if (fetchQueueTimeout.current) {
      clearTimeout(fetchQueueTimeout.current);
    }
    fetchQueue.current = appliedFilters;

    fetchQueueTimeout.current = setTimeout(() => {
      const currentFilter = JSON.stringify(fetchQueue.current);

      if (appliedFilters.languagePrimary) {
        getData(true, currentFilter);
      }
    }, 600);
  };

  async function getData(isRefresh?: boolean, currentFilter?: string) {
    let fetchedData: PhraseAndTermsData[] = [];

    if (appliedFilters.showFavourites) {
      fetchedData = await fetchData({
        appliedFilters,
        page: isRefresh ? 0 : page,
        currentUserId,
        languageLevel,
      });
    } else {
      fetchedData = await fetchData({
        appliedFilters,
        page: isRefresh ? 0 : page,
        currentUserId,
        languageLevel,
      });
    }

    const newFetchedData: PhraseAndTermsData[] = shuffleArray(fetchedData);
    setIsDatafetched(true);

    setIsLoading(false);
    setHasMoreData(false);
    if (isRefresh) {
      if (currentFilter === JSON.stringify(fetchQueue.current)) {
        fetchQueue.current = null;

        dispatch(setPhraseAndTerms(newFetchedData));
        if (newFetchedData.length === 0) {
          setPage(-1);
        } else {
          setPage(1);
        }
        setIsLoading(false);
      }
    } else {
      if (fetchQueue.current === null) {
        dispatch(updatePhraseAndTerms(newFetchedData));
        setPage((prev) => prev + 1);
        setIsLoading(false);
      }
    }
    if (newFetchedData.length < 24 && fetchQueue.current === null) {
      setHasMoreData(false);
    }
  }

  function handleRemoveFavourite() {
    setPage(-1);
  }

  function getBoxData(type: TYPE) {
    const secondary_value2: string = card?.secondary_data.secondary_value2
      ? card?.secondary_data?.secondary_value2
      : "";
    if (type === TYPE.PRIMARY) {
      return {
        generalLang: getGeneralLang(TYPE.PRIMARY),
        language: getLanguage(TYPE.PRIMARY),
        content: card?.primary_data?.primary_value ?? "",
        languageId: currentCardInfo?.isFliped
          ? languageSecondary?.language_id ?? ""
          : languagePrimary?.language_id ?? "",
      };
    } else {
      return {
        generalLang: getGeneralLang(TYPE.SECONDARY),
        language: getLanguage(TYPE.SECONDARY),
        content:
          card?.secondary_data?.secondary_value + "/v2/" + secondary_value2 ??
          "",

        languageId: currentCardInfo?.isFliped
          ? languagePrimary?.language_id ?? ""
          : languageSecondary?.language_id ?? "",
      };
    }
  }

  useEffect(() => {
    if (phraseAndTerms.length === 0) {
      handleRemoveFavourite();
    }
    if (currentCardInfo?.index === phraseAndTerms.length - 3) {
      setHasMoreData(true);
    }

    if (currentCardInfo) setCard(phraseAndTerms[currentCardInfo?.index]);
  }, [currentCardInfo, phraseAndTerms]);

  useEffect(() => {
    if (currentCardInfo?.isFliped) {
      setBoxData(getBoxData(TYPE.SECONDARY));
    } else {
      setBoxData(getBoxData(TYPE.PRIMARY));
    }
  }, [card, currentCardInfo?.isFliped]);

  useEffect(() => {
    if (currentCardInfo === null)
      if (default_card_language === "ENGLISH")
        setCurrentCardInfo({ index: 0, isFliped: false });
      else setCurrentCardInfo({ index: 0, isFliped: true });
    else if (currentCardInfo && hasMoreData) {
      if (currentCardInfo?.index === 0) {
        setIsLoading(true);
        setHasMoreData(true);
        updateQueue();
      } else {
        getData();
      }
    }
  }, [currentCardInfo?.index]);

  useLayoutEffect(() => {
    setPage(0);
    setHasMoreData(true);
    setIsLoading(true);
    appliedFilters.languagePrimary && updateQueue();
  }, [appliedFilters, languageLevel]);

  useEffect(() => {
    if (
      appliedFilters?.category?.value ||
      appliedFilters?.topic?.value ||
      appliedFilters?.showFavourites
    ) {
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (user_settings?.default_card_language === "ENGLISH") {
      if (currentCardInfo)
        setCurrentCardInfo((prev) => {
          if (prev === null) {
            return {
              index: 0,
              isFliped: false,
            };
          }
          return {
            ...prev,
            isFliped: false,
          };
        });
    } else {
      if (currentCardInfo) {
        setCurrentCardInfo((prev) => {
          if (prev === null) {
            return {
              index: 0,
              isFliped: true,
            };
          }
          return {
            ...prev,
            isFliped: true,
          };
        });
      }
    }
  }, [user_settings?.default_card_language]);

  return (
    <div className="h-full overflow-auto">
      <Stack direction={"row"} height={"100%"} className="">
        <DeskTopNav />

        <Divider
          sx={{ display: { md: "flex", xs: "none" } }}
          orientation="vertical"
          flexItem
        />
        <Box className=" flex-grow  flex flex-col ">
          <Box className="relative flex-grow flex flex-col overflow-hidden sm:justify-center items-center">
            <img
              src="WorldMap.svg"
              alt=""
              className="w-full h-full opacity-[0.13] object-cover "
            />
            <div className="p-5 z-50 absolute   flex flex-col w-full h-full">
              <Stack
                direction="row"
                gap={1.5}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <IconButton
                  onClick={() => {
                    toggleDrawer();
                  }}
                  sx={{ padding: 0, display: { md: "none" } }}
                >
                  <FilterIcon />

                  {filterSelected && (
                    <DotIcon className="absolute top-0 right-0 translate-x-[25%] -translate-y-[25%]" />
                  )}
                </IconButton>
              </Stack>
              <div className=" w-full h-full flex justify-center  sm:items-center sm:pt-0  pt-10 relative">
                {phraseAndTerms.length === 0 && isDatafetched ? (
                  <div className="z-50 absolute">no data found</div>
                ) : isLoading ? (
                  <CircularProgress
                    size="40px"
                    sx={{ margin: "auto", color: "black" }}
                    className="absolute z-50"
                  />
                ) : phraseAndTerms.length !== 0 &&
                  boxData?.content &&
                  !boxData.content.includes("undefined") ? (
                  <div className="flex-grow flex flex-col sm:justify-center  items-center ">
                    <div className="w-full flex flex-col sm:gap-20 gap-10 items-center">
                      <div className="w-full flex gap-28 items-center justify-center">
                        <Button
                          className="lg:flex hidden  disabled:bg-[#E5E5E5] disabled:border-none disabled:text-[#626262c4]   h-fit bg-white text-text-secondary px-5 rounded-md border border-text-secondary border-solid font-semibold tracking-wide "
                          disabled={currentCardInfo?.index === 0}
                          startIcon={
                            <KeyboardArrowLeftIcon width={14} height={14} />
                          }
                          onClick={() => {
                            speak("./voices/flip3.mp3");
                            if (currentCardInfo)
                              setCurrentCardInfo({
                                ...currentCardInfo,
                                index: currentCardInfo?.index - 1,
                              });
                            if (
                              user_settings?.default_card_language === "ENGLISH"
                            ) {
                              if (currentCardInfo?.isFliped) {
                                previousAnimation();
                              } else {
                                nextAnimation();
                              }
                            } else {
                              if (currentCardInfo?.isFliped) {
                                nextAnimation();
                              } else {
                                previousAnimation();
                              }
                            }
                          }}
                        >
                          Previous
                        </Button>
                        {boxData && (
                          <div className="relative max-w-[450px] w-full h-full bg-transparent flex flex-col justify-center items-center ">
                            {/* {card && (
                            <div className="absolute z-[200] top-4 right-4">
                              <FavouriteButton cardData={card} />
                            </div>
                          )} */}

                            <div className="w-full rotate-[-6deg] z-10 flex justify-center">
                              <FlashCardItem card={boxData} />
                            </div>
                            <div className="w-full absolute sm:rotate-[6deg] rotate-[3deg] sm:right-4 right-2 z-0 flex justify-center">
                              <FlashCardItem card={boxData} />
                            </div>
                            {isCardChanging && (
                              <div className="w-full absolute z-20 flex justify-center">
                                <FlashCardItem card={boxData} />
                              </div>
                            )}

                            <motion.div
                              className="absolute z-20 w-full"
                              initial={false}
                              animate={{
                                rotateY:
                                  user_settings?.default_card_language ===
                                  "ENGLISH"
                                    ? currentCardInfo?.isFliped
                                      ? 180
                                      : 0
                                    : currentCardInfo?.isFliped
                                    ? 0
                                    : 180,
                              }}
                              transition={{ duration: 0.6 }}
                              onAnimationComplete={() => setIsAnimating(false)}
                            >
                              <motion.div animate={controls} className="w-full">
                                <motion.div
                                  className="w-full flex justify-center"
                                  animate={{
                                    rotateY:
                                      user_settings?.default_card_language ===
                                      "ENGLISH"
                                        ? currentCardInfo?.isFliped
                                          ? -180
                                          : 0
                                        : currentCardInfo?.isFliped
                                        ? 0
                                        : -180,
                                  }}
                                  transition={{ duration: 0.6 }}
                                >
                                  <FlashCardItem
                                    card={boxData}
                                    cardData={card}
                                  />
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          </div>
                        )}

                        <Button
                          className="lg:flex hidden disabled:bg-[#E5E5E5] disabled:border-none disabled:text-[#626262c4] h-fit px-5 rounded-md border text-white bg-text-secondary border-text-secondary border-solid font-semibold tracking-wide "
                          disabled={
                            currentCardInfo?.index === phraseAndTerms.length - 1
                          }
                          endIcon={
                            <KeyboardArrowRightIcon width={14} height={14} />
                          }
                          onClick={() => {
                            if (currentCardInfo)
                              setCurrentCardInfo({
                                ...currentCardInfo,
                                index: currentCardInfo?.index + 1,
                              });
                            speak("./voices/flip4.mp3");
                            if (
                              user_settings?.default_card_language === "ENGLISH"
                            ) {
                              if (currentCardInfo?.isFliped) {
                                nextAnimation();
                              } else {
                                previousAnimation();
                              }
                            } else {
                              if (currentCardInfo?.isFliped) {
                                previousAnimation();
                              } else {
                                nextAnimation();
                              }
                            }
                          }}
                        >
                          Next
                        </Button>
                      </div>
                      <div className=" flex w-full max-w-[450px]  lg:justify-center justify-between">
                        <IconButton
                          className=" lg:hidden  disabled:bg-[#E5E5E5] disabled:border-none disabled:text-[#626262c4] flex h-fit bg-white text-text-secondary rounded-md border border-text-secondary border-solid font-semibold tracking-wide "
                          disabled={currentCardInfo?.index === 0}
                          onClick={() => {
                            if (currentCardInfo)
                              setCurrentCardInfo({
                                ...currentCardInfo,
                                index: currentCardInfo?.index - 1,
                              });
                            speak("./voices/flip3.mp3");

                            if (
                              user_settings?.default_card_language === "ENGLISH"
                            ) {
                              if (currentCardInfo?.isFliped) {
                                previousAnimation();
                              } else {
                                nextAnimation();
                              }
                            } else {
                              if (currentCardInfo?.isFliped) {
                                nextAnimation();
                              } else {
                                previousAnimation();
                              }
                            }
                          }}
                        >
                          <KeyboardArrowLeftIcon width={14} height={14} />
                        </IconButton>{" "}
                        <Button
                          className="w-fit bg-white text-text-secondary px-5 rounded-md border border-[#E7F4FB] border-solid font-semibold tracking-wide "
                          endIcon={<SwapIcon width={14} height={14} />}
                          onClick={() => {
                            if (!isAnimating) {
                              speak("./voices/flip1.mp3");
                              if (currentCardInfo)
                                setCurrentCardInfo({
                                  ...currentCardInfo,
                                  isFliped: !currentCardInfo?.isFliped,
                                });
                              setIsAnimating(true);
                            }
                          }}
                        >
                          Flip languages
                        </Button>
                        <IconButton
                          className=" lg:hidden flex h-fit  disabled:bg-[#E5E5E5] disabled:border-none disabled:text-[#626262c4]  rounded-md border text-white bg-text-secondary border-text-secondary border-solid font-semibold tracking-wide "
                          disabled={
                            currentCardInfo?.index === phraseAndTerms.length - 1
                          }
                          onClick={() => {
                            if (currentCardInfo)
                              setCurrentCardInfo({
                                ...currentCardInfo,
                                index: currentCardInfo?.index + 1,
                              });
                            speak("./voices/flip4.mp3");
                            if (
                              user_settings?.default_card_language === "ENGLISH"
                            ) {
                              if (currentCardInfo?.isFliped) {
                                nextAnimation();
                              } else {
                                previousAnimation();
                              }
                            } else {
                              if (currentCardInfo?.isFliped) {
                                previousAnimation();
                              } else {
                                nextAnimation();
                              }
                            }
                          }}
                        >
                          <KeyboardArrowRightIcon width={14} height={14} />{" "}
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="z-50 absolute">no data found</div>
                )}{" "}
              </div>
            </div>
          </Box>
        </Box>
      </Stack>

      <AddReviewButton />

      {/* Absolute compopnents ie. modals, dialog, alert */}
      {/* <LoginModal />
    <ReviewModal />
    <AlertBar /> */}
    </div>
  );
};

export default Flashcards;
