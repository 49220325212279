import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
// import persistReducer from "redux-persist/es/persistReducer";
// import persistStore from "redux-persist/es/persistStore";
// import { FLUSH, PAUSE, PERSIST, PURGE, PersistConfig, REGISTER, REHYDRATE } from "redux-persist";
import languageSlice from "./features/data/languageSlice";
import categorySlice from "./features/data/categorySlice";
import topicSlice from "./features/data/topicSlice";
import appliedFilterSlice from "./features/filters/appliedFilterSlice";
import userSlice from "./features/user/userSlice";
import phraseAndTermSlice from "./features/data/phraseAndTermSlice";
import selectedFilterSlice from "./features/filters/selectedFilterSlice";
import alertSlice from "./features/general/alertSlice";
import loginModalSlice from "./features/general/loginModalSlice";
import reviewModalSlice from "./features/general/reviewModalSlice";
import reviewSlice from "./features/data/reviewSlice";
import voiceConfigSlice from "./features/data/voiceConfigSlice";
import FavouritesSlice from "./features/data/FavouritesSlice";

// import storage from 'redux-persist/lib/storage/session';

// const currentVersion = 1.2;

// const persistConfig: PersistConfig<RootState> = {
//   key: "root",
//   storage,
//   version: currentVersion,
//   blacklist: ["filters"],
// };

export const clearAll = createAction("CLEAR_ALL");

const rootReducer = combineReducers({
  // data
  categoryList: categorySlice,
  languageList: languageSlice,
  topicList: topicSlice,
  phraseAndTerms: phraseAndTermSlice,
  reviewList: reviewSlice,
  FavouritesSlice: FavouritesSlice,

  // filters
  appliedFilters: appliedFilterSlice,
  selectedFilters: selectedFilterSlice,

  // general
  alertState: alertSlice,
  loginModalState: loginModalSlice,
  reviewModalState: reviewModalSlice,

  // user
  userData: userSlice,
  voiceConfig: voiceConfigSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
});

// export const persistor = persistStore(store);

export default store;
