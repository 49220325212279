import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { LanguageOptions } from "../../../types/global.types";

const initialState: LanguageOptions[] = [];

const languageSlice = createSlice({
  name: "languages",
  initialState: initialState,
  reducers: {
    setLanguages(
      state: LanguageOptions[],
      action: { payload: LanguageOptions[] }
    ) {
      return action?.payload ?? initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export const { setLanguages } = languageSlice.actions;
export default languageSlice.reducer;
