import {
  Divider,
  OutlinedInput,
  Box,
  Typography,
  OutlinedInputProps,
  Button,
} from "@mui/material";
import { styled } from "@mui/material";
import { theme } from "../../theme";

interface StyledInputProps extends OutlinedInputProps {
  outlineColor?: string;
}

export const StyledInput = styled(OutlinedInput)(
  ({ outlineColor }: StyledInputProps) => ({
    width: "100%",
    outline: "none",
    fontWeight: 400,
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.08)",

    "& #styled-input": {
      padding: "12px 16px",
      borderRadius: "10px",
      border: "1px solid #d8d8d8",
      // border: "1px solid white",
    },

    "& #styled-input:hover": {
      borderColor: "#7c7c7c",
    },

    "& #styled-input:focus": {
      // borderColor: outlineColor || theme.palette.success.main,
      borderColor: theme.palette.secondary.main,
      zIndex: 20,
    },
    "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "transparent",
      },
  })
);

export const StyledDivider = styled(Divider)({
  margin: "1.5rem 0",
  "&.MuiDivider-root": {
    "&::after, &::before": {
      borderTop: "1px solid gray",
      borderRadius: "8px",
    },
  },
});

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "94%",
  maxWidth: "530px",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "14px",
  transform: "translate(-50%,-50%)",
  backgroundColor: "white",
  boxShadow: "24px",

  [theme.breakpoints.down("md")]: {
    padding: "22px 22px 26px",
  },

  [theme.breakpoints.up("md")]: {
    padding: "32px 32px 36px",
  },
}));

export const ReviewModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "94%",
  maxWidth: "650px",
  height: "550px",
  maxHeight: "80%",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "14px",
  transform: "translate(-50%,-50%)",
  backgroundColor: "white",
  boxShadow: "24px",
  padding: "0",
}));

export const ModalHeading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
    lineHeight: "30px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
    lineHeight: "38px",
  },
  fontWeight: 750,
}));

export const ModalText = styled(Typography)({
  color: "#636262",
  fontSize: "16px",
  lineHeight: "21px",
  fontWeight: 500,
});

export const ContinueButton = styled(Button)({
  margin: "2.25rem 0 0 0",
  borderRadius: "8px",
  padding: "10px 24px",
  width: "100%",
  color: "white",
  background: theme.palette.secondary.main,
  fontSize: "16px",
  fontWeight: 600,
  "&:focus": {
    backgroundColor: theme.palette.secondary.dark,
  },
});
