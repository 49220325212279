import React, { useEffect, useRef, useState } from "react";
import { Children } from "../../../../../types/global.types";

type Entries = {
  isIntersecting: boolean;
};

interface Props extends Children {
  loadingComponent?: React.ReactNode;
  noDataComponent?: React.ReactNode;
  loadMore: () => Promise<void>;
  hasMore: boolean;
  isLoading: boolean;
  page: number;
}

const InfiniteScroll = ({ children, hasMore, loadingComponent, noDataComponent, loadMore, isLoading, page }: Props) => {
  const observer = new IntersectionObserver(handleIntersection);

  const bottomElementRef = useRef<HTMLDivElement>(null);

  const callBackRef = useRef<boolean>(true);

  function startObservation() {
    stopObservation();
    bottomElementRef.current && observer.observe(bottomElementRef.current);
  }

  function stopObservation() {
    bottomElementRef.current && observer.unobserve(bottomElementRef.current);
  }

  // handle intersection for pagination
  function handleIntersection(entries: Entries[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        if (!callBackRef.current && page !== 0) {
          callBackRef.current = true;
          loadMore();
        }
      }
    });
  }

  // start observation and clear observation
  useEffect(() => {
    callBackRef.current = false;
    startObservation();
    return stopObservation;
  }, [hasMore, page, isLoading]);

  return (
    <>
      {!isLoading && children}
      {!isLoading && !hasMore && page === -1 && !isLoading && noDataComponent}
      <div className=" col-span-full text-center">
        <div ref={bottomElementRef} />
        <div className=" col-span-full text-center">{(isLoading || hasMore) && loadingComponent}</div>
      </div>
    </>
  );
};

export default InfiniteScroll;
