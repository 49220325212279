import { sendSignInEmailLink } from "../../../service/firebase/firebaseAuth";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../store/features/general/alertSlice";

const useSendEmailLink = () => {
  const dispatch = useDispatch();

  async function sendEmailLinkTo(email: string, handleSuccess?: () => void) {
    if (email) {
      const res = await sendSignInEmailLink(email);
      if (res.status === 200) {
        handleSuccess && handleSuccess();
      }
      dispatch(setAlert({ type: "info", message: res.msg, duration: 800 }));
    }
  }

  return { sendEmailLinkTo };
};

export default useSendEmailLink;
