import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { getFlagByName } from "../../../../../../../../../utils/GeneralUtils";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import useProcessSpeech from "../../../../../../../../../Hooks/useProcessSpeech";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../store/store";
import { Change, diffWords } from "diff";

interface LanguageBoxProps {
  speechCallbackRef: React.MutableRefObject<(() => void) | undefined>;
  data: {
    generalLang: string;
    language: string;
    content?: string;
    languageId?: string;
    voice_language?: string;
  };
}

const LanguageBox: React.FC<LanguageBoxProps> = ({
  data,
  speechCallbackRef,
}) => {
  const Flag = getFlagByName(data?.language ?? "");
  const [isVoiceStart, setisVoiceStart] = useState(false);
  const { processSpeech, pauseAudio } = useProcessSpeech({
    onSpeechEnd: () => setisVoiceStart(false),
  });
  const [different, setDiffrentWords] = useState<Change[]>([]);
  const userSettings = useSelector(
    (state: RootState) => state?.userData?.user_settings
  );
  const startSpeech = () => {
    speechCallbackRef?.current?.();
    speechCallbackRef.current = () => setisVoiceStart(false);
    processSpeech(finalData ?? "", data?.languageId ?? "");
    setisVoiceStart(!isVoiceStart);
  };

  const [finalData, setFinalData] = useState("");
  useEffect(() => {
    const datax = data?.content?.split("/v2/");

    if (
      data.content &&
      userSettings?.gender &&
      datax &&
      datax?.length >= 2 &&
      datax[1]?.length > 0
    ) {
      const diff2 = diffWords(datax[0], datax[1]);
      if (diff2) setDiffrentWords(diff2);

      setFinalData(
        userSettings?.gender === "MALE"
          ? datax[0]
          : datax[1]
          ? datax[1]
          : datax[0]
      );
    } else {
      if (datax) {
        setFinalData(datax[0]);
      }
    }
  }, [data, userSettings]);

  return (
    <Box sx={{ maxWidth: "85%" }}>
      <Typography
        className="line-clamp-2 whitespace-break-spaces break-all flex items-center gap-1.5 mb-0.5 "
        sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.5)" }}
      >
        {Flag && (
          <Flag
            width={20}
            height={20}
            className="overflow-hidden rounded-full"
          />
        )}
        {`${data?.language ?? ""}`}
        {isVoiceStart ? (
          <IconButton
            onClick={() => {
              pauseAudio();
              setisVoiceStart(!isVoiceStart);
            }}
          >
            <VolumeUpIcon className=" text-lg text-text-secondary" />
          </IconButton>
        ) : (
          <IconButton onClick={startSpeech}>
            <VolumeOffIcon className=" text-lg " />
          </IconButton>
        )}
        {/* {isLoading ? (
          <IconButton >
            <CircularProgress color="secondary" size={17} />
          </IconButton>
        ) : (
          <></>
        )} */}
      </Typography>
      <Typography
        className="line-clamp-2 whitespace-break-spaces break-words h-[48px]"
        sx={{
          fontWeight: "550",
          fontSize: "16px",
          height: "42px",
        }}
      >
        {different.length > 0 ? (
          <>
            {different.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.added
                    ? "#D32F2F"
                    : part.removed
                    ? "#138FD7"
                    : "#1c1c1c",
                }}
              >
                {!part.removed && !part.added && part.value}
                {userSettings?.gender === "MALE" && part.removed && part.value}
                {userSettings?.gender === "FEMALE" && part.added && part.value}
              </span>
            ))}
          </>
        ) : (
          <>{data?.content?.split("/v2/")}</>
        )}
        {/* <div dangerouslySetInnerHTML={{ __html: secNewValue }}></div> */}
      </Typography>
    </Box>
  );
};

export default LanguageBox;
