import { PhraseAndTermsData } from "../../../service/firebase/firebase.types";
import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";

const initialState: PhraseAndTermsData[] = [];

const FavouritesSlice = createSlice({
  name: "phrases and terms",
  initialState: initialState,
  reducers: {
    setFavouritePhraseAndTerms(
      state: PhraseAndTermsData[],
      action: { payload: PhraseAndTermsData[] }
    ) {
      return [...action?.payload];
    },
    removeFavouritePhraseAndTerms(
      state: PhraseAndTermsData[],
      action: { payload: string }
    ) {},
  },
});

export const { setFavouritePhraseAndTerms, removeFavouritePhraseAndTerms } =
  FavouritesSlice.actions;
export default FavouritesSlice.reducer;
