import React from "react";
import { theme as muiTheme } from "../../../../../../../../../theme";

import Select, {
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import DropDownIcon from "../../../../../../../../../assets/Icons/DropDownIcon";
import { LanguageOptions } from "../../../../../../../../../types/global.types";
import { colourStyles } from "../../../common/react_select.styleConfig";
import { useGeneralContext } from "../../../../../../../../../context/GeneralContextProvider";
import { Option } from "../../../../index.types";
import { getFlagByName } from "../../../../../../../../../utils/GeneralUtils";

const CustomDropdownIndicator: React.FC<any> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropDownIcon width={16} height={16} className=" cursor-pointer" />
    </components.DropdownIndicator>
  );
};

const CustomOption: React.FC<OptionProps<Option>> = ({ innerProps, label }) => {
  const Flag = getFlagByName(label ?? "");
  return (
    <div
      {...innerProps}
      className="flex flex-row gap-[8px] items-center overflow-hidden px-2.5 py-1 cursor-pointer"
    >
      {Flag ? (
        <Flag
          width={15}
          height={15}
          className=" overflow-hidden rounded-full"
        />
      ) : null}
      <span className=" line-clamp-1">{label}</span>
    </div>
  );
};

const CustomSingleValue: React.FC<SingleValueProps<Option>> = ({
  children,
  data,
}) => {
  const Flag = getFlagByName(data?.label ?? "");
  return (
    <span className="flex flex-row gap-[8px] items-center cursor-pointer">
      {Flag ? (
        <Flag width={15} height={15} className="overflow-hidden rounded-full" />
      ) : null}
      <span className=" line-clamp-1">{data?.label}</span>
    </span>
  );
};

interface Props {
  value: LanguageOptions | null;
  options: LanguageOptions[];
  placeHolder: string;
  // defaultSelected?: LanguageOptions;
  filterOptions: (group_id: number) => void;
  setSelected: (selected: LanguageOptions) => void;
  menuPlacement?: "top" | "bottom" | "auto";
  isLoading: boolean;
}

const LanguageSelect: React.FC<Props> = ({
  value,
  options,
  placeHolder,
  setSelected,
  // defaultSelected,
  menuPlacement,
  isLoading,
}) => {
  const { smallScreen } = useGeneralContext();
  return (
    <Select
      isSearchable={false}
      value={isLoading ? null : value}
      blurInputOnSelect={true}
      isLoading={isLoading}
      minMenuHeight={300}
      maxMenuHeight={250}
      menuPlacement={smallScreen ? "top" : "auto"}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary25: muiTheme.palette.secondary.light,
          primary: muiTheme.palette.secondary.main,
        },
      })}
      defaultValue={options?.at(0)}
      styles={colourStyles}
      hideSelectedOptions={true}
      isClearable={false}
      name="Language Select"
      options={options}
      onChange={(item: any) => {
        const language = item as LanguageOptions;
        // filterOptions(language.group_id);
        setSelected(language);
      }}
      placeholder={placeHolder}
      components={{
        DropdownIndicator: isLoading ? null : CustomDropdownIndicator,
        IndicatorSeparator: () => null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
    />
  );
};

export default LanguageSelect;
