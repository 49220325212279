import { Stack, Button } from "@mui/material";
import React, { useState } from "react";
import { StyledInput } from "../index.styled";
import { addReview, fetchReviews } from "../../../service/firebase/firebaseQueries";
import { useDispatch, useSelector } from "react-redux";
import { setReviews, updateReviews } from "../../../store/features/data/reviewSlice";
import { setLoginModalState } from "../../../store/features/general/loginModalSlice";
import { RootState } from "../../../store/store";

const ReviewInput: React.FC = () => {
  const dispatch = useDispatch();
  const [reviewText, setReviewText] = useState("");
  const currentUser = useSelector((state: RootState) => state.userData);

  const getReviews = async () => {
    const reviews = await fetchReviews();
    if (reviews?.length > 0) {
      dispatch(setReviews(reviews));
    }
  };

  const addReviewLocal = (reviewText: string) => {
    const review = {
      review: reviewText,
      user_id: currentUser?.userId ?? "",
      user_name: currentUser?.displayName ?? "",
      created_at: Date.now(),
    };

    dispatch(updateReviews([review]));
  };

  const handleAddReview = async () => {
    if (currentUser?.userId) {
      setReviewText("");
      addReviewLocal(reviewText);
      await addReview({ review: reviewText });

      setTimeout(() => {
        getReviews();
      }, 500);
    } else {
      dispatch(setLoginModalState(true));
    }
  };

  return (
    <Stack direction={"row"} sx={{ height: "63px", padding: "14px 15px 12px 16px", gap: "9px", flexShrink: 0 }}>
      <StyledInput
        value={reviewText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setReviewText(e.target.value);
        }}
        sx={{ height: "38px", borderRadius: "8px", "& #styled-input": { padding: "7px 14px", borderRadius: "10px" } }}
        size="small"
        id="styled-input"
        autoComplete="off"
        placeholder="Type Your Feedback..."
      />

      <Button
        onClick={handleAddReview}
        disabled={reviewText?.trim() === ""}
        sx={{ display: "block", color: "white", borderRadius: "8px", lineHeight: 1, flexShrink: 0 }}
        disableElevation
        disableFocusRipple
        variant="contained"
        color="secondary"
      >
        Send
      </Button>
    </Stack>
  );
};

export default ReviewInput;
