import { SvgIconProps } from "@mui/material";
import React from "react";

const CategoryIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "12"}
      height={height ?? "12"}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.687744 1H3.7909V4H0.687744V1ZM5.85966 1H8.96282V4H5.85966V1ZM0.687744 6H3.7909V9H0.687744V6ZM5.85966 7.5C5.85966 7.89782 6.02313 8.27936 6.31411 8.56066C6.60509 8.84196 6.99974 9 7.41124 9C7.82274 9 8.21739 8.84196 8.50837 8.56066C8.79935 8.27936 8.96282 7.89782 8.96282 7.5C8.96282 7.10218 8.79935 6.72064 8.50837 6.43934C8.21739 6.15804 7.82274 6 7.41124 6C6.99974 6 6.60509 6.15804 6.31411 6.43934C6.02313 6.72064 5.85966 7.10218 5.85966 7.5Z"
        stroke="currentColor"
        strokeWidth="1.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CategoryIcon;
