import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../../service/firebase/firebase.types";
import { clearAll } from "../../store";

const userSlice = createSlice({
  name: "user",
  initialState: null as UserData,
  reducers: {
    setUserData(state, action: PayloadAction<Exclude<UserData, null>>) {
      return action.payload ? { ...action.payload } : null;
    },
    updateUserData(
      state: UserData | null,
      action: PayloadAction<Partial<Exclude<UserData, null>>>
    ) {
      if (state) {
        return { ...state, ...action.payload };
      }
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => null);
  },
});

export const { setUserData, updateUserData } = userSlice.actions;
export default userSlice.reducer;
