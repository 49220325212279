import { SvgIconProps } from "@mui/material";
import React from "react";

const MenuIcon: React.FC<SvgIconProps> = () => {
  return (
    <svg
      width="3"
      height="13"
      viewBox="0 0 3 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill="#2A292E" />
      <circle cx="1.5" cy="6.5" r="1.5" fill="#2A292E" />
      <circle cx="1.5" cy="11.5" r="1.5" fill="#2A292E" />
    </svg>
  );
};

export default MenuIcon;
