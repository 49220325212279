import { auth, googleProvider } from "./config/firebaseConfig";
import {
  AuthError,
  onAuthStateChanged,
  signOut,
  User,
  signInWithRedirect,
  getRedirectResult,
  getAdditionalUserInfo,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updateProfile,
} from "firebase/auth";
import getFirebaseErrorMessage from "./getErrorMessage";
import { setHasRedirectResponse } from "../../utils/AuthFunctions";
import { RedirectRes, Res, UserData } from "./firebase.types";
import { createNewUserDoc } from "./firebaseQueries";
import { defaultVoiceConfigs } from "../../constants/constants";

type Handler = (user: Exclude<UserData, null>) => void;

export const listenAuthStateChange = (handleStateChange: Handler) => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    //this is temp solution to add voice configg to already created accounts
    // if (user) {
    //   createNewUserDoc({ ...user, isNewUser: false });
    // }
    handleStateChange(getUserDataFrom(user));
  });
  return unsubscribe;
};

export async function signInWithGoogleAuth(): Promise<Res> {
  let res: Res = { status: 0, data: "", msg: "", error: "" };
  try {
    setHasRedirectResponse(true);
    await signInWithRedirect(auth, googleProvider);
    res.status = 200;
  } catch (error: any) {
    res.error = getFirebaseErrorMessage(error as AuthError);
    setHasRedirectResponse(false);
  }

  return res;
}

export async function sendSignInEmailLink(email: string) {
  let res: RedirectRes = { status: 0, data: null, msg: "", error: "" };

  const encodedEmail = encodeURIComponent(email);

  const actionCodeSettings = {
    url: `${window.location.origin}/account-setup?email=${encodedEmail}`,
    handleCodeInApp: true,
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    res.status = 200;
    res.msg = "Email has been sent!";
  } catch (error) {
    res.msg = getFirebaseErrorMessage(error as AuthError);
  }

  return res;
}

export async function signOutUser() {
  try {
    await signOut(auth);
    setTimeout(() => {
      window.location.reload();
    }, 700);
    return true;
  } catch (error: any) {
    console.log(error.message);
    return false;
  }
}

export async function getRedirectSignInResult(): Promise<RedirectRes> {
  let res: RedirectRes = { status: 0, data: null, msg: "", error: "" };

  try {
    const response = await getRedirectResult(auth);
    if (response) {
      const additionalData = getAdditionalUserInfo(response);
      res.status = 200;
      res.data = { ...response.user, isNewUser: additionalData?.isNewUser };
    }
  } catch (e) {
    console.error(e);
    res.msg = getFirebaseErrorMessage(e as AuthError);
  }

  return res;
}

export async function hasSignInWithEmailLink() {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedEmailFromUrl = urlParams.get("email") ?? "";

    const email = decodeURIComponent(encodedEmailFromUrl);

    return email;
  } else {
    return null;
  }
}

export async function handleSignInWithEmailLink(email: string) {
  let res: RedirectRes = { status: 0, data: null, msg: "", error: "" };

  try {
    const response = await signInWithEmailLink(
      auth,
      email,
      window.location.href
    );
    if (response) {
      const additionalData = getAdditionalUserInfo(response);
      if (additionalData?.isNewUser) {
        const displayName = response.user.email?.split("@").at(0);
        await updateProfile(response.user, {
          displayName,
        });
      }

      res.status = 200;
      res.data = { ...response.user, isNewUser: additionalData?.isNewUser };
    }
  } catch (e) {
    res.msg = getFirebaseErrorMessage(e as AuthError);
  }

  return res;
}

function getUserDataFrom(user: User | null): Exclude<UserData, null> {
  return {
    displayName: user?.displayName ?? "",
    email: user?.email ?? "",
    photoURL: user?.photoURL ?? "",
    userId: user?.uid,
  };
}
