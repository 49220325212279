/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import PhraseAndTermCard from "./PhraseAndTermCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

const PhraseAndTermList = ({
  handleRemoveFavourite,
}: {
  handleRemoveFavourite: () => void;
}) => {
  const speechCallbackRef = useRef<() => void>();
  const phraseAndTerms = useSelector(
    (state: RootState) => state.phraseAndTerms
  );
  // const [newData, setnewData] = useState<PhraseAndTermsData[]>([]);
  const languagePrimary = useSelector(
    (state: RootState) => state.appliedFilters?.languagePrimary
  );
  useEffect(() => {
    if (phraseAndTerms.length === 0) {
      handleRemoveFavourite();
    }
  }, [phraseAndTerms]);

  const isReversedData = (languageId: string) => {
    if (
      languagePrimary?.language_id === languageId ||
      languagePrimary?.general_language_id === languageId
    ) {
      return false;
    }

    return true;
  };
  // useEffect(() => {
  //   setnewData(
  //     phraseAndTerms.filter(
  //       (cardData) => cardData.secondary_data.secondary_value2 !== undefined
  //     )
  //   );
  // }, [phraseAndTerms]);

  return (
    <>
      {phraseAndTerms.map((cardData) => {
        return (
          <PhraseAndTermCard
            speechCallbackRef={speechCallbackRef}
            isReversedData={isReversedData(
              cardData?.primary_data?.primary_language_id
            )}
            key={cardData?.phrase_and_term_id}
            cardData={cardData}
          />
        );
      })}
    </>
  );
};

export default PhraseAndTermList;
