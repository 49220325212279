import { SvgIconProps } from "@mui/material";
import React from "react";

const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01325 1.89409L2.41992 4.69409C1.81992 5.16076 1.33325 6.15409 1.33325 6.90743V11.8474C1.33325 13.3941 2.59325 14.6608 4.13992 14.6608H11.8599C13.4066 14.6608 14.6666 13.3941 14.6666 11.8541V7.00076C14.6666 6.19409 14.1266 5.16076 13.4666 4.70076L9.34659 1.81409C8.41325 1.16076 6.91325 1.19409 6.01325 1.89409Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.9941V9.99414"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
