import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { FilterTypes } from "../../../types/global.types";

export const initialFilterState: FilterTypes = {
  phrase: true,
  term: true,
  type: "PHRASE",
  languagePrimary: null,
  languageSecondary: null,
  isReverseFilter: false,
  category: null,
  topic: null,
  showFavourites: false,
  searchTerm: "",
  sortById: true,
};

const appliedFilterSlice = createSlice({
  name: "filter options",
  initialState: initialFilterState,
  reducers: {
    setAppliedFilters(
      state: FilterTypes,
      action: { payload: Partial<FilterTypes> }
    ) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialFilterState);
  },
});

export const { setAppliedFilters } = appliedFilterSlice.actions;
export default appliedFilterSlice.reducer;
