import {
  Avatar,
  Link,
  Box,
  Menu,
  MenuItem,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useState, useRef, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAvatarInitials } from "../../utils/GeneralUtils";
import useHandleSignOut from "../../Hooks/useHandleSignOut";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router";
import MenuIcon from "../../assets/Icons/MenuIcon";
import HomeIcon from "../../assets/Icons/MobileMenu/HomeIcon";
import WhyWeIcon from "../../assets/Icons/MobileMenu/WhyWeIcon";
import FeaturesIcon from "../../assets/Icons/MobileMenu/FeaturesIcon";
import ReferenceIcon from "../../assets/Icons/MobileMenu/ReferenceIcon";
import FlashcardsIcon from "../../assets/Icons/MobileMenu/FlashcardsIcon";

type Props = {
  showDetails?: boolean;
};

const MenuItems = [
  { name: "Home", icon: <HomeIcon />, path: "/" },
  { name: "About", icon: <WhyWeIcon />, path: "/about" },
  { name: "Features", icon: <FeaturesIcon />, path: "/features" },
  // { name: "Reference", icon: <ReferenceIcon />, path: "/reference" },
  { name: "Flashcards", icon: <FlashcardsIcon />, path: "/flashcards" },
];
const MobileMenus = ({ showDetails }: Props) => {
  const [showProfileDetails, setShowProfileDetails] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector((state: RootState) => state.userData);
  const anchorElement = useRef<any>();

  const { handleSignOut } = useHandleSignOut();
  const { setIsConfigVoiceDialog } = useAuthContext();

  function handleClose() {
    setShowProfileDetails(false);
  }

  const AvatarInitials = useMemo(() => {
    return getAvatarInitials(userData);
  }, [userData]);

  useEffect(() => {
    if (showDetails !== undefined) setShowProfileDetails(showDetails);
  }, [showDetails]);

  const getDisplayName = useMemo(() => {
    const userName = userData?.displayName ?? "";

    if (userName !== "") {
      return userName;
    }
    return userData?.email.split("@").at(0) ?? "New User";
  }, [userData]);

  return (
    <>
      <IconButton
        onClick={() => {
          setShowProfileDetails(true);
        }}
        ref={anchorElement}
        sx={{ cursor: "pointer" }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElement?.current}
        className="z-[1000002]"
        keepMounted={false}
        tabIndex={-1}
        id="account-menu"
        open={showProfileDetails}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              width: "180px",
              mt: 1.5,
              top: "75px !important",
              borderRadius: "12px",
              "& .MuiAvatar-root": {
                width: 40,
                height: 40,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {MenuItems.map((item, index) => (
          <MenuItem
            key={index}
            disableRipple={true}
            onClick={() => {
              navigate(item.path);
            }}
            className={`overflow-ellipsis flex gap-5 cursor-default items-center py-3  ${
              showDetails ? "setting" : null
            }  ${
              location.pathname === item.path
                ? "bg-[#138fd71a] text-[#138FD7]"
                : null
            }`}
          >
            {item.icon}
            <Box>
              <Typography className="w-full line-clamp-1 font-medium leading-5 ">
                {item.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MobileMenus;
