import { PhraseAndTermsData } from "./../../../service/firebase/firebase.types";
import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";

const initialState: PhraseAndTermsData[] = [];

type UpdateFavouritesProp = {
  type: "ADD" | "REMOVE";
  cardId: string;
};

type UpdateFavouriteIdProps = {
  cardId: string;
  favouriteId: string;
  showOnlyFavourites: boolean;
};

const phraseAndTermSlice = createSlice({
  name: "phrases and terms",
  initialState: initialState,
  reducers: {
    setPhraseAndTerms(
      state: PhraseAndTermsData[],
      action: { payload: PhraseAndTermsData[] }
    ) {
      return [...action?.payload];
    },
    updatePhraseAndTerms(
      state: PhraseAndTermsData[],
      action: { payload: PhraseAndTermsData[] }
    ) {
      return state.concat(action?.payload);
    },
    updateFavourites(
      state: PhraseAndTermsData[],
      action: { payload: UpdateFavouritesProp }
    ) {
      return state.map((data) => {
        if (data.phrase_and_term_id === action.payload.cardId) {
          const isFavourite = action.payload.type === "ADD" ?? data.isFavourite;
          return { ...data, isFavourite, favouriteId: "PROCESSING" };
        }
        return data;
      });
    },
    updateFavouriteId(
      state: PhraseAndTermsData[],
      action: { payload: UpdateFavouriteIdProps }
    ) {
      const phraseId = action.payload.cardId;
      const favouriteId = action.payload.favouriteId ?? null;

      if (action.payload.showOnlyFavourites) {
        return state.filter((data) => {
          return action.payload.cardId !== data.phrase_and_term_id;
        });
      } else {
        return state.map((phrase) => {
          if (phrase.phrase_and_term_id === phraseId) {
            return { ...phrase, favouriteId };
          }
          return phrase;
        });
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export const {
  setPhraseAndTerms,
  updatePhraseAndTerms,
  updateFavourites,
  updateFavouriteId,
} = phraseAndTermSlice.actions;
export default phraseAndTermSlice.reducer;
