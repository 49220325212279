import { Theme } from "@emotion/react";
import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StyledTabType extends Theme {
  selected: boolean;
}

export const StyledTab = styled(Button)<StyledTabType>(
  ({ theme, selected }) => ({
    zIndex: 10,
    flex: "1 1 0%",
    fontSize: "16px",
    fontWeight: 600,
    padding: "6px 11px",
    position: "relative",
    width: "100px",
    cursor: "pointer",
    border: selected ? `1px solid #138FD7` : "1px solid #cccccc",
    borderRadius: "8px",
    color: selected ? "white" : "black",
    //   transition: "width 0.3s ease-out",
    backgroundColor: selected ? "#138FD7" : "#F6FAFD",
    "&:hover": {
      backgroundColor: selected ? "#138FD7" : "#e2e8ed",
    },
  })
);

export const StyledStack = styled(Stack)({
  backgroundColor: "#F6FAFD",
  border: "1px solid #EAEEF4",
});

export const WrapperStack = styled(Stack)({
  padding: " 16px 14px ",
  gap: "12px",
  borderRadius: "8px",
  backgroundColor: "#F6FAFD",
  border: `1px solid red`,
  borderColor: "#EAEEF4",
});

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "24px",
  borderRadius: "8px",
  padding: "10px 24px",
  width: "100%",
  color: "white",
  fontSize: "16px",
  fontWeight: 600,
  display: "flex",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
