import { InputAdornment, OutlinedInput } from "@mui/material";
import React, { useRef } from "react";
import SearchIcon from "../../assets/Icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilters } from "../../store/features/filters/appliedFilterSlice";
import { RootState } from "../../store/store";

const SearchBar = () => {
  const dispatch = useDispatch();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchValue = useSelector((state: RootState) => state.appliedFilters.searchTerm);

  // this change directly reflect in applied filters and so it clears input field when selected filters applied
  // debounce search is performed directly through get data from --mainSection--getData func.
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setAppliedFilters({ searchTerm: e?.target?.value ?? "" }));
  }

  const focusSearch = () => {
    searchInputRef?.current?.focus();
  };

  return (
    <OutlinedInput
      value={searchValue}
      inputRef={searchInputRef}
      onChange={handleChange}
      autoComplete="off"
      size="small"
      id="search-bar"
      color="primary"
      inputProps={{ inputMode: "search" }}
      sx={{
        width: { xs: "100%", md: "448px" },
        outline: "none",
        paddingRight: "16px",
        fontSize: "15px",

        "& #search-bar::placeholder": { fontSize: "14px" },
        fontWeight: 500,
        color: "#000000ba",
        borderRadius: "10px",
        overflow: "hidden",
        bgcolor: "primary.main",
        border: "1px solid #EAEEF4",
        "& .MuiInputBase-input": { padding: "12px 16px" },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#EAEEF4",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F7",
        },
      }}
      placeholder="Search terms and phrases"
      endAdornment={
        <InputAdornment
          onClick={focusSearch}
          sx={{ cursor: "default", opacity: 0.3, "&:hover": { opacity: 0.5 } }}
          position="end"
        >
          <SearchIcon width={20} height={20} />
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
