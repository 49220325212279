import { AuthError } from "firebase/auth";

interface ErrorCodes {
  [key: string]: string;
}

const errorCodes: ErrorCodes = {
  "auth/email-already-exists": "Email Already Registered!",
  "auth/email-already-in-use": "Email Already Registered!",
  "auth/invalid-continue-uri": "Invalid Success URL",
  "auth/invalid-email": "Invalid Email",
  "auth/invalid-password": "Invalid Password",
  "auth/wrong-password": "Incorrect Password",
  "auth/invalid-uid": "Invalid User Id",
  "auth/user-not-found": "User Not Found",
  "auth/too-many-requests": "Try After Sometime",
  "auth/popup-closed-by-user": "Operation Cancelled",
  "auth/invalid-action-code": "Verification link expired!",
  "auth/network-request-failed": "No Connection!",
};

const getFirebaseErrorMessage = (authError: AuthError) => {
  if (errorCodes[authError.code]) return errorCodes[authError.code];
  return "An Unknown Error Occured!";
};

export default getFirebaseErrorMessage;
