import { SvgIconProps } from "@mui/material";
import React from "react";

const FilterIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "48"}
      height={height ?? "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="10" fill="#138FD7" />
      <path
        d="M32 17.6C32 17.04 32 16.76 31.89 16.546C31.7945 16.3579 31.6419 16.2049 31.454 16.109C31.24 16 30.96 16 30.4 16H17.6C17.04 16 16.76 16 16.546 16.109C16.3578 16.2049 16.2049 16.3578 16.109 16.546C16 16.76 16 17.04 16 17.6V18.337C16 18.582 16 18.704 16.028 18.819C16.0525 18.9214 16.093 19.0193 16.148 19.109C16.209 19.209 16.296 19.296 16.468 19.469L21.531 24.531C21.704 24.704 21.791 24.791 21.852 24.891C21.907 24.981 21.948 25.079 21.972 25.181C22 25.295 22 25.416 22 25.655V30.411C22 31.268 22 31.697 22.18 31.955C22.2581 32.0666 22.3582 32.161 22.4741 32.2326C22.59 32.3041 22.7192 32.3512 22.854 32.371C23.165 32.417 23.549 32.226 24.315 31.842L25.115 31.442C25.437 31.282 25.597 31.202 25.714 31.082C25.8178 30.976 25.8967 30.8483 25.945 30.708C26 30.55 26 30.37 26 30.011V25.663C26 25.418 26 25.296 26.028 25.181C26.0525 25.0786 26.093 24.9807 26.148 24.891C26.208 24.791 26.295 24.705 26.465 24.535L26.469 24.531L31.532 19.469C31.704 19.296 31.79 19.209 31.852 19.109C31.9071 19.0193 31.9476 18.9214 31.972 18.819C32 18.706 32 18.584 32 18.345V17.6Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
