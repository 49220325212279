import { useEffect, useState } from "react";
import Home from "./pages/Home";
import useHandleSignIn from "./Hooks/useHandleSignIn";

function App() {
  const { handleAuth } = useHandleSignIn();
  // const [isRun, setIsRun] = useState(false);

  return (
    <div className=" w-screen flex-grow overflow-hidden">
      {/* <div className="demo__hero">
        <div>
          <button onClick={handleClickStart}>Start</button>
        </div>
      </div> */}
      <Home />
    </div>
  );
}

export default App;
