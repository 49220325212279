import { User, UserInfo } from "firebase/auth";
import { GenerateUserDoc, SenetizedData } from "./firebase.types";
import { serverTimestamp } from "firebase/firestore";

export const generateUserDoc = (
  userData: User
): Omit<GenerateUserDoc, "displayName"> => {
  return {
    display_name: userData.displayName ?? "",
    email: userData.email ?? "",
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
  };
};

export const getSenetizedData = (data: SenetizedData) => {
  return {
    access: data?.access,
    category_id: data.category_id,
    fake_index: data?.fake_index,
    phrase_and_term_id: data.phrase_and_term_id,
    primary_data: data.primary_data,
    secondary_data: data.secondary_data,
    topic_id: data.topic_id,
    type: data.type,
  };
};

export const getDisplayName = (user: UserInfo) => {
  const userName = user?.displayName ?? null;
  if (userName) {
    return userName;
  }
  return user?.email?.split("@").at(0) ?? "New User";
};
