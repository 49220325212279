import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { StyledButton } from "./index.styled";
import { FilterTypes } from "../../../../../types/global.types";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilters } from "../../../../../store/features/filters/appliedFilterSlice";
import { RootState } from "../../../../../store/store";
import { useGeneralContext } from "../../../../../context/GeneralContextProvider";
import PhraseTermFilter from "./components/PhraseTermFilters";
import LanguageFilters from "./components/LanguageFilters";
import ViewOnlyFavourites from "./components/VIewOnlyFavourites";
import CategoryTopicFilter from "./components/CategoryTopicFilters";
import { setSelectedFilters } from "../../../../../store/features/filters/selectedFilterSlice";
import { useLocation } from "react-router";

interface Props {
  toggleDrawer?: (state?: boolean) => void;
  drawerState?: boolean;
}

const FilterControls: React.FC<Props> = ({ toggleDrawer, drawerState }) => {
  const dispatch = useDispatch();

  // small screen size
  const { smallScreen } = useGeneralContext();

  const appliedFilters: FilterTypes = useSelector(
    (state: RootState) => state.appliedFilters
  );
  const selectedFilters: FilterTypes = useSelector(
    (state: RootState) => state.selectedFilters
  );

  function applyFilter() {
    dispatch(setAppliedFilters(selectedFilters));
  }
  const location = useLocation();
  const currentUserId = useSelector(
    (state: RootState) => state.userData?.userId
  );
  // apply filter on state change if not small-screen
  useEffect(() => {
    if (!smallScreen || !appliedFilters.languagePrimary) {
      applyFilter();
    }
  }, [selectedFilters]);

  // reset data of selectors on close
  useEffect(() => {
    if (drawerState) {
      dispatch(setSelectedFilters(appliedFilters));
    }
  }, [drawerState]);

  function handleApplyFilterSM() {
    toggleDrawer && toggleDrawer(false);
    applyFilter();
  }

  return (
    <>
      <Typography component={"h3"} variant="h3">
        Filter By
      </Typography>

      {/* filter options */}
      <Stack sx={{ mt: 3.5, gap: 1.5 }}>
        <PhraseTermFilter />

        <LanguageFilters />

        <CategoryTopicFilter />

        <ViewOnlyFavourites />

        <StyledButton
          disabled={
            JSON.stringify(appliedFilters) === JSON.stringify(selectedFilters)
          }
          onClick={handleApplyFilterSM}
          disableElevation
          disableFocusRipple={true}
          variant="contained"
          color="secondary"
        >
          Apply Filter
        </StyledButton>
      </Stack>
    </>
  );
};

// export const useFilterContext = () => useContext(FilterContext);
export default FilterControls;

{
  /* <FilterContext.Provider value={{ selectedFilters, setFilterState }}> */
}
// </FilterContext.Provider>
// extra designs

// {
//   /* Select Categories */
// }

// {
//   /* rounded design -- */
// }
<>
  {/* <Stack
    direction={"row"}
    sx={{
      mt: 3.5,
      fontWeight: 500,
      padding: 1,
      borderRadius: "6px",
      border: `1px solid `,
      borderColor: "primary.light",
      alignItems: "center",
      justifyContent: "center",
      bgcolor: "primary.main",
    }}
  >
    <Stack
      flex={1}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography>Phrases</Typography>
      <Checkbox
        {...label}
        defaultChecked
        color="secondary"
        size="small"
      />
    </Stack>
    <Divider orientation="vertical" flexItem />

    <Stack
      flex={1}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography>Verbs</Typography>
      <Checkbox
        {...label}
        defaultChecked
        size="small"
        color="secondary"
      />
    </Stack>
  </Stack> */}
</>;

// {
//   /* styled tab... box design */
// }
<>{/*  */}</>;
