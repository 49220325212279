import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

export const StyledAccordion = styled(Accordion)({
  backgroundColor: "transparent",
  border: "none",
  padding: 0,
});

export const AccordionTitle = styled(AccordionSummary)({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "&.Mui-focusVisible": { backgroundColor: "transparent" },
});

export const AccordionData = styled(AccordionDetails)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  gap: "12px",
  padding: 0,
  paddingTop: "16px",
});
