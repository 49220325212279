import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import { ReviewType } from "../../../types/global.types";
import { getAvatarInitialsFromName, getFormatDateFromMilliseconds } from "../../../utils/GeneralUtils";

interface Props {
  review: ReviewType;
}
const ReviewCard: React.FC<Props> = ({ review }) => {
  const dateString = getFormatDateFromMilliseconds(review?.created_at);
  const nameInitial = getAvatarInitialsFromName(review?.user_name);
  return (
    <Stack direction={"column"} sx={{ padding: "5px", gap: "3px", opacity: review?.review_id ? 1 : 0.5 }}>
      <Stack sx={{ flexDirection: "row", gap: "12px" }}>
        <Avatar
          sx={{ bgcolor: "secondary.main", fontSize: "18px", width: "39px", height: "39px", borderRadius: "8px" }}
        >
          {nameInitial}
        </Avatar>
        <Stack>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {review?.user_name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "16px",
              color: "rgba(28, 28, 28, 0.4)",
            }}
          >
            {dateString}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 600,
          paddingLeft: "51px",
          lineHeight: "16px",
          color: "rgba(28, 28, 28, 0.3)",
        }}
      >
        {review?.review}
      </Typography>
    </Stack>
  );
};

export default ReviewCard;
