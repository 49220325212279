import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchCategories,
  fetchLanguages,
  fetchTopics,
  fetchVoiceConfigs,
} from "../service/firebase/firebaseQueries";
import { setCategories } from "../store/features/data/categorySlice";
import { setLanguages } from "../store/features/data/languageSlice";
import { setTopics } from "../store/features/data/topicSlice";
import { setVoiceConfigs } from "../store/features/data/voiceConfigSlice";

const useLoadInitialData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getLanguages();
    getCategories();
    getTopics();
    getVoiceConfigs();
  }, []);

  async function getLanguages() {
    const languageList = await fetchLanguages();
    if (languageList.length !== 0) {
      dispatch(setLanguages(languageList));
    }
  }

  async function getCategories() {
    const categoryList = await fetchCategories();
    categoryList?.length !== 0 && dispatch(setCategories(categoryList));
  }

  async function getTopics() {
    const topicList = await fetchTopics();
    topicList?.length !== 0 && dispatch(setTopics(topicList));
  }

  async function getVoiceConfigs() {
    const voiceConfigs = await fetchVoiceConfigs();
    dispatch(setVoiceConfigs(voiceConfigs || {}));
  }
};

export default useLoadInitialData;
