import { SvgIconProps } from "@mui/material";
import React from "react";

const DropDownIcon: React.FC<SvgIconProps> = ({ width, height, ...props }) => {
  return (
    <svg
      width={width ?? "20"}
      height={height ?? "20"}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="13" height="13" rx="6.5" fill="#E5E5E5" />
      <path
        d="M4.28997 5.26562L6.49997 7.41034L8.70997 5.26562L9.38886 5.92891L6.49997 8.73229L3.61108 5.92891L4.28997 5.26562Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};

export default DropDownIcon;
