import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { FilterTypes } from "../../../types/global.types";

const initialFilterState: FilterTypes = {
  phrase: true,
  term: true,
  type: "PHRASE",
  languagePrimary: null,
  languageSecondary: null,
  isReverseFilter: false,
  category: null,
  topic: null,
  showFavourites: false,
  searchTerm: "",
  sortById: true,
};

const selectedFilterSlice = createSlice({
  name: "filter options",
  initialState: initialFilterState,
  reducers: {
    setSelectedFilters(
      state: FilterTypes,
      action: { payload: Partial<FilterTypes> }
    ) {
      return { ...state, ...action.payload };
    },
    swapLanguages(state: FilterTypes) {
      const languagePrimary = state?.languagePrimary;
      const languageSecondary = state?.languageSecondary;

      return {
        ...state,
        languagePrimary: languageSecondary,
        languageSecondary: languagePrimary,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialFilterState);
  },
});

export const { setSelectedFilters, swapLanguages } =
  selectedFilterSlice.actions;
export default selectedFilterSlice.reducer;
