import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import axios from "axios";
import { baseURL, headers } from "../constants/constants";
let audio: HTMLAudioElement;

type Props = {
  onSpeechEnd: () => void;
};

const useProcessSpeech = ({ onSpeechEnd }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const voiceConfigs = useSelector((state: RootState) => state.voiceConfig);
  const voiceGender = useSelector(
    (state: RootState) => state?.userData?.user_settings?.gender
  );

  const processSpeech = async (text: string, languageId: string) => {
    const speechVoice =
      voiceConfigs?.[voiceGender === "MALE" ? "male" : "female"]?.[languageId];

    const payload = {
      input: { text: text ?? "Something went wront!" },
      voice: speechVoice,
      audioConfig: { audioEncoding: "MP3" },
    };

    if (!isLoading) {
      setIsLoading(true);
      try {
        const response = await axios.post(baseURL, payload, { headers });
        playAudio(response.data.audioContent);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const playAudio = (audioContent: string) => {
    pauseAudio();
    audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
    audio.play?.();
    audio.addEventListener("ended", handleTextToSpeechEnded);
  };

  const pauseAudio = () => {
    audio?.pause?.();
  };

  const handleTextToSpeechEnded = () => {
    onSpeechEnd?.();
    audio.removeEventListener("ended", handleTextToSpeechEnded);
  };

  return { isLoading, processSpeech, pauseAudio, onSpeechEnd };
};

export default useProcessSpeech;
