import { createSlice } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { TopicOptions } from "../../../types/global.types";

const initialState: TopicOptions[] = [];

const topicSlice = createSlice({
  name: "topics",
  initialState: initialState,
  reducers: {
    setTopics(state, action) {
      return action?.payload ?? [];
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => initialState);
  },
});

export const { setTopics } = topicSlice.actions;
export default topicSlice.reducer;
