import React, { useEffect, useMemo, useRef, useState } from "react";
import { getFlagByName } from "../../../utils/GeneralUtils";
import { FlagNames } from "../../../utils/index.types";
import { CircularProgress, IconButton, SvgIconProps } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { PhraseAndTermsData } from "../../../service/firebase/firebase.types";
import { useAuthContext } from "../../../context/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import useProcessSpeech from "../../../Hooks/useProcessSpeech";
import { Change, diffWords } from "diff";
import FavouriteButton from "../../Home/components/MainSection/components/PhraseAndTermList/PhraseAndTermCard/components/FavouriteButton";
enum TYPE {
  "PRIMARY",
  "SECONDARY",
}
type BoxDataType = {
  generalLang: string;
  language: string;
  content: string;
  languageId: string;
};
type Props = {
  card: BoxDataType;
  cardData?: PhraseAndTermsData | null;
};
const FlashCardItem = ({ card, cardData }: Props) => {
  const Flag = getFlagByName(card?.language ?? "");
  const speechCallbackRef = useRef<() => void>();

  const [isVoiceStart, setisVoiceStart] = useState(false);
  const { processSpeech, pauseAudio } = useProcessSpeech({
    onSpeechEnd: () => setisVoiceStart(false),
  });
  const [different, setDiffrentWords] = useState<Change[]>([]);
  const userSettings = useSelector(
    (state: RootState) => state?.userData?.user_settings
  );
  const startSpeech = () => {
    speechCallbackRef?.current?.();
    speechCallbackRef.current = () => setisVoiceStart(false);
    processSpeech(finalData ?? "", card?.languageId ?? "");
    setisVoiceStart(!isVoiceStart);
  };

  const [finalData, setFinalData] = useState("");
  useEffect(() => {
    const datax = card?.content?.split("/v2/");

    if (
      card.content &&
      userSettings?.gender &&
      datax &&
      datax?.length >= 2 &&
      datax[1]?.length > 0
    ) {
      const diff2 = diffWords(datax[0], datax[1]);
      if (diff2) setDiffrentWords(diff2);

      setFinalData(
        userSettings?.gender === "MALE"
          ? datax[0]
          : datax[1]
          ? datax[1]
          : datax[0]
      );
    } else {
      if (datax) {
        setFinalData(datax[0]);
      }
    }
  }, [card, userSettings]);
  return (
    <div className="sm:w-[430px] w-[95%]  max-w-[430px] sm:min-h-[240px] min-h-[200px] bg-white rounded-lg overflow-hidden shadow-lg flex flex-col justify-between border border-[#D5E5FC] border-solid">
      <div className=" w-full h-[6px] bg-text-secondary"></div>
      <div className="flex-grow max-h-44 items-center  overflow-hidden flex justify-center  sm:px-7 px-5 sm:py-4 py-2 ">
        <div className="lineclampCard overflow-clip  h-fit text-[#2A292E] sm:text-2xl text-lg font-bold sm:tracking-wide sm:leading-8 leading-2">
          {/* {card ? card?.content : "sss"} */}{" "}
          {different.length > 0 ? (
            <>
              {different.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: part.added
                      ? "#D32F2F"
                      : part.removed
                      ? "#138FD7"
                      : "#1c1c1c",
                  }}
                >
                  {!part.removed && !part.added && part.value}
                  {userSettings?.gender === "MALE" &&
                    part.removed &&
                    part.value}
                  {userSettings?.gender === "FEMALE" &&
                    part.added &&
                    part.value}
                </span>
              ))}
            </>
          ) : (
            <>{card?.content?.split("/v2/")}</>
          )}
        </div>
      </div>
      <div className="bg-[#F1F6FE] px-5 sm:py-4 py-2 flex items-center justify-between gap-4 border-solid  border-[#D5E5FC] border-t-2 border-b-0 border-l-0 border-r-0  ">
        <div className=" flex sm:gap-4 gap-3 items-center">
          {Flag && (
            <Flag className="sm:w-10 w-8 sm:h-10 h-8 overflow-hidden rounded-full border border-solid border-white shadow-xl" />
          )}
          <div>
            <div className=" text-[#5D5C60] sm:text-lg text-md font-semibold">
              {card.language}
            </div>
            <div className=" text-xs text-gray-500 h-4 ">
              {cardData ? (
                cardData?.fake_index
              ) : (
                <div className="w-full h-full flex items-center">
                  <div className="h-3 w-full bg-gray-200 rounded-md dark:bg-gray-200 "></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <IconButton className="text-to-speech m-0 p-0 scale-110 ">
            {isVoiceStart ? (
              <IconButton
                onClick={() => {
                  pauseAudio();
                  setisVoiceStart(!isVoiceStart);
                }}
              >
                <VolumeUpIcon className=" text-lg text-text-secondary" />
              </IconButton>
            ) : (
              <IconButton onClick={startSpeech}>
                <VolumeOffIcon className=" text-lg " />
              </IconButton>
            )}
            {/* <VolumeOffIcon className=" sm:text-2xl text-xl text-[#949496] " /> */}
          </IconButton>{" "}
          <div className=" w-9 h-9 relative flex justify-center items-center scale-110">
            {cardData ? (
              <FavouriteButton
                cardData={cardData}
                className=" static t-0 r-0 !text-[#0000008a]"
              />
            ) : (
              <CircularProgress color="secondary" size={17} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashCardItem;
