import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearAll } from "../../store";
import { AlertType } from "../../../types/global.types";

const alertSlice = createSlice({
  name: "alert",
  initialState: null as AlertType,
  reducers: {
    setAlert(state, action: PayloadAction<AlertType>) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearAll, () => null);
  },
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;
